.illustration-img-container--multiply {
  mix-blend-mode: multiply;

  .float-note__img,
  .side-note__img {
    border: 0;
  }
}

.illustration-img-container--lighten {
  mix-blend-mode: lighten;

  img {
    filter: invert(1);
  }

  .illustration-img {
    &, & * {
      color: white;
    }
  }

  .float-note__img,
  .side-note__img {
    border: 0;
  }
}

.illustration-img {
  width: 100%;
  height: auto;

  video,
  img {
    display: block;
    width: 100%;
    height: auto;
  }

  span {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    text-align: center;
    color: black;
    text-transform: uppercase;
    font-family: "Suisse Intl";
    font-size: font-size($shape-img-text-font-size);
    line-height: 1.05;
  }
}
