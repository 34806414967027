@font-face {
  font-family: "EK Roumald";
  src: url("../fonts/EKRoumald-Roman.woff2") format("woff2"), url("../fonts/EKRoumald-Roman.woff2") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "EK Roumald";
  src: url("../fonts/EKRoumald-Italic.woff2") format("woff2"), url("../fonts/EKRoumald-Italic.woff2") format("woff");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Cancellaresca";
  src: url("../fonts/CancellarescaScript_normal_normal.woff2") format("woff2"), url("../fonts/CancellarescaScript_normal_normal.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Suisse Intl Mono";
  src: url("../fonts/SuisseIntlMono-Regular-WebXL.woff2") format("woff2"), url("../fonts/SuisseIntlMono-Regular-WebXL.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Suisse Intl";
  src: url("../fonts/SuisseIntl-Regular-WebS.woff2") format("woff2"), url("../fonts/SuisseIntl-Regular-WebS.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
