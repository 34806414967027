:root {
  --base-font-size: #{$base-font-size-no-unit--mobile};
  --base-font-size-vw: #{$base-font-size-vw--mobile};

  @media #{$breakpoint-sm-up} {
    --base-font-size: #{$base-font-size-no-unit--tablet};
    --base-font-size-vw: #{$base-font-size-vw--tablet};
  }

  @media #{$breakpoint-md-up} {
    --base-font-size: #{$base-font-size-no-unit--desktop};
    --base-font-size-vw: #{$base-font-size-vw--desktop};
  }
}

p, h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
  font-weight: normal;
}

ul, ol, li {
  margin: 0;
  padding: 0;
}

* {
  font-variant: no-contextual;
  font-size: font-size($p-font-size--mobile);
  font-family: "EK Roumald";
  line-height: 1.2;

  @media #{$breakpoint-sm-up} {
    font-size: font-size($p-font-size);
    line-height: 1.1;
  }
}

ul, ul *, ol, ol *, li, li *,
p, p *,
.body-text, .body-text * {
  font-size: font-size($p-font-size--mobile);

  @media #{$breakpoint-sm-up} {
    font-size: font-size($p-font-size);
  }

  b, strong {
    font-weight: bold;
  }
}

p + p {
  margin-top: 1.1em;
}
@media #{$breakpoint-sm-up} {
  p:not(:first-child) {
    margin-top: 1.1em;
  }
}


.h1-text-style, .h1-text-style *,
h1, h1 * {
  font-size: font-size($h1-font-size--mobile);
  line-height: 0.95;
  letter-spacing: -0.02em;

  @media #{$breakpoint-sm-up} {
    font-size: font-size($h1-font-size);
    line-height: 0.85;
  }
}

.h2-text-style, .h2-text-style *,
h2, h2 * {
  font-size: font-size($h2-font-size--mobile);
  line-height: 1.05;

  @media #{$breakpoint-md-up} {
    font-size: font-size($h2-font-size);
  }
}

.h3-text-style, .h3-text-style *,
h3, h3 * {
  font-family: "Cancellaresca";
  font-size: font-size($h3-font-size);
  line-height: 0.2;
  letter-spacing: 0.5;
}

.h4-text-style, .h4-text-style *,
h4, h4 * {
  font-size: font-size($h4-font-size--mobile);
  line-height: 0.95;
  text-transform: uppercase;

  @media #{$breakpoint-sm-up} {
    font-size: font-size($h4-font-size);
  }
}

.h4-side-text-style, .h4-side-text-style * {
  font-size: font-size($h4-side-text-font-size--mobile);
  text-transform: uppercase;
  font-family: "Suisse Intl Mono";
  vertical-align: top;
  display: inline-block;
  padding: 0.25em 0.5em;

  @media #{$breakpoint-sm-up} {
    font-size: font-size($h4-side-text-font-size);
  }

  &:first-child {
    padding-left: 0;
  }
  &:last-child {
    padding-right: 0;
  }
}

.h5-text-style, .h5-text-style *,
h5, h5 * {
  font-family: "Cancellaresca";
  font-size: font-size($h4-font-size);
  line-height: 1.1;
  letter-spacing: 0.5;
}

.h6-text-style, .h6-text-style *,
h6, h6 * {
  font-family: "Suisse Intl Mono";
  font-size: font-size($h6-font-size--mobile);
  line-height: 1.05;
  letter-spacing: 0.01em;

  @media #{$breakpoint-md-up} {
    font-size: font-size($h6-font-size);
  }
}

.h7-text-style, .h7-text-style * {
  font-family: "Suisse Intl Mono";
  font-size: font-size($h7-font-size);
  line-height: 1.1;
  text-transform: uppercase;
}

.side-note-text-style, .side-note-text-style * {
  font-family: "Suisse Intl Mono";
  font-size: font-size($sidenote-font-size--mobile);
  line-height: 1.2;
  color: $color-purple;

  @media #{$breakpoint-sm-up} {
    font-size: font-size($sidenote-font-size);
  }
}

.float-note-text-style, .float-note-text-style * {
  font-family: "Suisse Intl Mono";
  font-size: font-size($floatnote-font-size);
  line-height: 1.17;
  color: $color-purple;
}

.slideshow-text-style, .slideshow-text-style * {
  font-family: "Suisse Intl Mono";
  font-size: font-size($slideshow-font-size--mobile);
  line-height: 1.15;
  color: $color-magenta;

  @media #{$breakpoint-sm-up} {
    font-size: font-size($slideshow-font-size);
  }
}

.side-note-num-text-style, .side-note-num-text-style * {
  font-family: "Suisse Intl Mono";
  font-size: font-size($sidenote-num-font-size);
  line-height: 0.9;
  color: $color-magenta;
  letter-spacing: -0.03em;
}

.side-pag-text-style, .side-pag-text-style * {
  font-family: "Suisse Intl Mono";
  font-size: font-size($side-pag-font-size);
  line-height: 0.9;
  color: $color-purple;
  letter-spacing: -0.03em;
}

.slideshow-title-text-style, .slideshow-title-text-style * {
  font-family: "Suisse Intl Mono";
  font-size: font-size($slideshow-title-font-size--mobile);
  line-height: 0.9;
  color: $color-magenta;
  letter-spacing: -0.03em;

  @media #{$breakpoint-sm-up} {
    font-size: font-size($slideshow-title-font-size);
  }
}

.hinge-text-style, .hinge-text-style * {
  font-family: "Suisse Intl Mono";
  font-size: font-size($hinge-font-size--mobile);

  @media #{$breakpoint-sm-up} {
    font-size: font-size($hinge-font-size);
  }
}

