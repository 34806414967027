// 1. ABSTRACTS
@import 'abstracts/fonts';
@import 'abstracts/variables';
@import 'abstracts/functions';
@import 'abstracts/mixins';

// 2. VENDORS
@import 'vendors';

// 3. VENDORS EXTENSIONS
@import 'vendors-extensions/simplebar';

// 4. BASE
@import 'base/typography';
@import 'base/base';

// 5. LAYOUT
@import 'layout/general';

// 6. COMPONENTS
@import 'components/menu';
@import 'components/menu-mobile';
@import 'components/story';
@import 'components/side-note';
@import 'components/float-note';
@import 'components/illustration-img';
@import 'components/story-slideshow';
@import 'components/bg-grid';

// 7. PAGES
@import 'pages/foreword';
@import 'pages/story-1';
@import 'pages/story-2';
@import 'pages/story-3';
@import 'pages/story-4';
@import 'pages/story-5';
@import 'pages/story-6';
@import 'pages/info';
