.float-note {
  position: absolute;
  width: calc(100vw / #{$grid-columns} * 2);
  @extend .float-note-text-style;
  display: none;

  @media #{$breakpoint-md-up} {
    opacity: 0;
    transform: translateY(6em);
    transition: opacity 1s ease-out 0.0s, transform 0.8s ease-out 0.0s;
    will-change: opacity, transform;
    display: block;

    .story__section--active & {
      transform: translateY(0);
      opacity: 1;
    }
  }

  &, & * {
    color: white;
  }

  &__title {
    text-transform: uppercase;
    &, & * {
      color: $color-light-purple;
    }
  }

  &__img {
    width: 100%;
    border: 1px solid $color-bg;

    img {
      width: 100%;
    }

    & + p,
    p + & {
      margin-top: 0.5em;
    }

    &--round {
      border-radius: 100%;
      overflow: hidden;
    }

  }

  &__line {
    position: absolute;
    background:
       linear-gradient(to top right,
           rgba(0,0,0,0) 0%,
           rgba(0,0,0,0) calc(50% - 0.8px),
           white 50%,
           rgba(0,0,0,0) calc(50% + 0.8px),
           rgba(0,0,0,0) 100%);

    .story__section--active & {
      animation-name: clipPathChangeLeftToRight;
      animation-duration: 0.8s;
      animation-iteration-count: 1;
      animation-fill-mode: both;
    }

    &--right {
      background:
         linear-gradient(to top left,
             rgba(0,0,0,0) 0%,
             rgba(0,0,0,0) calc(50% - 0.8px),
             white 50%,
             rgba(0,0,0,0) calc(50% + 0.8px),
             rgba(0,0,0,0) 100%);

      .story__section--active & {
        animation-name: clipPathChangeRightToLeft;
      }
    }

    &--top {
      transform: scaleY(-1);
    }

    [data-note-order="1"] & {
      animation-delay: calc(1s + 0.0s); //(time of animation duration of note + delay of note) + delay in relation to the note
    }
    [data-note-order="2"] & {
      animation-delay: calc(1s + 0.2s);
    }
    [data-note-order="3"] & {
      animation-delay: calc(1s + 0.4s);
    }
    [data-note-order="4"] & {
      animation-delay: calc(1s + 0.6s);
    }
    [data-note-order="5"] & {
      animation-delay: calc(1s + 0.8s);
    }
    [data-note-order="6"] & {
      animation-delay: calc(1s + 1s);
    }
    [data-note-order="7"] & {
      animation-delay: calc(1s + 1.2s);
    }
    [data-note-order="8"] & {
      animation-delay: calc(1s + 1.4s);
    }
    [data-note-order="9"] & {
      animation-delay: calc(1s + 1.6s);
    }
    [data-note-order="10"] & {
      animation-delay: calc(1s + 1.8s);
    }

  }
}

@keyframes clipPathChangeLeftToRight {
  from {
    clip-path: inset(0 100% 0 0);
  }
  to {
   clip-path: inset(0 0% 0 0);
  }
}

@keyframes clipPathChangeRightToLeft {
  from {
    clip-path: inset(0 0 0 100%);
  }
  to {
   clip-path: inset(0 0 0 0%);
  }
}
