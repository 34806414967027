#story-5-page-8-text-p-increase-width {
  width: 102%;
}

.force-adjust-mobile {
  width: 96%;

  @media #{$breakpoint-sm-up} {
    width: initial;
  }
}

[data-story="roxane-gay"] {
  [data-page-num="1"],
  [data-page-num="5"],
  [data-page-num="9"] {
    .story__image-page {
      padding-top: $cover-pad;
      padding-bottom: $cover-pad;

      @media #{$breakpoint-md-up} {
        padding-top: 0;
        padding-bottom: 0;
      }
    }
  }
  [data-page-num="2"] {
    .story__image-page {
      padding-bottom: $cover-pad;

      @media #{$breakpoint-md-up} {
        padding-bottom: 0;
      }
    }
  }

  [data-page-num="2"] {
    .story__image-page img {
      object-position: center 40%;
    }
  }

  [data-page-num="3"] {
    .story__text-page:last-child {
      padding-top: $cover-pad;

      @media #{$breakpoint-md-up} {
        padding-top: $cover-pad*5;
      }
    }
  }

  [data-page-num="5"],
  [data-page-num="6"] {
    .story__text-page {
      padding-top: $cover-pad;

      @media #{$breakpoint-md-up} {
        padding-top: $cover-pad*5;
      }
    }
  }

  [data-page-num="5"] {
    .story__text-page .story__bg-image {
      margin-top: $cover-pad;;
    }
  }
  [data-page-num="7"] {
    .story__image-page {
      padding-top: $cover-pad;

      @media #{$breakpoint-md-up} {
        padding-top: 0;
      }
    }

    .story__bg-image img {
      object-position: 85% center;
    }
  }

  .story__section--last-page .story__text-page {
    padding-bottom: $cover-pad*2;

    // @pedrosanta: Commented this because https://app.clickup.com/t/86dudzmfn
  
    // @media #{$breakpoint-md-up} {
    //   padding-bottom: 0;
    // }
  }
}

//PAGE 1
#story-5-page-1-sidenote-1 {
  margin-top: 30em;
  width: 102%;
}


//PAGE 2
#story-5-page-2-floatnote-1-1 {
  width: 10.5vw;
  top: 1em;
  right: 14.5vw;

  img {
    transform: scaleX(-1) rotate(7deg);
  }

  span {
    width: 80%;
    top: 2em;
    left: 0.8em;
    transform: rotate(-9deg);
  }
}

#story-5-page-2-floatnote-1-2 {
  width: 17vw;
  top: -9em;
  right: 0;

  .float-note__img {
    transform: rotate(-33deg);
  }
}
#story-5-page-3-sidenotemobile-1 {
  .side-note-mobile__img {
    width: 70%;
    border-radius: 100%;
    overflow: hidden;
  }
}

#story-5-page-2-sidenote-1 {
  margin-top: 2em;
}

#story-5-page-2-sidenote-2 {
  margin-top: 9em;
}


//PAGE 3
#story-5-page-3-textshape-1 {
  width: 17vw;
  height: 17vw;
  margin-top: 19em;
  margin-left: -9.5em;
}
#story-5-page-3-sidenote-1 {
  margin-top: 38em;

  .side-note__img {
    width: 16vw;
    height: 16vw;
    border-radius: 100%;
    overflow: hidden;
  }
}
#story-5-page-3-sidenote-2 {
  margin-top: 10em;
}

#story-5-page-3-sidenote-3 {
  margin-top: 15em;
}


//PAGE 4
#story-5-page-4-floatnote-1 {
  bottom: 3.4em;
  width: 80%;
  left: 10%;
  text-align: center;
}
#story-5-page-4-sidenote-1 {
  margin-top: 19em;

  p {
    width: 106%;
  }
}

//PAGE 5
#story-5-page-5-floatnote-1 {
  bottom: 3.4em;
  width: 82%;
  left: 9%;
  text-align: center;
}
#story-5-page-5-textshape-1 {
  width: 10vw;
  height: 7vw;
  margin-top: 16em;
  margin-left: -5em;
  shape-outside: polygon(49% 56%, 50% 100%, 100% 90%);
}

#story-5-page-5-floatnote-1-1 {
  width: 8.5vw;
  top: 49.5em;
  left: 5px;

  img {
    transform: scaleX(-1) rotate(-10deg);
  }

  span {
    width: 80%;
    top: 1.2em;
    left: 0.8em;
    transform: rotate(11deg);
  }
}

#story-5-page-5-floatnote-1-2 {
  width: 18vw;
  top: 48.5em;
  left: 0.5vw;

  .float-note__img {
    transform: scaleX(-1) rotate(-11deg);
  }
}


//PAGE 6
#story-5-page-6-sidenote-1 {
  margin-top: 9em;
}

#story-5-page-6-sidenote-2 {
  margin-top: 9em;
}

#story-5-page-6-sidenote-3 {
  margin-top: 27em;
}


//PAGE 7
#story-5-page-7-floatnote-1 {
  bottom: 3.4em;
  width: 45%;
  left: 2.5%;
  text-align: center;
}
#story-5-page-7-floatnote-2-1 {
  width: 14vw;
  top: 25vw;
  left: 18vw;

  img {
    transform: scale(-1) rotate(5deg);
  }

  span {
    width: 80%;
    top: 4em;
    left: 0.2em;
    transform: rotate(4deg);
  }
}

#story-5-page-7-floatnote-2-2 {
  width: 23vw;
  top: 13vw;
  left: 19vw;

  .float-note__img {
    transform: scaleX(-1) rotate(11deg);
  }
}

//PAGE 8
#story-5-page-8-sidenote-1 {
  margin-top: 49em;
}

#story-5-page-8-sidenote-2 {
  margin-top: 15em;
  width: 103%;
  letter-spacing: -0.02em;
}

#story-5-page-8-sidenote-3 {
  margin-top: 15em;
  width: 106%;
}

//PAGE 9
#story-5-page-9-sidenotemobile-1-1 {
  width: 42vw;
  max-width: initial;
  margin-top: 3em;
  margin-left: 6vw;

  img {
    transform: scaleX(-1) rotate(-15deg);
  }

  span {
    width: 70%;
    top: 2.2em;
    left: 1.7em;
    transform: rotate(15deg);
  }

  @media #{$breakpoint-sm-up} {
    width: 27vw;
    margin-top: 3em;
    margin-left: 20vw;

    span {
      top: 2.5em;
    }
  }
}
#story-5-page-9-sidenotemobile-1-2 {
  width: 65vw;
  max-width: initial;
  margin-top: -14em;
  margin-bottom: -4em;
  margin-left: 28vw;

  @media #{$breakpoint-sm-up} {
    width: 45vw;
    max-width: initial;
    margin-top: -14em;
    margin-left: 32vw;
    margin-bottom: -6em;
  }

  .side-note-mobile__img {
    width: 100%;
  }
}

#story-5-page-9-floatnote-1-1 {
  width: 10.5vw;
  top: 10em;
  left: 0vw;

  img {
    transform: scaleX(-1) rotate(-14deg);
  }

  span {
    width: 80%;
    top: 2em;
    left: 1em;
    transform: rotate(13deg);
  }
}

#story-5-page-9-floatnote-1-2 {
  width: 16vw;
  top: 16em;
  left: 2vw;

  .float-note__img {
    transform: rotate(11deg);
  }
}


#story-5-page-9-sidenotemobile-final {
  width: 68vw;
  left: -1vw;
  margin-top: -7vw;
  margin-bottom: -17vw;

  @media #{$breakpoint-sm-up} {
    width: 50vw;
    margin-top: -7vw;
    margin-bottom: -10vw;
    left: 3vw;
  }

  .side-note-mobile__img {
    width: 100%;

    img {
      transform: rotate(-10deg);
    }
  }
}
