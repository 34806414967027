:focus-visible {
  outline-color: $color-purple;
  box-shadow: 0 0 3px $color-4;
}
:focus:not(:focus-visible)  {
  box-shadow: 0;
}

body {
  background-color: $color-bg;
  pointer-events: none;

  > * {
    opacity: 0;
  }

  &.doc-ready {
    pointer-events: all;

    > * {
      opacity: 1;
    }
  }
}

.content {
  min-height: 100vh;
}

.text-chapter,
.text-pause {
  text-align: center;
  font-family: "Suisse Intl Mono";
  font-size: 0.83rem;
  margin-bottom: 1.1em;

  & + p {
    margin-top: 0;
  }

  &, & * {
    color: $color-purple;
  }

  @media #{$breakpoint-md-up} {
    .story__text-page--white & {
      &, & * {
        color: $color-light-purple;
      }
    }
  }
}


.text-chapter:not(:first-child) {
  margin-top: 3em !important;
}

.side-note-mobile + .text-chapter {
  margin-top: 1.5em !important;
  @media #{$breakpoint-md-up} {
    margin-top: 3em !important;
  }
}

.texture-overlay {
  position: absolute;
  inset: 0;
  z-index: 15 !important;
  pointer-events: none;
  opacity: 0.4;
  background-image: url('../img/texture1.png');
  background-size: 250vw auto;
  background-position: left top;
  background-repeat: repeat-y;

  @media #{$breakpoint-md-up} {
    background-size: 110vw auto;
    background-position: left top;

    .story__text-page + .story__text-page & {
      background-position: 50% 0;
    }
  }

  &--1 {
    background-image: url('../img/texture1.png')
  }
  &--2 {
    background-image: url('../img/texture2.png')
  }
  &--3 & {
    background-image: url('../img/texture3.png')
  }

  .story__cover-page & {
    opacity: 0.4;
  }

  .menu-mobile &,
  .story__section > &,
  .story__slideshow-page &,
  .story__text-page & {
    opacity: 0.3;
  }

  .menu & {
    background-position: center center;
    opacity: 0.3;
  }

  .story__image-page & {
    opacity: 0.3;

    @media #{$breakpoint-md-up} {
      opacity: 0.4;
    }
  }
}

.menu-nav-mobile {
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  height: calc(2.5em + #{$cover-pad}/3*2);
  z-index: 20;
  overflow: hidden;

  @media #{$breakpoint-md-up} {
    display: none;
  }

  &__text {
    text-align: center;
    position: absolute;
    inset: 0;
    padding-left: 2.6em;
    padding-right: 2.2em;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    @extend .side-note-text-style;

    &, & * {
      color: $color-5;
    }
  }

  &__bg {
    background-color: $color-purple;
    position: absolute;
    inset: 0;
    z-index: -1;

    &-texture {
      background-image: url('../img/texture1.png');
      background-size: 250vw auto;
      background-position: left top;
      background-repeat: repeat-y;
      opacity: 0.4;
      width: 100%;
      height: 100vh;
    }
  }
}



.menu-hamb-mobile {
  position: fixed;
  left: $cover-pad/3;
  top: $cover-pad/3;
  display: block;
  width: 2.2em;
  height: 2.5em;
  z-index: 300;

  @media #{$breakpoint-md-up} {
    display: none;
  }

  span {
    position: absolute;
    width: 80%;
    height: 3px;
    top: calc(50% - 3px/2);
    left: 10%;
    background-color: $color-4;

    &:after,
    &:before {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      height: 3px;
      top: calc(2.5em/-4);
      background-color: $color-4;
    }

    &:after {
      top: calc(2.5em/4);
    }
  }

  [data-current-state="menu"] & {
    span {
      background-color: transparent !important;

      &:before {
        top: 0;
        transform: rotate(-45deg);
      }
      &:after {
        top: 0;
        transform: rotate(45deg);
      }
    }
  }
}


.menu-icon__bg,
.menu-icon {
  position: fixed;
  width: 2.6em;
  height: 2.4em;
  top: $cover-pad;
  left: $cover-pad;
  z-index: 300;
  display: none;

  @media #{$breakpoint-md-up} {
    display: block;
  }

  &, & * {
    color: white;
    font-family: "Suisse Intl Mono";
    font-size: font-size($menu-icon-font-size);
    line-height: 1;
    letter-spacing: 0.03em;
  }
}

.menu-icon__bg {
  z-index: 299;
}

.menu-icon {
  text-transform: uppercase;
  text-align: center;
  mix-blend-mode: difference;
  border: 1px solid white;
  text-decoration: none;

  @media #{$breakpoint-md-up} {
    display: flex;
  }


  &:hover + .menu-icon__bg {
    background-color: $color-4;
  }

  .cover--active & {
    mix-blend-mode: normal;

    &, & * {
      border-color: black;
      color: black;
    }

    .menu-icon__hamb {
      span, span:after, span:before {
        background-color: black;
      }
    }
  }

  .invert-menu-icon--active & {
    mix-blend-mode: normal;

    &, & * {
      border-color: $color-bg;
      color: $color-bg;
    }

    .menu-icon__hamb {
      span, span:after, span:before {
        background-color: $color-bg;
      }
    }
  }

  &__hamb {
    position: relative;
    width: 100%;
    height: 100%;

    span {
      position: absolute;
      width: 80%;
      height: 1px;
      top: calc(50% - 1px/2);
      left: 10%;
      background-color: white;
      transition: transform 0.2s ease-in-out, top 0.2s ease-in-out;

      &:after,
      &:before {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        height: 1px;
        top: calc(2.4em/-5);
        background-color: white;
        transform-origin: center;
        transition: transform 0.2s ease-in-out, top 0.2s ease-in-out;
      }

      &:after {
        top: calc(2.4em/5);
      }
    }


    [data-current-state="menu"] & {
      span {
        background-color: transparent !important;

        &:before {
          top: 0;
          transform: rotate(-45deg);
        }
        &:after {
          top: 0;
          transform: rotate(45deg);
        }
      }
    }
  }
}

[data-navigo] {
  body.is-animating & {
    pointer-events: none;
  }
}

[data-srcset] {
  filter: blur(5px);
  transition: filter 0.3s ease-out;

  &.lazyloaded {
    filter: blur(0);
  }
}


.side-note,
.float-note {
  &[data-note-order="2"] {
    transition-delay: 0.2s;
  }
  &[data-note-order="3"] {
    transition-delay: 0.4s;
  }
  &[data-note-order="4"] {
    transition-delay: 0.6s;
  }
  &[data-note-order="5"] {
    transition-delay: 0.8s;
  }
  &[data-note-order="6"] {
    transition-delay: 1s;
  }
  &[data-note-order="7"] {
    transition-delay: 1.2s;
  }
  &[data-note-order="8"] {
    transition-delay: 1.4s;
  }
  &[data-note-order="9"] {
    transition-delay: 1.6s;
  }
  &[data-note-order="10"] {
    transition-delay: 1.8s;
  }
}


.floating {
  animation-name: floating;
  animation-duration: 2.5s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  will-change: transform;
}

@keyframes floating {
  0% { transform: translate(0, 0); }
  50%  { transform: translate(0.1em, 0.5em); }
  100%   { transform: translate(0, 0); }
}
