.story-slideshow {
  background-color: $color-slideshow-bg;
  cursor: grab;
  overflow: hidden;

  @media #{$breakpoint-md-up} {
    height: 100vh;
  }

  &.glide--dragging {
    cursor: grabbing;
  }

  ul, ol {
    list-style: none;
  }

  &__title {
    width: 100%;
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
    @extend .slideshow-title-text-style;
    text-align: center;
    text-transform: uppercase;
    display: flex;
    padding-top: 2em;
    padding-bottom: 4em;
    justify-content: center;

    @media #{$breakpoint-md-up} {
      height: 5rem;
      padding-bottom: 0;
    }
  }

  &__slides {
    display: flex;
  }

  &__slide-content {
    width: 100%;
    height: calc(100vw - 25vw);

    @media #{$breakpoint-md-up} {
      height: calc(100vh - 5rem - 7rem);
    }
  }

  &__image {
    width: 65%;
    margin-left: auto;
    margin-right: auto;
    height: 95%;
    display: flex;
    align-items: center;
    justify-content: center;

    @media #{$breakpoint-md-up} {
      width: 100%;
      height: 100%;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: center;
      display: block;
    }
  }

  &__captions {
    @extend .slideshow-text-style;

    @media #{$breakpoint-md-up} {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 3.4em;
      display: flex;
      align-items: flex-end;
    }
  }

  &__caption {
    width: calc(100% - #{$cover-pad}*4);
    margin: 0.5em $cover-pad*2;
    text-align: center;
    display: none;

    @media #{$breakpoint-md-up} {
      width: 100%;
      margin: 0;
      max-width: 650px;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }


    @media only screen and (min-width: 1300px) {
      max-width: 840px;
    }

    &.active {
      display: block;
    }

    &-title {
      color: $color-purple;
    }
  }

  &__thumbnails {
    width: calc(4em * 3 + 1px * 3);
    display: flex;
    flex-wrap: wrap;
    margin-top: 2em;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 4em;

    @media #{$breakpoint-md-up} {
      position: absolute;
      bottom: $cover-pad;
      left: $cover-pad;
      padding-bottom: 0;
    }
  }

  &__thumbnail {
    width: 4em;
    height: 4em;
    border: 0;
    background-color: transparent;
    outline: 1px solid $color-magenta;
    margin-left: 1px;
    margin-bottom: 1px;
    padding: 0;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      opacity: 0.3;
      transition: opacity 0.2s ease-in;
    }

    &:hover img {
      @media #{$breakpoint-sm-up} {
        cursor: pointer;
        opacity: 0.5;
      }
    }
    &.glide__bullet--active img {
      opacity: 1;
    }
  }

  &__arrow {
    position: absolute;
    right: 0.5em;
    top: 14.7em;
    transform-origin: center;
    transform: rotate(-90deg);
    padding: 0;

    @media #{$breakpoint-sm-up} {
      top: 19.7em;
    }

    @media #{$breakpoint-md-up} {
      right: 6em;
      top: calc(50% - #{font-size($arrow-font-size)}*0.88);
    }

    span {
      padding: 0;
      font-size: font-size($arrow-font-size--mobile);
      color: $color-magenta;
      cursor: pointer;

      @media #{$breakpoint-md-up} {
        font-size: font-size($arrow-font-size);

        &:hover {
          color: $color-purple;
        }
      }
    }


    &--left {
      right: initial;
      left: 0.5em;
      transform: scaleY(-1) rotate(90deg);

      @media #{$breakpoint-md-up} {
        left: 6em;
      }
    }
  }
}
