/* GRID */
$grid-columns: 24; // Set number of columns in the grid

$xs-max: 640px; // 40rem
$sm-max: 1023px; // 64rem
$md-max: 1439px; // 90rem
$lg-max: 1790px; // $lg-max: 1920px; // 120rem

/* GUTTER AND CONTAINER PAD */
/* gutter and container pad changes on sm */
$gutter-size: 0;
$gutter: 0 $gutter-size; // Set gutter size

$container-pad: 1.2em;

$border-radius: 2em;

/* COLORS */
$color-1: #ffffff;
$color-2: #E3DBEC;
$color-3: #EEDCEB;
$color-4: #BCA6D0;
$color-5: #E3C4DE;

$color-bg: #F4E7F2;

$color-purple: #49308c;
$color-magenta: #a3009a;
$color-light-purple: #e4dbec;

$color-slideshow-bg: #D7CAE3;

$cover-pad: 15px;

/* FONT SIZES */
$base-font-size-no-unit--desktop: 18.5;// px
$base-font-size-vw--desktop: 1.285;// 100vw * 18.5px / 1440px --> 1.285vw


$base-font-size-no-unit--tablet: 17;// px
$base-font-size-vw--tablet: 2.656;// 100vw * 17px / 640px --> 2.656vw


$base-font-size-no-unit--mobile: 17;// px
$base-font-size-vw--mobile: 4.358;// 100vw * 17px / 390px --> 4.358vw

$base-font-size-no-unit--indesign: 16.5;// pt
$pt-to-px-ratio: $base-font-size-no-unit--desktop/$base-font-size-no-unit--indesign;


$p-font-size: 16.5 * $pt-to-px-ratio;// 16.5pt

$h1-font-size: 110 * $pt-to-px-ratio;// 110pt
$h2-font-size: 40 * $pt-to-px-ratio;// 40pt
$h3-font-size: 31 * $pt-to-px-ratio;// 31pt
$h4-font-size: 25 * $pt-to-px-ratio;// 25pt
$h5-font-size: 24 * $pt-to-px-ratio;// 24pt
$h6-font-size: 15.7 * $pt-to-px-ratio;// 17pt
$h7-font-size: 14 * $pt-to-px-ratio;// 15pt

$h4-side-text-font-size: 11.3 * $pt-to-px-ratio;// 12pt

$menu-icon-font-size: 11.2 * $pt-to-px-ratio; //13pt

$sidenote-font-size: 8.4 * $pt-to-px-ratio;// 7.5pt
$floatnote-font-size: 9.8 * $pt-to-px-ratio;

$slideshow-font-size: 9.7 * $pt-to-px-ratio;// 7.5pt

$sidenote-num-font-size: 9.7 * $pt-to-px-ratio;// 10.3pt

$side-pag-font-size: 10.3 * $pt-to-px-ratio;// 11pt

$slideshow-title-font-size: 17.7 * $pt-to-px-ratio;// 19pt
$arrow-font-size: 50 * $pt-to-px-ratio;// 50pt

$shape-img-text-font-size: 11.6 * $pt-to-px-ratio;// 12.5pt

$hinge-font-size: 22.3;

//MOBILE
$p-font-size--mobile: 17;

$h1-font-size--mobile: 95;
$h2-font-size--mobile: 25;
$h4-font-size--mobile: 29;
$h6-font-size--mobile: 12.95;

$h4-side-text-font-size--mobile: 10;

$sidenote-font-size--mobile: 10.3;
$slideshow-font-size--mobile: 10.3;

$slideshow-title-font-size--mobile: 18;
$arrow-font-size--mobile: 35;

$hinge-font-size--mobile: 16.5;
