.menu-mobile {
  position: fixed;
  inset: 0;
  z-index: 200;
  pointer-events: none;
  overflow: hidden;

  @media #{$breakpoint-md-up} {
    display: none;
  }

  .animation-menu-hidden & {
    display: none;
  }

  &__content {
    position: relative;
    height: var(--window-height);
    display: flex;
    flex-direction: column;
  }

  &__book-cover {
    position: relative;
    pointer-events: all;
    background-color: $color-bg;
    min-height: 0;
    flex: 100% 1 1;
  }

  &__spines {
    flex-grow: 1;
  }

  &__footer {
    pointer-events: all;
  }

  a {
    color: inherit;
  }
}

.spines-mobile {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  &__spine-wrapper {
    flex-basis: calc(100% / 6);
    flex-grow: 0;
    flex-shrink: 0;
    pointer-events: all;
    height: 100%;

    &:nth-child(5n+1) {
      background-color: $color-1;
    }
    &:nth-child(5n+2) {
      background-color: $color-2;
    }
    &:nth-child(5n+3) {
      background-color: $color-3;
    }
    &:nth-child(5n+4) {
      background-color: $color-4;
    }
    &:nth-child(5n+5) {
      background-color: $color-5;
    }
  }

  a {
    text-decoration: none;
  }
}


.book-cover-mobile {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  text-align: center;

  h6 {
    text-transform: uppercase;
  }

  &__header {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: $cover-pad;
    padding-top: $cover-pad*0.7;
    height: 100%;
  }

  &__logo {
    margin-top: $cover-pad/2;
    margin-bottom: $cover-pad*0.7;
    min-height: 0;
    overflow: hidden;

    img {
      width: 90%;
      height: 100%;
      object-fit: contain;
      object-position: center;
    }
  }
}


.spine-mobile {
  position: relative;
  display: flex;
  align-items: center;
  padding: $cover-pad*1.5 $cover-pad;

  * {
    color: black;
  }

  &__title {
    flex-grow: 1;
    text-transform: none;
  }
  &__author {
    text-align: center;
    padding-top: $cover-pad*0.5;
    text-transform: uppercase;
  }
}



.footer-mobile {
  position: relative;
  background-color: $color-bg;

  &__menu {
    margin-top: $cover-pad;
    margin-bottom: $cover-pad;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__button {
    position: relative;
    text-decoration: underline;
    text-transform: none;

    &:not(:first-child) {
      margin-left: 0.5em;
    }
  }
}
