@import url("npm:sanitize.css");
@import "@drewbot/sass-flexbox-grid/public/sass-flexbox/scss/main.scss";
@import '../../node_modules/simplebar/dist/simplebar.css';
@import "@glidejs/glide/src/assets/sass/glide.core.scss";

#ot-sdk-btn.ot-sdk-show-settings {
  color: inherit !important;
  border: inherit !important;
  height: inherit !important;
  white-space: inherit !important;
  word-wrap: inherit !important;
  padding: inherit !important;
  font-size: inherit !important;
  line-height: inherit !important;
  text-decoration-line: underline !important;
  text-underline-offset: 0.2em !important;
  text-decoration-thickness: 1px !important;
  text-decoration-color: $color-4 !important;

  &:hover {
    background-color: inherit !important;
    color: $color-magenta !important;
  }
}

#onetrust-consent-sdk #onetrust-pc-sdk .ot-acc-grpcntr.ot-acc-txt,
#onetrust-consent-sdk #onetrust-pc-sdk .ot-acc-txt .ot-subgrp-tgl .ot-switch.ot-toggle,
#onetrust-consent-sdk #onetrust-pc-sdk #ot-host-lst .ot-host-info,
#onetrust-consent-sdk #onetrust-pc-sdk .ot-acc-txt .ot-ven-dets {
  background-color: black !important;
}
