.stories {
  @media #{$breakpoint-md-up} {
    scroll-snap-type: y mandatory;
    height: 100vh;
    overflow: auto;
  }

  &:focus {
    border: 0;
    outline: 0;
  }
}

.story {
  background-color: $color-bg;

  &__bg {
    position: absolute;
    inset: 0;
    z-index: 0;
    background-color: $color-bg;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }

    .story__section:not(.story__section--fullimage) & img {
      display: none;

      @media #{$breakpoint-md-up} {
        display: block;
      }
    }

    @media #{$breakpoint-md-up} {
      &--overlay:before {
        content: '';
        position: absolute;
        inset: 0;
        background: black;
        z-index: 2;
        opacity: 0.3;
      }
    }
  }

  &__side-info {
    @extend .side-pag-text-style;
    position: fixed;
    top: 50%;
    right: calc(1em + 3px);
    transform: translateY(-50%) rotate(180deg);
    writing-mode: vertical-rl;
    text-orientation: mixed;
    z-index: 2;
    text-transform: uppercase;
    display: none;

    .invert-side-info--active & {
      &, & * {
        color: $color-bg;
      }
    }

    @media #{$breakpoint-md-up} {
      display: block;
    }
  }

  &__pagination {
    @extend .side-pag-text-style;
    position: fixed;
    bottom: 1em;
    right: calc(1em + 3px);
    z-index: 2;
    display: none;

    .invert-side-info--active & {
      &, & * {
        color: $color-bg;
      }
    }

    @media #{$breakpoint-md-up} {
      display: block;
    }
  }

  &__section {
    position: relative;
    overflow: hidden;

    @media #{$breakpoint-md-up} {
      overflow: initial;
    }

    &--cover {
      z-index: 3;

      @media #{$breakpoint-md-up} {
        min-height: 100vh;
      }
    }

    &--fullimage {
      @media #{$breakpoint-md-up} {
        min-height: 100vh;
      }

      .story__bg {
        display: none;

        @media #{$breakpoint-md-up} {
          display: block;
        }
      }
    }

    @media #{$breakpoint-md-up} {
      min-height: 100vh;
      scroll-snap-align: start;
      scroll-snap-stop: always;
    }
  }

  &__pages-container {
    width: 100%;

    .simplebar-content {
      display: flex;
    }

    @media #{$breakpoint-md-up} {
      display: flex;
      flex-wrap: no-wrap;
      min-height: 100vh;
    }
  }

  &__cover-page {
    background-color: $color-purple;
    position: relative;
    width: 100%;
    flex: 100% 0 0;
    padding: $cover-pad;
    padding-top: calc(#{$cover-pad}*4);
    height: calc(var(--window-height)*0.9);

    @media #{$breakpoint-md-up} {
      width: 50%;
      flex: 50% 0 0;
      padding: 20px;
      padding-top: calc(20px*3.5);
      min-height: 100vh;
      height: initial;
    }
  }

  &__text-page {
    background-color: $color-bg;
    position: relative;
    width: 100%;
    flex: 100% 0 0;
    padding: 0 $cover-pad;
    overflow-x: hidden;
    overflow-y: hidden;

    @media #{$breakpoint-md-up} {
      width: 50%;
      flex: 50% 0 0;
      padding: $cover-pad;
      padding-top: $cover-pad*5;
      padding-bottom: $cover-pad*5;
      overflow-x: initial;
      overflow-y: initial;
    }

    & + &:before {
      @media #{$breakpoint-md-up} {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: -1px;
        width: 1px;
        background-color: $color-4;
        opacity: 0.5;
      }
    }

    &--white {
      background-color: transparent;

      @media #{$breakpoint-md-up} {
        &, & * {
          color: white;
        }
      }
    }
  }

  &__image-page {
    position: relative;
    width: 100%;
    flex: 100% 0 0;

    .story__section:not(.story__section--cover) &:last-child {
      padding-bottom: $cover-pad;

      @media #{$breakpoint-md-up} {
        padding-bottom: 0;
      }
    }

    .story__section:not(.story__section--cover) & {
      .story__bg-grid,
      .story__bg-image {
        padding: 0 $cover-pad;
      }
    }

    .story__section:not(.story__section--cover) .story__text-content & {
      .story__bg-image {//Exeption, images outside the original place on desktop
        padding: 0;
      }

      @media #{$breakpoint-md-up} {
        display: none;
      }
    }

    @media #{$breakpoint-md-up} {
      flex: 50% 0 0;
      width: 50%;
      position: sticky;
      top: 0;
      height: 100vh;
      overflow: hidden;

      .story__bg-grid,
      .story__bg-image {
        padding: 0;
      }

      .story__section:not(.story__section--cover) & {
        flex: 50% 0 0;
        width: 50%;
        padding: 0;
        height: 100vh;

        .story__bg-grid,
        .story__bg-image {
          padding: 0;
        }
      }
    }

    & > *:not(.story__bg-image):not(.story__bg-grid) {
      z-index: 2;
    }
  }

  &__text-content {
    @media #{$breakpoint-md-up} {
      width: 97%;
    }
  }

  &__side-notes {
    display: none;
    @media #{$breakpoint-md-up} {
      display: block;
    }
  }

  &__text-shape {
    // background: pink;//DEBUG
    shape-outside: content-box;
    float: left;
    border-radius: 100%;
    display: none;

    @media #{$breakpoint-md-up} {
      display: block;
    }
  }

  &__bg-grid,
  &__bg-image {
    position: relative;
    height: calc((100vw - #{$cover-pad}*2) * 16 / 10);
    overflow: hidden;

    @media #{$breakpoint-md-up} {
      position: absolute;
      inset: 0;
      height: 100%;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: top center;
    }

    @media #{$breakpoint-md-up} {
      &--overlay:before {
        content: '';
        position: absolute;
        inset: 0;
        background: black;
        background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0.8) 30%, rgba(0,0,0,0) 50%, rgba(0,0,0,0) 100%);
        z-index: 2;
        opacity: 0.3;
      }

      &--overlay-top:before {
        content: '';
        position: absolute;
        inset: 0;
        background: black;
        background: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0.8) 30%, rgba(0,0,0,0) 50%, rgba(0,0,0,0) 100%);
        z-index: 2;
        opacity: 0.3;
      }
    }
  }
}

[data-page-num="1"] {
  .story__text-page {
    padding-top: $cover-pad*2;

    @media #{$breakpoint-md-up} {
      padding-top: $cover-pad*5;
    }
  }
}


.cover {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  * {
    color: white;
  }

  &__header {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    height: 100%;
  }

  &__title {
    margin-bottom: 5rem;

    @media #{$breakpoint-md-up} {
      margin-bottom: 3rem;
    }
  }

  &__who {
    margin-bottom: 0.3em;
  }

  &__match {
    margin-bottom: 1.3em;

    @media #{$breakpoint-md-up} {
      opacity: 0;
      transform: translateY(0.25em);
      transition: opacity 1s ease-out 0.0s, transform 0.8s ease-out 0.0s;
      transition-delay: 0.8s;
      will-change: opacity, transform;

      .is-animating & {
        transition-delay: 1s;
      }

      .story__section--active & {
        transform: translateY(0);
        opacity: 1;
      }
    }
  }

  &__footer {
    margin: 1.5em;
    margin-top: 2.5em;
    margin-bottom: 2.5em;
    text-align: center;

    @media #{$breakpoint-md-up} {
      margin: 0;
      margin-bottom: calc(font-size($sidenote-font-size)*10 - 20px - 0.2em);
      margin-top: 5em;
      opacity: 0;
      transform: translateY(0.5em);
      transition: opacity 1s ease-out 0.0s, transform 0.8s ease-out 0.0s;
      transition-delay: 1.2s;
      will-change: opacity, transform;

      .is-animating & {
        transition-delay: 1.5s;
      }

      .story__section--active & {
        transform: translateY(0);
        opacity: 1;
      }
    }

    br {
      display: none;

      @media #{$breakpoint-md-up} {
        display: block;
      }
    }
  }
}

.cover-float-img {
  z-index: 2;
  position: absolute;
  width: calc(100vw / #{$grid-columns} * 2);

  @media #{$breakpoint-md-up} {
    opacity: 0;
    transform: scale(0.98);
    transition: opacity 1s ease-out 0.0s;
    will-change: opacity, transform;

    .story__section--active & {
      transform: scale(1);
      opacity: 1;
    }

    .story__section--cover & {
      transition-delay: 0.8s;
    }
  }

  video,
  img {
    width: 100%;
    height: auto;
  }
}


[data-float-img="2"] {
  transition-delay: calc(0.8s + 0.2s);
}
[data-float-img="3"] {
  transition-delay: calc(0.8s + 0.4s);
}
[data-float-img="4"] {
  transition-delay: calc(0.8s + 0.6s);
}
[data-float-img="5"] {
  transition-delay: calc(0.8s + 0.8s);
}
[data-float-img="6"] {
  transition-delay: calc(0.8s + 1s);
}
[data-float-img="7"] {
  transition-delay: calc(0.8s + 1.2s);
}
[data-float-img="8"] {
  transition-delay: calc(0.8s + 1.4s);
}
[data-float-img="9"] {
  transition-delay: calc(0.8s + 1.6s);
}
[data-float-img="10"] {
  transition-delay: calc(0.8s + 1.8s);
}

.side-note-num {
  @extend .side-note-num-text-style;
  border: 1px solid $color-purple;
  border-radius: 1em;
  margin: 0 .3em;
  padding: .1em .3em 0;
  padding-top: 0.2em;
  position: relative;
  top: -.25em;
  height: 1.3em;
  display: inline-block;

  @media #{$breakpoint-md-up} {
    .story__text-page--white & {
      border-color: $color-4;
    }
  }
}
