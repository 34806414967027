.foreword {
  background-color: $color-slideshow-bg;

  .story__section {
    text-align: center;
    padding: calc(#{$cover-pad}*4) $cover-pad*3;

    @media #{$breakpoint-md-up} {
      text-align: left;
      min-height: 100vh;
      padding: 2.5em 1em;
      padding-bottom: 5em;
    }
  }

  &__title {
    text-align: center;
    margin-bottom: 3.4em;

    @media #{$breakpoint-md-up} {
      margin-bottom: 4em;
    }
  }
}
