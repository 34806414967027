.bg-grid {
  display: flex;
  flex-wrap: wrap;
  z-index: 0;

  &__img {
    flex: 50% 0 0;
    height: calc(100%/3);
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top center;
    outline: 1px solid $color-bg;
  }
}
