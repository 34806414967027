body[data-current-state="menu"] {
  overflow: hidden;
}

.menu {
  position: fixed;
  inset: 0;
  z-index: 200;
  pointer-events: none;
  overflow-x: hidden;
  overflow-y: auto;
  display: none;
  text-selection: none;

  @media #{$breakpoint-md-up} {
    display: block;
  }

  &__content {
    position: relative;
    min-height: 100vh;
    display: flex;
  }

  &__book-cover {
    flex: 50% 0 0;
    pointer-events: all;
    background-color: $color-bg;
    overflow: hidden;
  }

  &__spines {
    position: relative;
    flex: 50% 0 0;
  }

  a {
    text-decoration: none;
    color: inherit;
  }
}

.spines {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;

  &__spine-wrapper {
    flex-basis: calc(100% / 6);
    flex-grow: 0;
    flex-shrink: 0;
    height: 100%;
    pointer-events: all;
    height: 100%;
    overflow: hidden;

    &:nth-child(5n+1) {
      background-color: $color-1;
    }
    &:nth-child(5n+2) {
      background-color: $color-2;
    }
    &:nth-child(5n+3) {
      background-color: $color-3;
    }
    &:nth-child(5n+4) {
      background-color: $color-4;
    }
    &:nth-child(5n+5) {
      background-color: $color-5;
    }
  }

  a {
    display: block;
    width: 100%;
    height: 100%;
    text-decoration: none;

    &:focus-visible {
      border: 1px solid $color-purple;
    }

    :focus:not(:focus-visible)  {
      border: 0;
    }
  }

  &__spine {
    width: 100%;
    height: 100%;
    transition: transform 0.3s ease-out;

    &:hover {
      transform: scale(1.02) translateY(-5px);
    }

    .is-animating & {
      transform: none !important;
    }
  }
}


.book-cover {
  position: relative;
  display: flex;
  flex-direction: column;

  h6 {
    text-transform: uppercase;
  }

  &__content {
    padding: $cover-pad;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    min-height: 100vh;
  }

  &__header {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-bottom: 5em;
  }

  &__logo {
    margin-top: $cover-pad;
    margin-bottom: $cover-pad*1.5;
    width: 80%;

    img {
      width: 100%;
    }
  }

  &__menu {
    position: absolute;
    left: $cover-pad;
    bottom: $cover-pad*1.5;
    display: flex;
    align-items: flex-end;
  }

  &__button {
    position: relative;
    display: flex;
    text-align: center;
    margin-right: 0.25em;
    justify-content: center;

    * {
      font-family: "Suisse Intl Mono";
      font-size: font-size($menu-icon-font-size);
      line-height: 0.9;
    }

    &:after {
      content: '';
      position: absolute;
      inset: 0;
      z-index: 1;
      background-color: white;
    }

    span {
      z-index: 2;
      position: relative;
      min-width: 9.4em;
      border: 0.5px solid;
      padding: 0.6em 2.1em;
      padding-top: 0.825em;
    }

    &:hover {
      cursor: pointer;

      &:after {
        background-color: $color-4;
      }

      span {
        border-color: #556148;
        color: #556148;
      }
    }
  }

  &__button--info {
    border-radius: 100%;

    span,
    &:after {
      border-radius: 100%;
    }
  }

  &__cupid {
    z-index: 2;
    pointer-events: none;
    width: 12em;
    mix-blend-mode: multiply;
    position: absolute;
    bottom: -1.5em;
    right: 0.8em;
    transform: rotate(10deg);

    img {
      width: 100%;
    }
  }

  &__cupid-speech {
    width: 7em;
    position: absolute;
    bottom: 3.4em;
    right: 11em;
    mix-blend-mode: multiply;
    transform: rotate(7deg);

    img {
      transform: scale(-1, -1);
    }

    .illustration-img span {
      width: 70%;
      top: 3.45em;
      left: 1.2em;
      transform: rotate(-1deg);
    }
  }

  &__corner-page-line {
    pointer-events: none;
    position: absolute;
    bottom: -0.25em;
    right: -0.25em;
    width: 6.9em;
    mix-blend-mode: multiply;
    z-index: 3;
    opacity: 0.85;


    img {
      width: 100%;
    }
  }

  &__corner-page {
    z-index: 2;
    pointer-events: none;
    position: absolute;
    bottom: -0.25em;
    right: -0.25em;
    width: 6.7em;

    img {
      width: 100%;
    }
  }
}


.spine {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  * {
    color: black;
  }

  &__title {
    padding: $cover-pad;
    text-orientation: sideways;
    writing-mode: vertical-lr;
    flex-grow: 1;
  }
  &__author {
    text-align: center;
    padding: $cover-pad*0.5;
    padding-bottom: $cover-pad*1.1;
  }
}

.spine-pop-up {
  pointer-events: none;
  position: absolute;
  width: 10em;
  height: auto;
  padding: 1em;
  top: 0;
  left: 0;
  z-index: 2;
  background-color: $color-bg;
  border: 1px solid $color-purple;
  text-align: center;
  opacity: 0;

  [data-current-state^="story-"] &,
  .is-animating & {
    opacity: 0 !important;
  }

  &__title {
    font-size: font-size(22);
    margin-bottom: 0.5em;
  }

  &__text {
    @extend .side-pag-text-style;
    color: black;
    line-height: 1.2;
  }
}
