[data-story="ro-kwon"] {
  [data-page-num="0"] .story__bg-image img{
    object-position: center 80%;
  }

  [data-page-num="1"] {
    .story__bg-image {
      padding-bottom: $cover-pad;

      @media #{$breakpoint-md-up} {
        padding-bottom: 0;
      }
    }
  }

  [data-page-num="4"] .story__image-page .story__bg-image {
    margin-top: 0 !important;
  }

  .story__section--last-page .story__text-page {
    padding-bottom: $cover-pad*2;

    @media #{$breakpoint-md-up} {
      padding-bottom: 0;
    }
  }
}

//COVER
#story-1-page-0-floatnote-1 {
  width: 36%;
  bottom: -2vw;
  left: 7vw;

  .float-note__img {
    transform: rotate(10deg);
  }
}


//PAGE 1
#story-1-page-1-sidenote-1 {
  margin-top: 2rem;
}

#story-1-page-1-sidenote-2 {
  margin-top: 10.7rem;
}

//PAGE 2
#story-1-page-2-sidenote-1-1 {
  width: 11em;
  margin-top: 3em;
  margin-left: 17em;

  @media #{$breakpoint-sm-up} {
    width: 13em;
    margin-top: 4em;
    margin-left: 49vw;
  }

  @media #{$breakpoint-md-up} {
    width: 13em;
    margin-top: -2em;
    margin-left: 11em;
  }

  img {
    transform: rotate(-10deg);
  }

  span {
    width: 66%;
    top: 1.7em;
    left: 1.7em;
    transform: rotate(-10deg);

    @media #{$breakpoint-md-up} {
      width: 60%;
      top: 1.8em;
      left: 2em;
      transform: rotate(0deg);
    }
  }
}
#story-1-page-2-sidenote-1-2 {
  width: 58vw;
  margin-top: -38vw;
  margin-bottom: -3em;
  margin-left: 4vw;

  @media #{$breakpoint-sm-up} {
    width: 41vw;
    margin-left: 20vw;
    margin-top: -25.5vw;
  }

  @media #{$breakpoint-md-up} {
    width: 22.5em;
    margin-top: -10.5em;
    margin-left: -4.5em;
  }



  .side-note-mobile__img {
    width: 100%;
    transform: scaleX(-1);
  }
}

#story-1-page-2-sidenote-2 {
  margin-top: 26em;
}

#story-1-page-2-textshape-1 {
  width: 10em;
  height: 6.5em;
  border-radius: 100%;
  margin-left: -5.5em;
  margin-top: -2.5em;
}

#story-1-page-2-floatnote-1 {
  bottom: 3.4em;
  right: 3em;
  width: 17vw;
}

//Page 3
#story-1-page-3-floatnote-1 {
  width: 25em;
  bottom: 3.4em;
  left: 15px;

  .float-note__img {
    margin-bottom: 1em;
    @extend .float-note__img--round;
    border-color: $color-4;
  }

  p {
    width: 105%;
  }
}

#story-1-page-3-text {
  margin-top: 63vh;
}


//Page 4
#story-1-page-4-floatnote-1-1 {
  width: 8.5vw;
  top: 10vw;
  left: 35vw;

  img {
    transform: scaleY(-1) rotate(-15deg);
  }

  span {
    top: 2.6em;
    left: 1.8em;
    width: 60%;
    transform: rotate(10deg);
  }
}

#story-1-page-4-floatnote-1-2 {
  width: 15vw;
  top: -1vw;
  left: 25vw;

  img {
    transform: scaleX(-1) rotate(-15deg);
  }
}

#story-1-page-4-sidenote-1 {
  margin-top: 19em;
}
#story-1-page-4-sidenote-2 {
  margin-top: 4em;
}


#story-1-page-4-sidenotemobile-final-1 {
  width: 33vw;
  max-width: initial;
  margin-top: 11em;
  margin-left: 46vw;

  @media #{$breakpoint-sm-up} {
    width: 20.5vw;
    margin-top: 15em;
    margin-left: 50vw;
  }

  .side-note-mobile__img {
    width: 100%;
  }

  img {
    transform: scaleY(-1) rotate(-7deg);
  }

  span {
    width: 80%;
    top: 3em;
    left: 1.2em;
    transform: rotate(3deg);
  }
}
#story-1-page-4-sidenotemobile-final-2 {
  width: 65vw;
  max-width: initial;
  margin-top: -25em;
  margin-bottom: -9em;
  margin-left: -2vw;

  @media #{$breakpoint-sm-up} {
    width: 48vw;
    max-width: initial;
    margin-top: -31em;
    margin-left: 12vw;
  }

  .side-note-mobile__img {
    width: 100%;
  }

  img {
    transform: rotate(10deg);
  }
}

