.side-note-mobile,
.side-note {
  position: relative;
  @extend .side-note-text-style;
  opacity: 0;
  transform: translateY(6em);
  transition: opacity 1s ease-out 0.0s, transform 0.8s ease-out 0.0s;
  will-change: opacity, transform;

  .story__section--active & {
    transform: translateY(0);
    opacity: 1;
  }

  &__title {
    text-transform: uppercase;
    &, & * {
      color: $color-magenta;
    }

    @media #{$breakpoint-md-up} {
      .story__text-page--white & {
        &, & * {
          color: $color-light-purple;
        }
      }
    }
  }

  &__img {
    border: 1px solid $color-magenta;
    width: 100%;
    height: auto;

    .illustration-img-container--multiply &,
    .illustration-img-container--lighten & {
      border: 0;
    }

    & + p,
    p + & {
      margin-top: 1em;
    }

    video,
    img {
      display: block;
      width: 100%;
      height: auto;
    }


    &--round {
      border-radius: 100%;
      overflow: hidden;
    }
  }
}

.side-note-mobile {
  transition: initial;
  transform: initial;
  opacity: 1;
  text-align: center;
  padding: $container-pad*2 0;
  margin-left: auto;
  margin-right: auto;
  max-width: 80%;

  &__img {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
  }

  @media #{$breakpoint-md-up} {
    display: none;
  }

  &.illustration-img-container--multiply,
  &.illustration-img-container--lighten {
    padding: 0;
    padding-bottom: $container-pad*2;
  }

  & + & {
    padding-top: 0;
  }

  .story__bg-image + &,
  .story__bg-grid + & {
    padding-top: 1em;
  }
}
