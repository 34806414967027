[data-story="isle-mcelroy"] {

  [data-page-num="1"],
  [data-page-num="3"] {
    .story__image-page {
      padding-top: $cover-pad;
      padding-bottom: 0;

      @media #{$breakpoint-md-up} {
        padding-top: 0;
      }
    }
  }

  [data-page-num="2"] {
    > .texture-overlay {
      opacity: 0.075;
    }
  }

  [data-page-num="4"] {
    .story__image-page .texture-overlay {
      opacity: 0.3;
    }
    .story__text-page {
      padding-top: $cover-pad;

      @media #{$breakpoint-md-up} {
        padding-top: $cover-pad*5;
      }
    }
  }

  [data-page-num="5"] {
    .story__image-page {
      padding-top: $cover-pad;
      padding-bottom: $cover-pad;

      @media #{$breakpoint-md-up} {
        padding-top: 0;
        padding-bottom: 0;
      }
    }
  }

  .story__section--last-page .story__text-page {
    padding-bottom: $cover-pad*2;

    @media #{$breakpoint-md-up} {
      padding-bottom: 0;
    }
  }
}

#story-4-page-0-floatnote-1 {
  top: 0;
  right: -1.8vw;
  width: 15vw;
}

//PAGE 1
#story-4-page-1-floatnote-1 {
  top: 7.5vw;
  left: 2vw;
  width: 12vw;

  .float-note__line {
    width: 5.5vw;
    height: 4.5vw;
    top: 11vw;
    left: 5vw;
  }
}
#story-4-page-1-floatnote-2 {
  width: 11vw;
  top: 10vw;
  right: 4.5vw;


  .float-note__line {
    width: 3vw;
    height: 4.5vw;
    top: 3.5vw;
    left: -3.5vw;
  }
}

#story-4-page-1-sidenote-1 {
  margin-top: 1em;
}

#story-4-page-1-sidenote-2 {
  margin-top: 7em;

  p {
    width: 106%;
  }
}

#story-4-page-1-sidenote-3 {
  margin-top: 20em;

  p {
    width: 109%;
    letter-spacing: -0.01em;
  }
}


//PAGE 2
#story-4-page-2-floatnote-1 {
  bottom: 3.4em;
  left: 15px;
  width: 22vw;
}

#story-4-page-2-floatnote-2-1 {
  width: 10vw;
  left: 40.5vw;
  top: 3em;

  img {
    transform: rotate(-6deg);
  }

  span {
    width: 74%;
    top: 1.5em;
    left: 1.5em;
    transform: rotate(-5deg);
  }
}

#story-4-page-2-floatnote-2-2 {
  width: 19vw;
  top: 2em;
  left: 28vw;

  .float-note__img {
    transform: rotate(-5deg);
  }
}

//PAGE 3
#story-4-page-3-textshape-1 {
  width: 17vw;
  height: 17vw;
  margin-top: -0.5em;
  margin-left: -9.5em;
}

#story-4-page-3-sidenotemobile-1 {
  .side-note-mobile__img {
    width: 70%;
    border-radius: 100%;
    overflow: hidden;
  }
}
#story-4-page-3-sidenote-1 {
  margin-top: 0;

  .side-note__img {
    width: 16vw;
    height: 16vw;
    border-radius: 100%;
    overflow: hidden;
  }
}

#story-4-page-3-sidenote-2 {
  margin-top: 8em;
}

//PAGE 4
#story-4-page-4-floatnote-1 {
  bottom: 3.4em;
  width: 85%;
  left: 7.5%;
  text-align: center;
}

#story-4-page-4-textshape-1 {
  width: 10em;
  height: 5.5em;
  border-radius: 100%;
  margin-top: -0.5em;
  margin-left: -2em;
}

#story-4-page-4-sidenote-1-1 {
  width: 16em;
  margin-top: 1.5em;
  margin-left: 16em;

  img {
    transform: rotate(5deg);
  }

  span {
    top: 1.3em;
    left: 2.1em;
    width: 70%;
    transform: rotate(2deg);
  }
}
#story-4-page-4-sidenote-1-2 {
  width: 28em;
  margin-top: -13em;
  margin-left: -5.2em;

  .side-note__img {
    width: 100%;
    transform: scaleX(-1);
  }
}
#story-4-page-4-sidenote-2 {
  margin-top: 16em;
}

#story-4-page-4-sidenote-3 {
  margin-top: 7em;
}

//PAGE 5
#story-4-page-5-sidenotemobile-1-1 {
  width: 37vw;
  max-width: initial;
  margin-top: 3em;
  margin-left: 48vw;

  @media #{$breakpoint-sm-up} {
    width: 23.5vw;
    margin-top: 3em;
    margin-left: 53vw;
  }

  img {
    transform: rotate(7deg);
  }

  span {
    width: 70%;
    top: 1.3em;
    left: 2em;
    transform: rotate(11deg);
  }
}
#story-4-page-5-sidenotemobile-1-2 {
  width: 68vw;
  max-width: initial;
  margin-top: -15em;
  margin-bottom: -1em;
  margin-left: -5vw;

  @media #{$breakpoint-sm-up} {
    width: 47vw;
    max-width: initial;
    margin-top: -19em;
    margin-bottom: -3em;
    margin-left: 15vw;
  }

  .side-note-mobile__img {
    width: 100%;
  }

  img {
    transform: scaleX(-1) rotate(4deg);
  }
}

#story-4-page-5-floatnote-1-1 {
  width: 11vw;
  bottom: 14em;
  left: 2vw;

  img {
    transform: scaleX(-1) rotate(7deg);
  }

  span {
    width: 80%;
    top: 2em;
    left: 0.8em;
    transform: rotate(-9deg);
  }
}

#story-4-page-5-floatnote-1-2 {
  width: 17vw;
  bottom: -5em;
  left: 8vw;

  .float-note__img {
    transform: scaleX(-1) rotate(-5deg);
  }
}

#story-4-page-5-sidenote-1 {
  margin-top: 0.5em;

  p {
    width: 102%;
  }
}


#story-4-page-5-sidenotemobile-final-1 {
  width: 42vw;
  max-width: initial;
  margin-top: 3em;
  margin-left: 3vw;

  @media #{$breakpoint-sm-up} {
    width: 26vw;
    margin-top: 3em;
    margin-left: 13vw;
  }

  img {
    transform: scaleX(-1) rotate(7deg);
  }

  span {
    width: 75%;
    top: 2.2em;
    left: 1em;
    transform: rotate(-11deg);
  }
}
#story-4-page-5-sidenotemobile-final-2 {
  width: 68vw;
  max-width: initial;
  margin-top: -15em;
  margin-bottom: -9em;
  margin-left: 23.5vw;

  @media #{$breakpoint-sm-up} {
    width: 47vw;
    max-width: initial;
    margin-top: -19em;
    margin-bottom: -8em;
    margin-left: 27vw;
  }

  .side-note-mobile__img {
    width: 100%;
  }

  img {
    transform: scaleX(-1) rotate(-4deg);
  }
}
