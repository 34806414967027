*, :before, :after {
  box-sizing: border-box;
  background-repeat: no-repeat;
}

:before, :after {
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
  vertical-align: inherit;
}

:where(:root) {
  cursor: default;
  overflow-wrap: break-word;
  tab-size: 4;
  -webkit-tap-highlight-color: transparent;
  -webkit-text-size-adjust: 100%;
  line-height: 1.5;
}

:where(body) {
  margin: 0;
}

:where(h1) {
  margin: .67em 0;
  font-size: 2em;
}

:where(dl, ol, ul) :where(dl, ol, ul) {
  margin: 0;
}

:where(hr) {
  color: inherit;
  height: 0;
}

:where(nav) :where(ol, ul) {
  padding: 0;
  list-style-type: none;
}

:where(nav li):before {
  content: "​";
  float: left;
}

:where(pre) {
  font-family: monospace;
  font-size: 1em;
  overflow: auto;
}

:where(abbr[title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

:where(b, strong) {
  font-weight: bolder;
}

:where(code, kbd, samp) {
  font-family: monospace;
  font-size: 1em;
}

:where(small) {
  font-size: 80%;
}

:where(audio, canvas, iframe, img, svg, video) {
  vertical-align: middle;
}

:where(iframe) {
  border-style: none;
}

:where(svg:not([fill])) {
  fill: currentColor;
}

:where(table) {
  border-collapse: collapse;
  border-color: inherit;
  text-indent: 0;
}

:where(button, input, select) {
  margin: 0;
}

:where(button, [type="button" i], [type="reset" i], [type="submit" i]) {
  -webkit-appearance: button;
}

:where(fieldset) {
  border: 1px solid #a0a0a0;
}

:where(progress) {
  vertical-align: baseline;
}

:where(textarea) {
  resize: vertical;
  margin: 0;
}

:where([type="search" i]) {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

::-webkit-input-placeholder {
  color: inherit;
  opacity: .54;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

:where(dialog) {
  color: #000;
  height: -moz-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: -moz-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  background-color: #fff;
  border: solid;
  margin: auto;
  padding: 1em;
  position: absolute;
  left: 0;
  right: 0;
}

:where(dialog:not([open])) {
  display: none;
}

:where(details > summary:first-of-type) {
  display: list-item;
}

:where([aria-busy="true" i]) {
  cursor: progress;
}

:where([aria-controls]) {
  cursor: pointer;
}

:where([aria-disabled="true" i], [disabled]) {
  cursor: not-allowed;
}

:where([aria-hidden="false" i][hidden]) {
  display: initial;
}

:where([aria-hidden="false" i][hidden]:not(:focus)) {
  clip: rect(0, 0, 0, 0);
  position: absolute;
}

[data-simplebar] {
  flex-flow: column wrap;
  place-content: flex-start;
  align-items: flex-start;
  position: relative;
}

.simplebar-wrapper {
  width: inherit;
  height: inherit;
  max-width: inherit;
  max-height: inherit;
  overflow: hidden;
}

.simplebar-mask {
  direction: inherit;
  z-index: 0;
  margin: 0;
  padding: 0;
  position: absolute;
  inset: 0;
  overflow: hidden;
  width: auto !important;
  height: auto !important;
}

.simplebar-offset {
  -webkit-overflow-scrolling: touch;
  margin: 0;
  padding: 0;
  position: absolute;
  inset: 0;
  direction: inherit !important;
  box-sizing: inherit !important;
  resize: none !important;
}

.simplebar-content-wrapper {
  direction: inherit;
  height: 100%;
  width: auto;
  max-width: 100%;
  max-height: 100%;
  scrollbar-width: none;
  -ms-overflow-style: none;
  display: block;
  position: relative;
  overflow: auto;
  box-sizing: border-box !important;
}

.simplebar-content-wrapper::-webkit-scrollbar, .simplebar-hide-scrollbar::-webkit-scrollbar {
  width: 0;
  height: 0;
  display: none;
}

.simplebar-content:before, .simplebar-content:after {
  content: " ";
  display: table;
}

.simplebar-placeholder {
  max-height: 100%;
  max-width: 100%;
  width: 100%;
  pointer-events: none;
}

.simplebar-height-auto-observer-wrapper {
  height: 100%;
  width: 100%;
  max-width: 1px;
  float: left;
  max-height: 1px;
  z-index: -1;
  pointer-events: none;
  flex-grow: inherit;
  flex-shrink: 0;
  flex-basis: 0;
  margin: 0;
  padding: 0;
  position: relative;
  overflow: hidden;
  box-sizing: inherit !important;
}

.simplebar-height-auto-observer {
  box-sizing: inherit;
  opacity: 0;
  height: 1000%;
  width: 1000%;
  min-height: 1px;
  min-width: 1px;
  pointer-events: none;
  z-index: -1;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.simplebar-track {
  z-index: 1;
  pointer-events: none;
  position: absolute;
  bottom: 0;
  right: 0;
  overflow: hidden;
}

[data-simplebar].simplebar-dragging, [data-simplebar].simplebar-dragging .simplebar-content {
  pointer-events: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  -khtml-user-select: none;
}

[data-simplebar].simplebar-dragging .simplebar-track {
  pointer-events: all;
}

.simplebar-scrollbar {
  min-height: 10px;
  position: absolute;
  left: 0;
  right: 0;
}

.simplebar-scrollbar:before {
  content: "";
  opacity: 0;
  background: #000;
  border-radius: 7px;
  transition: opacity .2s linear .5s;
  position: absolute;
  left: 2px;
  right: 2px;
}

.simplebar-scrollbar.simplebar-visible:before {
  opacity: .5;
  transition-duration: 0s;
  transition-delay: 0s;
}

.simplebar-track.simplebar-vertical {
  width: 11px;
  top: 0;
}

.simplebar-scrollbar:before {
  inset: 2px;
}

.simplebar-track.simplebar-horizontal {
  height: 11px;
  left: 0;
}

.simplebar-track.simplebar-horizontal .simplebar-scrollbar {
  min-height: 0;
  min-width: 10px;
  width: auto;
  inset: 0 auto 0 0;
}

[data-simplebar-direction="rtl"] .simplebar-track.simplebar-vertical {
  left: 0;
  right: auto;
}

.simplebar-dummy-scrollbar-size {
  direction: rtl;
  opacity: 0;
  visibility: hidden;
  height: 500px;
  width: 500px;
  position: fixed;
  overflow-x: scroll;
  overflow-y: hidden;
  -ms-overflow-style: scrollbar !important;
}

.simplebar-dummy-scrollbar-size > div {
  width: 200%;
  height: 200%;
  margin: 10px 0;
}

.simplebar-hide-scrollbar {
  visibility: hidden;
  scrollbar-width: none;
  -ms-overflow-style: none;
  position: fixed;
  left: 0;
  overflow-y: scroll;
}

@font-face {
  font-family: EK Roumald;
  src: url("EKRoumald-Roman.57bd1470.woff2") format("woff2"), url("EKRoumald-Roman.57bd1470.woff2") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: EK Roumald;
  src: url("EKRoumald-Italic.c35d90f6.woff2") format("woff2"), url("EKRoumald-Italic.c35d90f6.woff2") format("woff");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: Cancellaresca;
  src: url("CancellarescaScript_normal_normal.d3f9e0e3.woff2") format("woff2"), url("CancellarescaScript_normal_normal.a6f3e764.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: Suisse Intl Mono;
  src: url("SuisseIntlMono-Regular-WebXL.77bf9d0f.woff2") format("woff2"), url("SuisseIntlMono-Regular-WebXL.b2dfa73c.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: Suisse Intl;
  src: url("SuisseIntl-Regular-WebS.100855bb.woff2") format("woff2"), url("SuisseIntl-Regular-WebS.9f1f8777.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

.row {
  box-sizing: border-box;
  width: 100%;
  flex-flow: wrap;
  flex: 0 auto;
  margin: 0 auto;
  display: flex;
}

.row.reverse {
  flex-direction: row-reverse;
}

.row.natural-height {
  align-items: flex-start;
}

.row.flex-column {
  flex-direction: column;
}

.col {
  box-sizing: border-box;
  max-width: 100%;
  flex-grow: 1;
  flex-basis: 0;
  padding: 0;
}

.col.reverse {
  flex-direction: column-reverse;
}

.first {
  order: -1;
}

.last {
  order: 1;
}

.align-start {
  align-self: flex-start;
}

.align-end {
  align-self: flex-end;
}

.align-center {
  align-self: center;
}

.align-baseline {
  align-self: baseline;
}

.align-stretch {
  align-self: stretch;
}

.col-xs {
  box-sizing: border-box;
  max-width: 100%;
  flex-grow: 1;
  flex-basis: 0;
  padding: 0;
}

.col-xs-1 {
  box-sizing: border-box;
  max-width: 4.16667%;
  flex-basis: 4.16667%;
  padding: 0;
}

.col-xs-offset-1 {
  margin-left: 4.16667%;
}

.col-xs-2 {
  box-sizing: border-box;
  max-width: 8.33333%;
  flex-basis: 8.33333%;
  padding: 0;
}

.col-xs-offset-2 {
  margin-left: 8.33333%;
}

.col-xs-3 {
  box-sizing: border-box;
  max-width: 12.5%;
  flex-basis: 12.5%;
  padding: 0;
}

.col-xs-offset-3 {
  margin-left: 12.5%;
}

.col-xs-4 {
  box-sizing: border-box;
  max-width: 16.6667%;
  flex-basis: 16.6667%;
  padding: 0;
}

.col-xs-offset-4 {
  margin-left: 16.6667%;
}

.col-xs-5 {
  box-sizing: border-box;
  max-width: 20.8333%;
  flex-basis: 20.8333%;
  padding: 0;
}

.col-xs-offset-5 {
  margin-left: 20.8333%;
}

.col-xs-6 {
  box-sizing: border-box;
  max-width: 25%;
  flex-basis: 25%;
  padding: 0;
}

.col-xs-offset-6 {
  margin-left: 25%;
}

.col-xs-7 {
  box-sizing: border-box;
  max-width: 29.1667%;
  flex-basis: 29.1667%;
  padding: 0;
}

.col-xs-offset-7 {
  margin-left: 29.1667%;
}

.col-xs-8 {
  box-sizing: border-box;
  max-width: 33.3333%;
  flex-basis: 33.3333%;
  padding: 0;
}

.col-xs-offset-8 {
  margin-left: 33.3333%;
}

.col-xs-9 {
  box-sizing: border-box;
  max-width: 37.5%;
  flex-basis: 37.5%;
  padding: 0;
}

.col-xs-offset-9 {
  margin-left: 37.5%;
}

.col-xs-10 {
  box-sizing: border-box;
  max-width: 41.6667%;
  flex-basis: 41.6667%;
  padding: 0;
}

.col-xs-offset-10 {
  margin-left: 41.6667%;
}

.col-xs-11 {
  box-sizing: border-box;
  max-width: 45.8333%;
  flex-basis: 45.8333%;
  padding: 0;
}

.col-xs-offset-11 {
  margin-left: 45.8333%;
}

.col-xs-12 {
  box-sizing: border-box;
  max-width: 50%;
  flex-basis: 50%;
  padding: 0;
}

.col-xs-offset-12 {
  margin-left: 50%;
}

.col-xs-13 {
  box-sizing: border-box;
  max-width: 54.1667%;
  flex-basis: 54.1667%;
  padding: 0;
}

.col-xs-offset-13 {
  margin-left: 54.1667%;
}

.col-xs-14 {
  box-sizing: border-box;
  max-width: 58.3333%;
  flex-basis: 58.3333%;
  padding: 0;
}

.col-xs-offset-14 {
  margin-left: 58.3333%;
}

.col-xs-15 {
  box-sizing: border-box;
  max-width: 62.5%;
  flex-basis: 62.5%;
  padding: 0;
}

.col-xs-offset-15 {
  margin-left: 62.5%;
}

.col-xs-16 {
  box-sizing: border-box;
  max-width: 66.6667%;
  flex-basis: 66.6667%;
  padding: 0;
}

.col-xs-offset-16 {
  margin-left: 66.6667%;
}

.col-xs-17 {
  box-sizing: border-box;
  max-width: 70.8333%;
  flex-basis: 70.8333%;
  padding: 0;
}

.col-xs-offset-17 {
  margin-left: 70.8333%;
}

.col-xs-18 {
  box-sizing: border-box;
  max-width: 75%;
  flex-basis: 75%;
  padding: 0;
}

.col-xs-offset-18 {
  margin-left: 75%;
}

.col-xs-19 {
  box-sizing: border-box;
  max-width: 79.1667%;
  flex-basis: 79.1667%;
  padding: 0;
}

.col-xs-offset-19 {
  margin-left: 79.1667%;
}

.col-xs-20 {
  box-sizing: border-box;
  max-width: 83.3333%;
  flex-basis: 83.3333%;
  padding: 0;
}

.col-xs-offset-20 {
  margin-left: 83.3333%;
}

.col-xs-21 {
  box-sizing: border-box;
  max-width: 87.5%;
  flex-basis: 87.5%;
  padding: 0;
}

.col-xs-offset-21 {
  margin-left: 87.5%;
}

.col-xs-22 {
  box-sizing: border-box;
  max-width: 91.6667%;
  flex-basis: 91.6667%;
  padding: 0;
}

.col-xs-offset-22 {
  margin-left: 91.6667%;
}

.col-xs-23 {
  box-sizing: border-box;
  max-width: 95.8333%;
  flex-basis: 95.8333%;
  padding: 0;
}

.col-xs-offset-23 {
  margin-left: 95.8333%;
}

.col-xs-24 {
  box-sizing: border-box;
  max-width: 100%;
  flex-basis: 100%;
  padding: 0;
}

.col-xs-offset-24 {
  margin-left: 100%;
}

.row.start-xs {
  justify-content: flex-start;
}

.row.center-xs {
  justify-content: center;
}

.row.end-xs {
  justify-content: flex-end;
}

.row.top-xs {
  align-items: flex-start;
}

.row.middle-xs {
  align-items: center;
}

.row.bottom-xs {
  align-items: flex-end;
}

.row.around-xs {
  justify-content: space-around;
}

.row.between-xs {
  justify-content: space-between;
}

.first-xs {
  order: -1;
}

.last-xs {
  order: 1;
}

@media only screen and (min-width: 641px) {
  .col-sm {
    box-sizing: border-box;
    max-width: 100%;
    flex-grow: 1;
    flex-basis: 0;
    padding: 0;
  }

  .col-sm-1 {
    box-sizing: border-box;
    max-width: 4.16667%;
    flex-basis: 4.16667%;
    padding: 0;
  }

  .col-sm-offset-1 {
    margin-left: 4.16667%;
  }

  .col-sm-2 {
    box-sizing: border-box;
    max-width: 8.33333%;
    flex-basis: 8.33333%;
    padding: 0;
  }

  .col-sm-offset-2 {
    margin-left: 8.33333%;
  }

  .col-sm-3 {
    box-sizing: border-box;
    max-width: 12.5%;
    flex-basis: 12.5%;
    padding: 0;
  }

  .col-sm-offset-3 {
    margin-left: 12.5%;
  }

  .col-sm-4 {
    box-sizing: border-box;
    max-width: 16.6667%;
    flex-basis: 16.6667%;
    padding: 0;
  }

  .col-sm-offset-4 {
    margin-left: 16.6667%;
  }

  .col-sm-5 {
    box-sizing: border-box;
    max-width: 20.8333%;
    flex-basis: 20.8333%;
    padding: 0;
  }

  .col-sm-offset-5 {
    margin-left: 20.8333%;
  }

  .col-sm-6 {
    box-sizing: border-box;
    max-width: 25%;
    flex-basis: 25%;
    padding: 0;
  }

  .col-sm-offset-6 {
    margin-left: 25%;
  }

  .col-sm-7 {
    box-sizing: border-box;
    max-width: 29.1667%;
    flex-basis: 29.1667%;
    padding: 0;
  }

  .col-sm-offset-7 {
    margin-left: 29.1667%;
  }

  .col-sm-8 {
    box-sizing: border-box;
    max-width: 33.3333%;
    flex-basis: 33.3333%;
    padding: 0;
  }

  .col-sm-offset-8 {
    margin-left: 33.3333%;
  }

  .col-sm-9 {
    box-sizing: border-box;
    max-width: 37.5%;
    flex-basis: 37.5%;
    padding: 0;
  }

  .col-sm-offset-9 {
    margin-left: 37.5%;
  }

  .col-sm-10 {
    box-sizing: border-box;
    max-width: 41.6667%;
    flex-basis: 41.6667%;
    padding: 0;
  }

  .col-sm-offset-10 {
    margin-left: 41.6667%;
  }

  .col-sm-11 {
    box-sizing: border-box;
    max-width: 45.8333%;
    flex-basis: 45.8333%;
    padding: 0;
  }

  .col-sm-offset-11 {
    margin-left: 45.8333%;
  }

  .col-sm-12 {
    box-sizing: border-box;
    max-width: 50%;
    flex-basis: 50%;
    padding: 0;
  }

  .col-sm-offset-12 {
    margin-left: 50%;
  }

  .col-sm-13 {
    box-sizing: border-box;
    max-width: 54.1667%;
    flex-basis: 54.1667%;
    padding: 0;
  }

  .col-sm-offset-13 {
    margin-left: 54.1667%;
  }

  .col-sm-14 {
    box-sizing: border-box;
    max-width: 58.3333%;
    flex-basis: 58.3333%;
    padding: 0;
  }

  .col-sm-offset-14 {
    margin-left: 58.3333%;
  }

  .col-sm-15 {
    box-sizing: border-box;
    max-width: 62.5%;
    flex-basis: 62.5%;
    padding: 0;
  }

  .col-sm-offset-15 {
    margin-left: 62.5%;
  }

  .col-sm-16 {
    box-sizing: border-box;
    max-width: 66.6667%;
    flex-basis: 66.6667%;
    padding: 0;
  }

  .col-sm-offset-16 {
    margin-left: 66.6667%;
  }

  .col-sm-17 {
    box-sizing: border-box;
    max-width: 70.8333%;
    flex-basis: 70.8333%;
    padding: 0;
  }

  .col-sm-offset-17 {
    margin-left: 70.8333%;
  }

  .col-sm-18 {
    box-sizing: border-box;
    max-width: 75%;
    flex-basis: 75%;
    padding: 0;
  }

  .col-sm-offset-18 {
    margin-left: 75%;
  }

  .col-sm-19 {
    box-sizing: border-box;
    max-width: 79.1667%;
    flex-basis: 79.1667%;
    padding: 0;
  }

  .col-sm-offset-19 {
    margin-left: 79.1667%;
  }

  .col-sm-20 {
    box-sizing: border-box;
    max-width: 83.3333%;
    flex-basis: 83.3333%;
    padding: 0;
  }

  .col-sm-offset-20 {
    margin-left: 83.3333%;
  }

  .col-sm-21 {
    box-sizing: border-box;
    max-width: 87.5%;
    flex-basis: 87.5%;
    padding: 0;
  }

  .col-sm-offset-21 {
    margin-left: 87.5%;
  }

  .col-sm-22 {
    box-sizing: border-box;
    max-width: 91.6667%;
    flex-basis: 91.6667%;
    padding: 0;
  }

  .col-sm-offset-22 {
    margin-left: 91.6667%;
  }

  .col-sm-23 {
    box-sizing: border-box;
    max-width: 95.8333%;
    flex-basis: 95.8333%;
    padding: 0;
  }

  .col-sm-offset-23 {
    margin-left: 95.8333%;
  }

  .col-sm-24 {
    box-sizing: border-box;
    max-width: 100%;
    flex-basis: 100%;
    padding: 0;
  }

  .col-sm-offset-24 {
    margin-left: 100%;
  }

  .row.start-sm {
    justify-content: flex-start;
  }

  .row.center-sm {
    justify-content: center;
  }

  .row.end-sm {
    justify-content: flex-end;
  }

  .row.top-sm {
    align-items: flex-start;
  }

  .row.middle-sm {
    align-items: center;
  }

  .row.bottom-sm {
    align-items: flex-end;
  }

  .row.around-sm {
    justify-content: space-around;
  }

  .row.between-sm {
    justify-content: space-between;
  }

  .first-sm {
    order: -1;
  }

  .last-sm {
    order: 1;
  }
}

@media only screen and (min-width: 1024px) {
  .col-md {
    box-sizing: border-box;
    max-width: 100%;
    flex-grow: 1;
    flex-basis: 0;
    padding: 0;
  }

  .col-md-1 {
    box-sizing: border-box;
    max-width: 4.16667%;
    flex-basis: 4.16667%;
    padding: 0;
  }

  .col-md-offset-1 {
    margin-left: 4.16667%;
  }

  .col-md-2 {
    box-sizing: border-box;
    max-width: 8.33333%;
    flex-basis: 8.33333%;
    padding: 0;
  }

  .col-md-offset-2 {
    margin-left: 8.33333%;
  }

  .col-md-3 {
    box-sizing: border-box;
    max-width: 12.5%;
    flex-basis: 12.5%;
    padding: 0;
  }

  .col-md-offset-3 {
    margin-left: 12.5%;
  }

  .col-md-4 {
    box-sizing: border-box;
    max-width: 16.6667%;
    flex-basis: 16.6667%;
    padding: 0;
  }

  .col-md-offset-4 {
    margin-left: 16.6667%;
  }

  .col-md-5 {
    box-sizing: border-box;
    max-width: 20.8333%;
    flex-basis: 20.8333%;
    padding: 0;
  }

  .col-md-offset-5 {
    margin-left: 20.8333%;
  }

  .col-md-6 {
    box-sizing: border-box;
    max-width: 25%;
    flex-basis: 25%;
    padding: 0;
  }

  .col-md-offset-6 {
    margin-left: 25%;
  }

  .col-md-7 {
    box-sizing: border-box;
    max-width: 29.1667%;
    flex-basis: 29.1667%;
    padding: 0;
  }

  .col-md-offset-7 {
    margin-left: 29.1667%;
  }

  .col-md-8 {
    box-sizing: border-box;
    max-width: 33.3333%;
    flex-basis: 33.3333%;
    padding: 0;
  }

  .col-md-offset-8 {
    margin-left: 33.3333%;
  }

  .col-md-9 {
    box-sizing: border-box;
    max-width: 37.5%;
    flex-basis: 37.5%;
    padding: 0;
  }

  .col-md-offset-9 {
    margin-left: 37.5%;
  }

  .col-md-10 {
    box-sizing: border-box;
    max-width: 41.6667%;
    flex-basis: 41.6667%;
    padding: 0;
  }

  .col-md-offset-10 {
    margin-left: 41.6667%;
  }

  .col-md-11 {
    box-sizing: border-box;
    max-width: 45.8333%;
    flex-basis: 45.8333%;
    padding: 0;
  }

  .col-md-offset-11 {
    margin-left: 45.8333%;
  }

  .col-md-12 {
    box-sizing: border-box;
    max-width: 50%;
    flex-basis: 50%;
    padding: 0;
  }

  .col-md-offset-12 {
    margin-left: 50%;
  }

  .col-md-13 {
    box-sizing: border-box;
    max-width: 54.1667%;
    flex-basis: 54.1667%;
    padding: 0;
  }

  .col-md-offset-13 {
    margin-left: 54.1667%;
  }

  .col-md-14 {
    box-sizing: border-box;
    max-width: 58.3333%;
    flex-basis: 58.3333%;
    padding: 0;
  }

  .col-md-offset-14 {
    margin-left: 58.3333%;
  }

  .col-md-15 {
    box-sizing: border-box;
    max-width: 62.5%;
    flex-basis: 62.5%;
    padding: 0;
  }

  .col-md-offset-15 {
    margin-left: 62.5%;
  }

  .col-md-16 {
    box-sizing: border-box;
    max-width: 66.6667%;
    flex-basis: 66.6667%;
    padding: 0;
  }

  .col-md-offset-16 {
    margin-left: 66.6667%;
  }

  .col-md-17 {
    box-sizing: border-box;
    max-width: 70.8333%;
    flex-basis: 70.8333%;
    padding: 0;
  }

  .col-md-offset-17 {
    margin-left: 70.8333%;
  }

  .col-md-18 {
    box-sizing: border-box;
    max-width: 75%;
    flex-basis: 75%;
    padding: 0;
  }

  .col-md-offset-18 {
    margin-left: 75%;
  }

  .col-md-19 {
    box-sizing: border-box;
    max-width: 79.1667%;
    flex-basis: 79.1667%;
    padding: 0;
  }

  .col-md-offset-19 {
    margin-left: 79.1667%;
  }

  .col-md-20 {
    box-sizing: border-box;
    max-width: 83.3333%;
    flex-basis: 83.3333%;
    padding: 0;
  }

  .col-md-offset-20 {
    margin-left: 83.3333%;
  }

  .col-md-21 {
    box-sizing: border-box;
    max-width: 87.5%;
    flex-basis: 87.5%;
    padding: 0;
  }

  .col-md-offset-21 {
    margin-left: 87.5%;
  }

  .col-md-22 {
    box-sizing: border-box;
    max-width: 91.6667%;
    flex-basis: 91.6667%;
    padding: 0;
  }

  .col-md-offset-22 {
    margin-left: 91.6667%;
  }

  .col-md-23 {
    box-sizing: border-box;
    max-width: 95.8333%;
    flex-basis: 95.8333%;
    padding: 0;
  }

  .col-md-offset-23 {
    margin-left: 95.8333%;
  }

  .col-md-24 {
    box-sizing: border-box;
    max-width: 100%;
    flex-basis: 100%;
    padding: 0;
  }

  .col-md-offset-24 {
    margin-left: 100%;
  }

  .row.start-md {
    justify-content: flex-start;
  }

  .row.center-md {
    justify-content: center;
  }

  .row.end-md {
    justify-content: flex-end;
  }

  .row.top-md {
    align-items: flex-start;
  }

  .row.middle-md {
    align-items: center;
  }

  .row.bottom-md {
    align-items: flex-end;
  }

  .row.around-md {
    justify-content: space-around;
  }

  .row.between-md {
    justify-content: space-between;
  }

  .first-md {
    order: -1;
  }

  .last-md {
    order: 1;
  }
}

@media only screen and (min-width: 1440px) {
  .col-lg {
    box-sizing: border-box;
    max-width: 100%;
    flex-grow: 1;
    flex-basis: 0;
    padding: 0;
  }

  .col-lg-1 {
    box-sizing: border-box;
    max-width: 4.16667%;
    flex-basis: 4.16667%;
    padding: 0;
  }

  .col-lg-offset-1 {
    margin-left: 4.16667%;
  }

  .col-lg-2 {
    box-sizing: border-box;
    max-width: 8.33333%;
    flex-basis: 8.33333%;
    padding: 0;
  }

  .col-lg-offset-2 {
    margin-left: 8.33333%;
  }

  .col-lg-3 {
    box-sizing: border-box;
    max-width: 12.5%;
    flex-basis: 12.5%;
    padding: 0;
  }

  .col-lg-offset-3 {
    margin-left: 12.5%;
  }

  .col-lg-4 {
    box-sizing: border-box;
    max-width: 16.6667%;
    flex-basis: 16.6667%;
    padding: 0;
  }

  .col-lg-offset-4 {
    margin-left: 16.6667%;
  }

  .col-lg-5 {
    box-sizing: border-box;
    max-width: 20.8333%;
    flex-basis: 20.8333%;
    padding: 0;
  }

  .col-lg-offset-5 {
    margin-left: 20.8333%;
  }

  .col-lg-6 {
    box-sizing: border-box;
    max-width: 25%;
    flex-basis: 25%;
    padding: 0;
  }

  .col-lg-offset-6 {
    margin-left: 25%;
  }

  .col-lg-7 {
    box-sizing: border-box;
    max-width: 29.1667%;
    flex-basis: 29.1667%;
    padding: 0;
  }

  .col-lg-offset-7 {
    margin-left: 29.1667%;
  }

  .col-lg-8 {
    box-sizing: border-box;
    max-width: 33.3333%;
    flex-basis: 33.3333%;
    padding: 0;
  }

  .col-lg-offset-8 {
    margin-left: 33.3333%;
  }

  .col-lg-9 {
    box-sizing: border-box;
    max-width: 37.5%;
    flex-basis: 37.5%;
    padding: 0;
  }

  .col-lg-offset-9 {
    margin-left: 37.5%;
  }

  .col-lg-10 {
    box-sizing: border-box;
    max-width: 41.6667%;
    flex-basis: 41.6667%;
    padding: 0;
  }

  .col-lg-offset-10 {
    margin-left: 41.6667%;
  }

  .col-lg-11 {
    box-sizing: border-box;
    max-width: 45.8333%;
    flex-basis: 45.8333%;
    padding: 0;
  }

  .col-lg-offset-11 {
    margin-left: 45.8333%;
  }

  .col-lg-12 {
    box-sizing: border-box;
    max-width: 50%;
    flex-basis: 50%;
    padding: 0;
  }

  .col-lg-offset-12 {
    margin-left: 50%;
  }

  .col-lg-13 {
    box-sizing: border-box;
    max-width: 54.1667%;
    flex-basis: 54.1667%;
    padding: 0;
  }

  .col-lg-offset-13 {
    margin-left: 54.1667%;
  }

  .col-lg-14 {
    box-sizing: border-box;
    max-width: 58.3333%;
    flex-basis: 58.3333%;
    padding: 0;
  }

  .col-lg-offset-14 {
    margin-left: 58.3333%;
  }

  .col-lg-15 {
    box-sizing: border-box;
    max-width: 62.5%;
    flex-basis: 62.5%;
    padding: 0;
  }

  .col-lg-offset-15 {
    margin-left: 62.5%;
  }

  .col-lg-16 {
    box-sizing: border-box;
    max-width: 66.6667%;
    flex-basis: 66.6667%;
    padding: 0;
  }

  .col-lg-offset-16 {
    margin-left: 66.6667%;
  }

  .col-lg-17 {
    box-sizing: border-box;
    max-width: 70.8333%;
    flex-basis: 70.8333%;
    padding: 0;
  }

  .col-lg-offset-17 {
    margin-left: 70.8333%;
  }

  .col-lg-18 {
    box-sizing: border-box;
    max-width: 75%;
    flex-basis: 75%;
    padding: 0;
  }

  .col-lg-offset-18 {
    margin-left: 75%;
  }

  .col-lg-19 {
    box-sizing: border-box;
    max-width: 79.1667%;
    flex-basis: 79.1667%;
    padding: 0;
  }

  .col-lg-offset-19 {
    margin-left: 79.1667%;
  }

  .col-lg-20 {
    box-sizing: border-box;
    max-width: 83.3333%;
    flex-basis: 83.3333%;
    padding: 0;
  }

  .col-lg-offset-20 {
    margin-left: 83.3333%;
  }

  .col-lg-21 {
    box-sizing: border-box;
    max-width: 87.5%;
    flex-basis: 87.5%;
    padding: 0;
  }

  .col-lg-offset-21 {
    margin-left: 87.5%;
  }

  .col-lg-22 {
    box-sizing: border-box;
    max-width: 91.6667%;
    flex-basis: 91.6667%;
    padding: 0;
  }

  .col-lg-offset-22 {
    margin-left: 91.6667%;
  }

  .col-lg-23 {
    box-sizing: border-box;
    max-width: 95.8333%;
    flex-basis: 95.8333%;
    padding: 0;
  }

  .col-lg-offset-23 {
    margin-left: 95.8333%;
  }

  .col-lg-24 {
    box-sizing: border-box;
    max-width: 100%;
    flex-basis: 100%;
    padding: 0;
  }

  .col-lg-offset-24 {
    margin-left: 100%;
  }

  .row.start-lg {
    justify-content: flex-start;
  }

  .row.center-lg {
    justify-content: center;
  }

  .row.end-lg {
    justify-content: flex-end;
  }

  .row.top-lg {
    align-items: flex-start;
  }

  .row.middle-lg {
    align-items: center;
  }

  .row.bottom-lg {
    align-items: flex-end;
  }

  .row.around-lg {
    justify-content: space-around;
  }

  .row.between-lg {
    justify-content: space-between;
  }

  .first-lg {
    order: -1;
  }

  .last-lg {
    order: 1;
  }
}

@media only screen and (min-width: 1791px) {
  .col-xl {
    box-sizing: border-box;
    max-width: 100%;
    flex-grow: 1;
    flex-basis: 0;
    padding: 0;
  }

  .col-xl-1 {
    box-sizing: border-box;
    max-width: 4.16667%;
    flex-basis: 4.16667%;
    padding: 0;
  }

  .col-xl-offset-1 {
    margin-left: 4.16667%;
  }

  .col-xl-2 {
    box-sizing: border-box;
    max-width: 8.33333%;
    flex-basis: 8.33333%;
    padding: 0;
  }

  .col-xl-offset-2 {
    margin-left: 8.33333%;
  }

  .col-xl-3 {
    box-sizing: border-box;
    max-width: 12.5%;
    flex-basis: 12.5%;
    padding: 0;
  }

  .col-xl-offset-3 {
    margin-left: 12.5%;
  }

  .col-xl-4 {
    box-sizing: border-box;
    max-width: 16.6667%;
    flex-basis: 16.6667%;
    padding: 0;
  }

  .col-xl-offset-4 {
    margin-left: 16.6667%;
  }

  .col-xl-5 {
    box-sizing: border-box;
    max-width: 20.8333%;
    flex-basis: 20.8333%;
    padding: 0;
  }

  .col-xl-offset-5 {
    margin-left: 20.8333%;
  }

  .col-xl-6 {
    box-sizing: border-box;
    max-width: 25%;
    flex-basis: 25%;
    padding: 0;
  }

  .col-xl-offset-6 {
    margin-left: 25%;
  }

  .col-xl-7 {
    box-sizing: border-box;
    max-width: 29.1667%;
    flex-basis: 29.1667%;
    padding: 0;
  }

  .col-xl-offset-7 {
    margin-left: 29.1667%;
  }

  .col-xl-8 {
    box-sizing: border-box;
    max-width: 33.3333%;
    flex-basis: 33.3333%;
    padding: 0;
  }

  .col-xl-offset-8 {
    margin-left: 33.3333%;
  }

  .col-xl-9 {
    box-sizing: border-box;
    max-width: 37.5%;
    flex-basis: 37.5%;
    padding: 0;
  }

  .col-xl-offset-9 {
    margin-left: 37.5%;
  }

  .col-xl-10 {
    box-sizing: border-box;
    max-width: 41.6667%;
    flex-basis: 41.6667%;
    padding: 0;
  }

  .col-xl-offset-10 {
    margin-left: 41.6667%;
  }

  .col-xl-11 {
    box-sizing: border-box;
    max-width: 45.8333%;
    flex-basis: 45.8333%;
    padding: 0;
  }

  .col-xl-offset-11 {
    margin-left: 45.8333%;
  }

  .col-xl-12 {
    box-sizing: border-box;
    max-width: 50%;
    flex-basis: 50%;
    padding: 0;
  }

  .col-xl-offset-12 {
    margin-left: 50%;
  }

  .col-xl-13 {
    box-sizing: border-box;
    max-width: 54.1667%;
    flex-basis: 54.1667%;
    padding: 0;
  }

  .col-xl-offset-13 {
    margin-left: 54.1667%;
  }

  .col-xl-14 {
    box-sizing: border-box;
    max-width: 58.3333%;
    flex-basis: 58.3333%;
    padding: 0;
  }

  .col-xl-offset-14 {
    margin-left: 58.3333%;
  }

  .col-xl-15 {
    box-sizing: border-box;
    max-width: 62.5%;
    flex-basis: 62.5%;
    padding: 0;
  }

  .col-xl-offset-15 {
    margin-left: 62.5%;
  }

  .col-xl-16 {
    box-sizing: border-box;
    max-width: 66.6667%;
    flex-basis: 66.6667%;
    padding: 0;
  }

  .col-xl-offset-16 {
    margin-left: 66.6667%;
  }

  .col-xl-17 {
    box-sizing: border-box;
    max-width: 70.8333%;
    flex-basis: 70.8333%;
    padding: 0;
  }

  .col-xl-offset-17 {
    margin-left: 70.8333%;
  }

  .col-xl-18 {
    box-sizing: border-box;
    max-width: 75%;
    flex-basis: 75%;
    padding: 0;
  }

  .col-xl-offset-18 {
    margin-left: 75%;
  }

  .col-xl-19 {
    box-sizing: border-box;
    max-width: 79.1667%;
    flex-basis: 79.1667%;
    padding: 0;
  }

  .col-xl-offset-19 {
    margin-left: 79.1667%;
  }

  .col-xl-20 {
    box-sizing: border-box;
    max-width: 83.3333%;
    flex-basis: 83.3333%;
    padding: 0;
  }

  .col-xl-offset-20 {
    margin-left: 83.3333%;
  }

  .col-xl-21 {
    box-sizing: border-box;
    max-width: 87.5%;
    flex-basis: 87.5%;
    padding: 0;
  }

  .col-xl-offset-21 {
    margin-left: 87.5%;
  }

  .col-xl-22 {
    box-sizing: border-box;
    max-width: 91.6667%;
    flex-basis: 91.6667%;
    padding: 0;
  }

  .col-xl-offset-22 {
    margin-left: 91.6667%;
  }

  .col-xl-23 {
    box-sizing: border-box;
    max-width: 95.8333%;
    flex-basis: 95.8333%;
    padding: 0;
  }

  .col-xl-offset-23 {
    margin-left: 95.8333%;
  }

  .col-xl-24 {
    box-sizing: border-box;
    max-width: 100%;
    flex-basis: 100%;
    padding: 0;
  }

  .col-xl-offset-24 {
    margin-left: 100%;
  }

  .row.start-xl {
    justify-content: flex-start;
  }

  .row.center-xl {
    justify-content: center;
  }

  .row.end-xl {
    justify-content: flex-end;
  }

  .row.top-xl {
    align-items: flex-start;
  }

  .row.middle-xl {
    align-items: center;
  }

  .row.bottom-xl {
    align-items: flex-end;
  }

  .row.around-xl {
    justify-content: space-around;
  }

  .row.between-xl {
    justify-content: space-between;
  }

  .first-xl {
    order: -1;
  }

  .last-xl {
    order: 1;
  }
}

.col-gutter-lr, .col-no-gutter {
  padding: 0;
}

.show {
  display: block !important;
}

.row.show {
  display: flex !important;
}

.hide {
  display: none !important;
}

.show-xs {
  display: block !important;
}

.row.show-xs {
  display: flex !important;
}

.hide-xs {
  display: none !important;
}

@media only screen and (max-width: 640px) {
  .show-xs-only {
    display: block !important;
  }

  .row.show-xs-only {
    display: flex !important;
  }

  .hide-xs-only {
    display: none !important;
  }
}

@media only screen and (min-width: 641px) {
  .show-sm {
    display: block !important;
  }

  .row.show-sm {
    display: flex !important;
  }

  .hide-sm {
    display: none !important;
  }
}

@media only screen and (min-width: 641px) and (max-width: 1023px) {
  .show-sm-only {
    display: block !important;
  }

  .row.show-sm-only {
    display: flex !important;
  }

  .hide-sm-only {
    display: none !important;
  }
}

@media only screen and (min-width: 1024px) {
  .show-md {
    display: block !important;
  }

  .row.show-md {
    display: flex !important;
  }

  .hide-md {
    display: none !important;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1439px) {
  .show-md-only {
    display: block !important;
  }

  .row.show-md-only {
    display: flex !important;
  }

  .hide-md-only {
    display: none !important;
  }
}

@media only screen and (min-width: 1440px) {
  .show-lg {
    display: block !important;
  }

  .row.show-lg {
    display: flex !important;
  }

  .hide-lg {
    display: none !important;
  }
}

@media only screen and (min-width: 1440px) and (max-width: 1790px) {
  .show-lg-only {
    display: block !important;
  }

  .row.show-lg-only {
    display: flex !important;
  }

  .hide-lg-only {
    display: none !important;
  }
}

@media only screen and (min-width: 1791px) {
  .show-xl {
    display: block !important;
  }

  .row.show-xl {
    display: flex !important;
  }

  .hide-xl {
    display: none !important;
  }
}

.glide {
  width: 100%;
  box-sizing: border-box;
  position: relative;
}

.glide * {
  box-sizing: inherit;
}

.glide__track {
  overflow: hidden;
}

.glide__slides {
  width: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transform-style: preserve-3d;
  touch-action: pan-Y;
  white-space: nowrap;
  will-change: transform;
  flex-wrap: nowrap;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  position: relative;
  overflow: hidden;
}

.glide__slides--dragging {
  -webkit-user-select: none;
  user-select: none;
}

.glide__slide {
  width: 100%;
  height: 100%;
  white-space: normal;
  -webkit-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
  flex-shrink: 0;
}

.glide__slide a {
  -webkit-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
}

.glide__arrows, .glide__bullets {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
}

.glide--rtl {
  direction: rtl;
}

#ot-sdk-btn.ot-sdk-show-settings {
  color: inherit !important;
  border: inherit !important;
  height: inherit !important;
  white-space: inherit !important;
  word-wrap: inherit !important;
  padding: inherit !important;
  font-size: inherit !important;
  line-height: inherit !important;
  text-underline-offset: .2em !important;
  text-decoration-line: underline !important;
  text-decoration-color: #bca6d0 !important;
  text-decoration-thickness: 1px !important;
}

#ot-sdk-btn.ot-sdk-show-settings:hover {
  background-color: inherit !important;
  color: #a3009a !important;
}

#onetrust-consent-sdk #onetrust-pc-sdk .ot-acc-grpcntr.ot-acc-txt, #onetrust-consent-sdk #onetrust-pc-sdk .ot-acc-txt .ot-subgrp-tgl .ot-switch.ot-toggle, #onetrust-consent-sdk #onetrust-pc-sdk #ot-host-lst .ot-host-info, #onetrust-consent-sdk #onetrust-pc-sdk .ot-acc-txt .ot-ven-dets {
  background-color: #000 !important;
}

.simplebar-scrollbar:before {
  background: #fff;
  border-radius: 0;
  inset: 0;
}

.simplebar-track.simplebar-horizontal {
  height: 3px;
}

.simplebar-track.simplebar-vertical {
  width: 3px;
}

.simplebar-scrollbar.simplebar-visible:before {
  opacity: .3;
}

.simplebar-content-wrapper {
  height: 100% !important;
}

:root {
  --base-font-size: 17;
  --base-font-size-vw: 4.358;
}

@media only screen and (min-width: 641px) {
  :root {
    --base-font-size: 17;
    --base-font-size-vw: 2.656;
  }
}

@media only screen and (min-width: 1024px) {
  :root {
    --base-font-size: 18.5;
    --base-font-size-vw: 1.285;
  }
}

p, h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
  font-weight: normal;
}

ul, ol, li {
  margin: 0;
  padding: 0;
}

* {
  font-variant: no-contextual;
  font-size: calc((17 * var(--base-font-size-vw) / var(--base-font-size)) * 1vw);
  font-family: EK Roumald;
  line-height: 1.2;
}

@media only screen and (min-width: 641px) {
  * {
    font-size: calc((18.5 * var(--base-font-size-vw) / var(--base-font-size)) * 1vw);
    line-height: 1.1;
  }
}

ul, ul *, ol, ol *, li, li *, p, p *, .body-text, .body-text * {
  font-size: calc((17 * var(--base-font-size-vw) / var(--base-font-size)) * 1vw);
}

@media only screen and (min-width: 641px) {
  ul, ul *, ol, ol *, li, li *, p, p *, .body-text, .body-text * {
    font-size: calc((18.5 * var(--base-font-size-vw) / var(--base-font-size)) * 1vw);
  }
}

ul b, ul strong, ul * b, ul * strong, ol b, ol strong, ol * b, ol * strong, li b, li strong, li * b, li * strong, p b, p strong, p * b, p * strong, .body-text b, .body-text strong, .body-text * b, .body-text * strong {
  font-weight: bold;
}

p + p {
  margin-top: 1.1em;
}

@media only screen and (min-width: 641px) {
  p:not(:first-child) {
    margin-top: 1.1em;
  }
}

.h1-text-style, .h1-text-style *, h1, h1 * {
  font-size: calc((95 * var(--base-font-size-vw) / var(--base-font-size)) * 1vw);
  letter-spacing: -.02em;
  line-height: .95;
}

@media only screen and (min-width: 641px) {
  .h1-text-style, .h1-text-style *, h1, h1 * {
    font-size: calc((123.333 * var(--base-font-size-vw) / var(--base-font-size)) * 1vw);
    line-height: .85;
  }
}

.h2-text-style, .h2-text-style *, h2, h2 * {
  font-size: calc((25 * var(--base-font-size-vw) / var(--base-font-size)) * 1vw);
  line-height: 1.05;
}

@media only screen and (min-width: 1024px) {
  .h2-text-style, .h2-text-style *, h2, h2 * {
    font-size: calc((44.8485 * var(--base-font-size-vw) / var(--base-font-size)) * 1vw);
  }
}

.h3-text-style, .h3-text-style *, h3, h3 * {
  font-family: Cancellaresca;
  font-size: calc((34.7576 * var(--base-font-size-vw) / var(--base-font-size)) * 1vw);
  letter-spacing: .5px;
  line-height: .2;
}

.h4-text-style, .h4-text-style *, h4, h4 * {
  font-size: calc((29 * var(--base-font-size-vw) / var(--base-font-size)) * 1vw);
  text-transform: uppercase;
  line-height: .95;
}

@media only screen and (min-width: 641px) {
  .h4-text-style, .h4-text-style *, h4, h4 * {
    font-size: calc((28.0303 * var(--base-font-size-vw) / var(--base-font-size)) * 1vw);
  }
}

.h4-side-text-style, .h4-side-text-style * {
  font-size: calc((10 * var(--base-font-size-vw) / var(--base-font-size)) * 1vw);
  text-transform: uppercase;
  vertical-align: top;
  padding: .25em .5em;
  font-family: Suisse Intl Mono;
  display: inline-block;
}

@media only screen and (min-width: 641px) {
  .h4-side-text-style, .h4-side-text-style * {
    font-size: calc((12.6697 * var(--base-font-size-vw) / var(--base-font-size)) * 1vw);
  }
}

.h4-side-text-style:first-child, .h4-side-text-style :first-child {
  padding-left: 0;
}

.h4-side-text-style:last-child, .h4-side-text-style :last-child {
  padding-right: 0;
}

.h5-text-style, .h5-text-style *, h5, h5 * {
  font-family: Cancellaresca;
  font-size: calc((28.0303 * var(--base-font-size-vw) / var(--base-font-size)) * 1vw);
  letter-spacing: .5px;
  line-height: 1.1;
}

.h6-text-style, .h6-text-style *, h6, h6 * {
  font-family: Suisse Intl Mono;
  font-size: calc((12.95 * var(--base-font-size-vw) / var(--base-font-size)) * 1vw);
  letter-spacing: .01em;
  line-height: 1.05;
}

@media only screen and (min-width: 1024px) {
  .h6-text-style, .h6-text-style *, h6, h6 * {
    font-size: calc((17.603 * var(--base-font-size-vw) / var(--base-font-size)) * 1vw);
  }
}

.h7-text-style, .h7-text-style * {
  font-family: Suisse Intl Mono;
  font-size: calc((15.697 * var(--base-font-size-vw) / var(--base-font-size)) * 1vw);
  text-transform: uppercase;
  line-height: 1.1;
}

.side-note-text-style, .side-note-mobile, .side-note, .menu-nav-mobile__text, .side-note-text-style *, .side-note-mobile *, .side-note *, .menu-nav-mobile__text * {
  font-family: Suisse Intl Mono;
  font-size: calc((10.3 * var(--base-font-size-vw) / var(--base-font-size)) * 1vw);
  color: #49308c;
  line-height: 1.2;
}

@media only screen and (min-width: 641px) {
  .side-note-text-style, .side-note-mobile, .side-note, .menu-nav-mobile__text, .side-note-text-style *, .side-note-mobile *, .side-note *, .menu-nav-mobile__text * {
    font-size: calc((9.41818 * var(--base-font-size-vw) / var(--base-font-size)) * 1vw);
  }
}

.float-note-text-style, .float-note, .float-note-text-style *, .float-note * {
  font-family: Suisse Intl Mono;
  font-size: calc((10.9879 * var(--base-font-size-vw) / var(--base-font-size)) * 1vw);
  color: #49308c;
  line-height: 1.17;
}

.slideshow-text-style, .story-slideshow__captions, .slideshow-text-style *, .story-slideshow__captions * {
  font-family: Suisse Intl Mono;
  font-size: calc((10.3 * var(--base-font-size-vw) / var(--base-font-size)) * 1vw);
  color: #a3009a;
  line-height: 1.15;
}

@media only screen and (min-width: 641px) {
  .slideshow-text-style, .story-slideshow__captions, .slideshow-text-style *, .story-slideshow__captions * {
    font-size: calc((10.8758 * var(--base-font-size-vw) / var(--base-font-size)) * 1vw);
  }
}

.side-note-num-text-style, .side-note-num, .side-note-num-text-style *, .side-note-num * {
  font-family: Suisse Intl Mono;
  font-size: calc((10.8758 * var(--base-font-size-vw) / var(--base-font-size)) * 1vw);
  color: #a3009a;
  letter-spacing: -.03em;
  line-height: .9;
}

.side-pag-text-style, .story__pagination, .story__side-info, .spine-pop-up__text, .side-pag-text-style *, .story__pagination *, .story__side-info *, .spine-pop-up__text * {
  font-family: Suisse Intl Mono;
  font-size: calc((11.5485 * var(--base-font-size-vw) / var(--base-font-size)) * 1vw);
  color: #49308c;
  letter-spacing: -.03em;
  line-height: .9;
}

.slideshow-title-text-style, .story-slideshow__title, .slideshow-title-text-style *, .story-slideshow__title * {
  font-family: Suisse Intl Mono;
  font-size: calc((18 * var(--base-font-size-vw) / var(--base-font-size)) * 1vw);
  color: #a3009a;
  letter-spacing: -.03em;
  line-height: .9;
}

@media only screen and (min-width: 641px) {
  .slideshow-title-text-style, .story-slideshow__title, .slideshow-title-text-style *, .story-slideshow__title * {
    font-size: calc((19.8455 * var(--base-font-size-vw) / var(--base-font-size)) * 1vw);
  }
}

.hinge-text-style, .info__hinge-text, .hinge-text-style *, .info__hinge-text * {
  font-family: Suisse Intl Mono;
  font-size: calc((16.5 * var(--base-font-size-vw) / var(--base-font-size)) * 1vw);
}

@media only screen and (min-width: 641px) {
  .hinge-text-style, .info__hinge-text, .hinge-text-style *, .info__hinge-text * {
    font-size: calc((22.3 * var(--base-font-size-vw) / var(--base-font-size)) * 1vw);
  }
}

:root {
  --window-width: 100vw;
  --window-height: 100vh;
}

button {
  background: none;
  border: 0;
  outline: 0;
}

:focus-visible {
  outline-color: #49308c;
  box-shadow: 0 0 3px #bca6d0;
}

:focus:not(:focus-visible) {
  box-shadow: 0;
}

body {
  pointer-events: none;
  background-color: #f4e7f2;
}

body > * {
  opacity: 0;
}

body.doc-ready {
  pointer-events: all;
}

body.doc-ready > * {
  opacity: 1;
}

.content {
  min-height: 100vh;
}

.text-chapter, .text-pause {
  text-align: center;
  margin-bottom: 1.1em;
  font-family: Suisse Intl Mono;
  font-size: .83rem;
}

.text-chapter + p, .text-pause + p {
  margin-top: 0;
}

.text-chapter, .text-chapter *, .text-pause, .text-pause * {
  color: #49308c;
}

@media only screen and (min-width: 1024px) {
  .story__text-page--white .text-chapter, .story__text-page--white .text-chapter *, .story__text-page--white .text-pause, .story__text-page--white .text-pause * {
    color: #e4dbec;
  }
}

.text-chapter:not(:first-child) {
  margin-top: 3em !important;
}

.side-note-mobile + .text-chapter {
  margin-top: 1.5em !important;
}

@media only screen and (min-width: 1024px) {
  .side-note-mobile + .text-chapter {
    margin-top: 3em !important;
  }
}

.texture-overlay {
  pointer-events: none;
  opacity: .4;
  background-image: url("texture1.86848797.png");
  background-position: 0 0;
  background-repeat: repeat-y;
  background-size: 250vw;
  position: absolute;
  inset: 0;
  z-index: 15 !important;
}

@media only screen and (min-width: 1024px) {
  .texture-overlay {
    background-position: 0 0;
    background-size: 110vw;
  }

  .story__text-page + .story__text-page .texture-overlay {
    background-position: 50% 0;
  }
}

.texture-overlay--1 {
  background-image: url("texture1.86848797.png");
}

.texture-overlay--2 {
  background-image: url("texture2.2c74aa21.png");
}

.texture-overlay--3 .texture-overlay {
  background-image: url("texture3.2bccf85c.png");
}

.story__cover-page .texture-overlay {
  opacity: .4;
}

.menu-mobile .texture-overlay, .story__section > .texture-overlay, .story__slideshow-page .texture-overlay, .story__text-page .texture-overlay {
  opacity: .3;
}

.menu .texture-overlay {
  opacity: .3;
  background-position: center;
}

.story__image-page .texture-overlay {
  opacity: .3;
}

@media only screen and (min-width: 1024px) {
  .story__image-page .texture-overlay {
    opacity: .4;
  }
}

.menu-nav-mobile {
  height: calc(2.5em + 10px);
  z-index: 20;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  overflow: hidden;
}

@media only screen and (min-width: 1024px) {
  .menu-nav-mobile {
    display: none;
  }
}

.menu-nav-mobile__text {
  text-align: center;
  text-transform: uppercase;
  justify-content: center;
  align-items: center;
  padding-left: 2.6em;
  padding-right: 2.2em;
  display: flex;
  position: absolute;
  inset: 0;
}

.menu-nav-mobile__text, .menu-nav-mobile__text * {
  color: #e3c4de;
}

.menu-nav-mobile__bg {
  z-index: -1;
  background-color: #49308c;
  position: absolute;
  inset: 0;
}

.menu-nav-mobile__bg-texture {
  opacity: .4;
  width: 100%;
  height: 100vh;
  background-image: url("texture1.86848797.png");
  background-position: 0 0;
  background-repeat: repeat-y;
  background-size: 250vw;
}

.menu-hamb-mobile {
  width: 2.2em;
  height: 2.5em;
  z-index: 300;
  display: block;
  position: fixed;
  top: 5px;
  left: 5px;
}

@media only screen and (min-width: 1024px) {
  .menu-hamb-mobile {
    display: none;
  }
}

.menu-hamb-mobile span {
  width: 80%;
  height: 3px;
  background-color: #bca6d0;
  position: absolute;
  top: calc(50% - 1.5px);
  left: 10%;
}

.menu-hamb-mobile span:after, .menu-hamb-mobile span:before {
  content: "";
  height: 3px;
  background-color: #bca6d0;
  position: absolute;
  top: -.625em;
  left: 0;
  right: 0;
}

.menu-hamb-mobile span:after {
  top: .625em;
}

[data-current-state="menu"] .menu-hamb-mobile span {
  background-color: #0000 !important;
}

[data-current-state="menu"] .menu-hamb-mobile span:before {
  top: 0;
  transform: rotate(-45deg);
}

[data-current-state="menu"] .menu-hamb-mobile span:after {
  top: 0;
  transform: rotate(45deg);
}

.menu-icon__bg, .menu-icon {
  width: 2.6em;
  height: 2.4em;
  z-index: 300;
  display: none;
  position: fixed;
  top: 15px;
  left: 15px;
}

@media only screen and (min-width: 1024px) {
  .menu-icon__bg, .menu-icon {
    display: block;
  }
}

.menu-icon__bg, .menu-icon__bg *, .menu-icon, .menu-icon * {
  color: #fff;
  font-family: Suisse Intl Mono;
  font-size: calc((12.5576 * var(--base-font-size-vw) / var(--base-font-size)) * 1vw);
  letter-spacing: .03em;
  line-height: 1;
}

.menu-icon__bg {
  z-index: 299;
}

.menu-icon {
  text-transform: uppercase;
  text-align: center;
  mix-blend-mode: difference;
  border: 1px solid #fff;
  text-decoration: none;
}

@media only screen and (min-width: 1024px) {
  .menu-icon {
    display: flex;
  }
}

.menu-icon:hover + .menu-icon__bg {
  background-color: #bca6d0;
}

.cover--active .menu-icon {
  mix-blend-mode: normal;
}

.cover--active .menu-icon, .cover--active .menu-icon * {
  color: #000;
  border-color: #000;
}

.cover--active .menu-icon .menu-icon__hamb span, .cover--active .menu-icon .menu-icon__hamb span:after, .cover--active .menu-icon .menu-icon__hamb span:before {
  background-color: #000;
}

.invert-menu-icon--active .menu-icon {
  mix-blend-mode: normal;
}

.invert-menu-icon--active .menu-icon, .invert-menu-icon--active .menu-icon * {
  color: #f4e7f2;
  border-color: #f4e7f2;
}

.invert-menu-icon--active .menu-icon .menu-icon__hamb span, .invert-menu-icon--active .menu-icon .menu-icon__hamb span:after, .invert-menu-icon--active .menu-icon .menu-icon__hamb span:before {
  background-color: #f4e7f2;
}

.menu-icon__hamb {
  width: 100%;
  height: 100%;
  position: relative;
}

.menu-icon__hamb span {
  width: 80%;
  height: 1px;
  background-color: #fff;
  transition: transform .2s ease-in-out, top .2s ease-in-out;
  position: absolute;
  top: calc(50% - .5px);
  left: 10%;
}

.menu-icon__hamb span:after, .menu-icon__hamb span:before {
  content: "";
  height: 1px;
  transform-origin: center;
  background-color: #fff;
  transition: transform .2s ease-in-out, top .2s ease-in-out;
  position: absolute;
  top: -.48em;
  left: 0;
  right: 0;
}

.menu-icon__hamb span:after {
  top: .48em;
}

[data-current-state="menu"] .menu-icon__hamb span {
  background-color: #0000 !important;
}

[data-current-state="menu"] .menu-icon__hamb span:before {
  top: 0;
  transform: rotate(-45deg);
}

[data-current-state="menu"] .menu-icon__hamb span:after {
  top: 0;
  transform: rotate(45deg);
}

body.is-animating [data-navigo] {
  pointer-events: none;
}

[data-srcset] {
  filter: blur(5px);
  transition: filter .3s ease-out;
}

[data-srcset].lazyloaded {
  filter: blur();
}

.side-note[data-note-order="2"], .float-note[data-note-order="2"] {
  transition-delay: .2s;
}

.side-note[data-note-order="3"], .float-note[data-note-order="3"] {
  transition-delay: .4s;
}

.side-note[data-note-order="4"], .float-note[data-note-order="4"] {
  transition-delay: .6s;
}

.side-note[data-note-order="5"], .float-note[data-note-order="5"] {
  transition-delay: .8s;
}

.side-note[data-note-order="6"], .float-note[data-note-order="6"] {
  transition-delay: 1s;
}

.side-note[data-note-order="7"], .float-note[data-note-order="7"] {
  transition-delay: 1.2s;
}

.side-note[data-note-order="8"], .float-note[data-note-order="8"] {
  transition-delay: 1.4s;
}

.side-note[data-note-order="9"], .float-note[data-note-order="9"] {
  transition-delay: 1.6s;
}

.side-note[data-note-order="10"], .float-note[data-note-order="10"] {
  transition-delay: 1.8s;
}

.floating {
  will-change: transform;
  animation-name: floating;
  animation-duration: 2.5s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}

@keyframes floating {
  0% {
    transform: translate(0);
  }

  50% {
    transform: translate(.1em, .5em);
  }

  100% {
    transform: translate(0);
  }
}

body[data-current-state="menu"] {
  overflow: hidden;
}

.menu {
  z-index: 200;
  pointer-events: none;
  text-selection: none;
  display: none;
  position: fixed;
  inset: 0;
  overflow-x: hidden;
  overflow-y: auto;
}

@media only screen and (min-width: 1024px) {
  .menu {
    display: block;
  }
}

.menu__content {
  min-height: 100vh;
  display: flex;
  position: relative;
}

.menu__book-cover {
  pointer-events: all;
  background-color: #f4e7f2;
  flex: 0 0 50%;
  overflow: hidden;
}

.menu__spines {
  flex: 0 0 50%;
  position: relative;
}

.menu a {
  color: inherit;
  text-decoration: none;
}

.spines {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;
}

.spines__spine-wrapper {
  height: 100%;
  pointer-events: all;
  height: 100%;
  flex: 0 0 16.6667%;
  overflow: hidden;
}

.spines__spine-wrapper:nth-child(5n+1) {
  background-color: #fff;
}

.spines__spine-wrapper:nth-child(5n+2) {
  background-color: #e3dbec;
}

.spines__spine-wrapper:nth-child(5n+3) {
  background-color: #eedceb;
}

.spines__spine-wrapper:nth-child(5n+4) {
  background-color: #bca6d0;
}

.spines__spine-wrapper:nth-child(5n+5) {
  background-color: #e3c4de;
}

.spines a {
  width: 100%;
  height: 100%;
  text-decoration: none;
  display: block;
}

.spines a:focus-visible {
  border: 1px solid #49308c;
}

.spines a :focus:not(:focus-visible) {
  border: 0;
}

.spines__spine {
  width: 100%;
  height: 100%;
  transition: transform .3s ease-out;
}

.spines__spine:hover {
  transform: scale(1.02)translateY(-5px);
}

.is-animating .spines__spine {
  transform: none !important;
}

.book-cover {
  flex-direction: column;
  display: flex;
  position: relative;
}

.book-cover h6 {
  text-transform: uppercase;
}

.book-cover__content {
  height: 100%;
  min-height: 100vh;
  flex-direction: column;
  justify-content: space-between;
  padding: 15px;
  display: flex;
}

.book-cover__header {
  text-align: center;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  margin-bottom: 5em;
  display: flex;
}

.book-cover__logo {
  width: 80%;
  margin-top: 15px;
  margin-bottom: 22.5px;
}

.book-cover__logo img {
  width: 100%;
}

.book-cover__menu {
  align-items: flex-end;
  display: flex;
  position: absolute;
  bottom: 22.5px;
  left: 15px;
}

.book-cover__button {
  text-align: center;
  justify-content: center;
  margin-right: .25em;
  display: flex;
  position: relative;
}

.book-cover__button * {
  font-family: Suisse Intl Mono;
  font-size: calc((12.5576 * var(--base-font-size-vw) / var(--base-font-size)) * 1vw);
  line-height: .9;
}

.book-cover__button:after {
  content: "";
  z-index: 1;
  background-color: #fff;
  position: absolute;
  inset: 0;
}

.book-cover__button span {
  z-index: 2;
  min-width: 9.4em;
  border: .5px solid;
  padding: .825em 2.1em .6em;
  position: relative;
}

.book-cover__button:hover {
  cursor: pointer;
}

.book-cover__button:hover:after {
  background-color: #bca6d0;
}

.book-cover__button:hover span {
  color: #556148;
  border-color: #556148;
}

.book-cover__button--info, .book-cover__button--info span, .book-cover__button--info:after {
  border-radius: 100%;
}

.book-cover__cupid {
  z-index: 2;
  pointer-events: none;
  width: 12em;
  mix-blend-mode: multiply;
  position: absolute;
  bottom: -1.5em;
  right: .8em;
  transform: rotate(10deg);
}

.book-cover__cupid img {
  width: 100%;
}

.book-cover__cupid-speech {
  width: 7em;
  mix-blend-mode: multiply;
  position: absolute;
  bottom: 3.4em;
  right: 11em;
  transform: rotate(7deg);
}

.book-cover__cupid-speech img {
  transform: scale(-1);
}

.book-cover__cupid-speech .illustration-img span {
  width: 70%;
  top: 3.45em;
  left: 1.2em;
  transform: rotate(-1deg);
}

.book-cover__corner-page-line {
  pointer-events: none;
  width: 6.9em;
  mix-blend-mode: multiply;
  z-index: 3;
  opacity: .85;
  position: absolute;
  bottom: -.25em;
  right: -.25em;
}

.book-cover__corner-page-line img {
  width: 100%;
}

.book-cover__corner-page {
  z-index: 2;
  pointer-events: none;
  width: 6.7em;
  position: absolute;
  bottom: -.25em;
  right: -.25em;
}

.book-cover__corner-page img {
  width: 100%;
}

.spine {
  flex-direction: column;
  align-items: center;
  display: flex;
  position: relative;
}

.spine * {
  color: #000;
}

.spine__title {
  text-orientation: sideways;
  writing-mode: vertical-lr;
  flex-grow: 1;
  padding: 15px;
}

.spine__author {
  text-align: center;
  padding: 7.5px 7.5px 16.5px;
}

.spine-pop-up {
  pointer-events: none;
  width: 10em;
  height: auto;
  z-index: 2;
  text-align: center;
  opacity: 0;
  background-color: #f4e7f2;
  border: 1px solid #49308c;
  padding: 1em;
  position: absolute;
  top: 0;
  left: 0;
}

[data-current-state^="story-"] .spine-pop-up, .is-animating .spine-pop-up {
  opacity: 0 !important;
}

.spine-pop-up__title {
  font-size: calc((22 * var(--base-font-size-vw) / var(--base-font-size)) * 1vw);
  margin-bottom: .5em;
}

.spine-pop-up__text {
  color: #000;
  line-height: 1.2;
}

.menu-mobile {
  z-index: 200;
  pointer-events: none;
  position: fixed;
  inset: 0;
  overflow: hidden;
}

@media only screen and (min-width: 1024px) {
  .menu-mobile {
    display: none;
  }
}

.animation-menu-hidden .menu-mobile {
  display: none;
}

.menu-mobile__content {
  height: var(--window-height);
  flex-direction: column;
  display: flex;
  position: relative;
}

.menu-mobile__book-cover {
  pointer-events: all;
  min-height: 0;
  background-color: #f4e7f2;
  flex: 100%;
  position: relative;
}

.menu-mobile__spines {
  flex-grow: 1;
}

.menu-mobile__footer {
  pointer-events: all;
}

.menu-mobile a {
  color: inherit;
}

.spines-mobile {
  width: 100%;
  height: 100%;
  flex-direction: column;
  display: flex;
}

.spines-mobile__spine-wrapper {
  pointer-events: all;
  height: 100%;
  flex: 0 0 16.6667%;
}

.spines-mobile__spine-wrapper:nth-child(5n+1) {
  background-color: #fff;
}

.spines-mobile__spine-wrapper:nth-child(5n+2) {
  background-color: #e3dbec;
}

.spines-mobile__spine-wrapper:nth-child(5n+3) {
  background-color: #eedceb;
}

.spines-mobile__spine-wrapper:nth-child(5n+4) {
  background-color: #bca6d0;
}

.spines-mobile__spine-wrapper:nth-child(5n+5) {
  background-color: #e3c4de;
}

.spines-mobile a {
  text-decoration: none;
}

.book-cover-mobile {
  text-align: center;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  display: flex;
  position: relative;
}

.book-cover-mobile h6 {
  text-transform: uppercase;
}

.book-cover-mobile__header {
  height: 100%;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  padding: 10.5px 15px 15px;
  display: flex;
}

.book-cover-mobile__logo {
  min-height: 0;
  margin-top: 7.5px;
  margin-bottom: 10.5px;
  overflow: hidden;
}

.book-cover-mobile__logo img {
  width: 90%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}

.spine-mobile {
  align-items: center;
  padding: 22.5px 15px;
  display: flex;
  position: relative;
}

.spine-mobile * {
  color: #000;
}

.spine-mobile__title {
  text-transform: none;
  flex-grow: 1;
}

.spine-mobile__author {
  text-align: center;
  text-transform: uppercase;
  padding-top: 7.5px;
}

.footer-mobile {
  background-color: #f4e7f2;
  position: relative;
}

.footer-mobile__menu {
  justify-content: center;
  align-items: center;
  margin-top: 15px;
  margin-bottom: 15px;
  display: flex;
}

.footer-mobile__button {
  text-transform: none;
  text-decoration: underline;
  position: relative;
}

.footer-mobile__button:not(:first-child) {
  margin-left: .5em;
}

@media only screen and (min-width: 1024px) {
  .stories {
    scroll-snap-type: y mandatory;
    height: 100vh;
    overflow: auto;
  }
}

.stories:focus {
  border: 0;
  outline: 0;
}

.story {
  background-color: #f4e7f2;
}

.story__bg {
  z-index: 0;
  background-color: #f4e7f2;
  position: absolute;
  inset: 0;
}

.story__bg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.story__section:not(.story__section--fullimage) .story__bg img {
  display: none;
}

@media only screen and (min-width: 1024px) {
  .story__section:not(.story__section--fullimage) .story__bg img {
    display: block;
  }

  .story__bg--overlay:before {
    content: "";
    z-index: 2;
    opacity: .3;
    background: #000;
    position: absolute;
    inset: 0;
  }
}

.story__side-info {
  writing-mode: vertical-rl;
  text-orientation: mixed;
  z-index: 2;
  text-transform: uppercase;
  display: none;
  position: fixed;
  top: 50%;
  right: calc(1em + 3px);
  transform: translateY(-50%)rotate(180deg);
}

.invert-side-info--active .story__side-info, .invert-side-info--active .story__side-info * {
  color: #f4e7f2;
}

@media only screen and (min-width: 1024px) {
  .story__side-info {
    display: block;
  }
}

.story__pagination {
  z-index: 2;
  display: none;
  position: fixed;
  bottom: 1em;
  right: calc(1em + 3px);
}

.invert-side-info--active .story__pagination, .invert-side-info--active .story__pagination * {
  color: #f4e7f2;
}

@media only screen and (min-width: 1024px) {
  .story__pagination {
    display: block;
  }
}

.story__section {
  position: relative;
  overflow: hidden;
}

@media only screen and (min-width: 1024px) {
  .story__section {
    overflow: initial;
  }
}

.story__section--cover {
  z-index: 3;
}

@media only screen and (min-width: 1024px) {
  .story__section--cover, .story__section--fullimage {
    min-height: 100vh;
  }
}

.story__section--fullimage .story__bg {
  display: none;
}

@media only screen and (min-width: 1024px) {
  .story__section--fullimage .story__bg {
    display: block;
  }

  .story__section {
    min-height: 100vh;
    scroll-snap-align: start;
    scroll-snap-stop: always;
  }
}

.story__pages-container {
  width: 100%;
}

.story__pages-container .simplebar-content {
  display: flex;
}

@media only screen and (min-width: 1024px) {
  .story__pages-container {
    flex-wrap: no-wrap;
    min-height: 100vh;
    display: flex;
  }
}

.story__cover-page {
  width: 100%;
  height: calc(var(--window-height) * .9);
  background-color: #49308c;
  flex: 0 0 100%;
  padding: 60px 15px 15px;
  position: relative;
}

@media only screen and (min-width: 1024px) {
  .story__cover-page {
    width: 50%;
    min-height: 100vh;
    height: initial;
    flex: 0 0 50%;
    padding: 70px 20px 20px;
  }
}

.story__text-page {
  width: 100%;
  background-color: #f4e7f2;
  flex: 0 0 100%;
  padding: 0 15px;
  position: relative;
  overflow: hidden;
}

@media only screen and (min-width: 1024px) {
  .story__text-page {
    width: 50%;
    overflow-x: initial;
    overflow-y: initial;
    flex: 0 0 50%;
    padding: 75px 15px;
  }

  .story__text-page + .story__text-page:before {
    content: "";
    width: 1px;
    opacity: .5;
    background-color: #bca6d0;
    position: absolute;
    top: 0;
    bottom: 0;
    left: -1px;
  }
}

.story__text-page--white {
  background-color: #0000;
}

@media only screen and (min-width: 1024px) {
  .story__text-page--white, .story__text-page--white * {
    color: #fff;
  }
}

.story__image-page {
  width: 100%;
  flex: 0 0 100%;
  position: relative;
}

.story__section:not(.story__section--cover) .story__image-page:last-child {
  padding-bottom: 15px;
}

@media only screen and (min-width: 1024px) {
  .story__section:not(.story__section--cover) .story__image-page:last-child {
    padding-bottom: 0;
  }
}

.story__section:not(.story__section--cover) .story__image-page .story__bg-grid, .story__section:not(.story__section--cover) .story__image-page .story__bg-image {
  padding: 0 15px;
}

.story__section:not(.story__section--cover) .story__text-content .story__image-page .story__bg-image {
  padding: 0;
}

@media only screen and (min-width: 1024px) {
  .story__section:not(.story__section--cover) .story__text-content .story__image-page {
    display: none;
  }

  .story__image-page {
    width: 50%;
    height: 100vh;
    flex: 0 0 50%;
    position: sticky;
    top: 0;
    overflow: hidden;
  }

  .story__image-page .story__bg-grid, .story__image-page .story__bg-image {
    padding: 0;
  }

  .story__section:not(.story__section--cover) .story__image-page {
    width: 50%;
    height: 100vh;
    flex: 0 0 50%;
    padding: 0;
  }

  .story__section:not(.story__section--cover) .story__image-page .story__bg-grid, .story__section:not(.story__section--cover) .story__image-page .story__bg-image {
    padding: 0;
  }
}

.story__image-page > :not(.story__bg-image):not(.story__bg-grid) {
  z-index: 2;
}

@media only screen and (min-width: 1024px) {
  .story__text-content {
    width: 97%;
  }
}

.story__side-notes {
  display: none;
}

@media only screen and (min-width: 1024px) {
  .story__side-notes {
    display: block;
  }
}

.story__text-shape {
  shape-outside: content-box;
  float: left;
  border-radius: 100%;
  display: none;
}

@media only screen and (min-width: 1024px) {
  .story__text-shape {
    display: block;
  }
}

.story__bg-grid, .story__bg-image {
  height: calc(160vw - 48px);
  position: relative;
  overflow: hidden;
}

@media only screen and (min-width: 1024px) {
  .story__bg-grid, .story__bg-image {
    height: 100%;
    position: absolute;
    inset: 0;
  }
}

.story__bg-grid img, .story__bg-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top center;
}

@media only screen and (min-width: 1024px) {
  .story__bg-grid--overlay:before, .story__bg-image--overlay:before {
    content: "";
    z-index: 2;
    opacity: .3;
    background: linear-gradient(0deg, #000 0%, #000c 30%, #0000 50% 100%);
    position: absolute;
    inset: 0;
  }

  .story__bg-grid--overlay-top:before, .story__bg-image--overlay-top:before {
    content: "";
    z-index: 2;
    opacity: .3;
    background: linear-gradient(#000 0%, #000c 30%, #0000 50% 100%);
    position: absolute;
    inset: 0;
  }
}

[data-page-num="1"] .story__text-page {
  padding-top: 30px;
}

@media only screen and (min-width: 1024px) {
  [data-page-num="1"] .story__text-page {
    padding-top: 75px;
  }
}

.cover {
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.cover * {
  color: #fff;
}

.cover__header {
  text-align: center;
  height: 100%;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  display: flex;
}

.cover__title {
  margin-bottom: 5rem;
}

@media only screen and (min-width: 1024px) {
  .cover__title {
    margin-bottom: 3rem;
  }
}

.cover__who {
  margin-bottom: .3em;
}

.cover__match {
  margin-bottom: 1.3em;
}

@media only screen and (min-width: 1024px) {
  .cover__match {
    opacity: 0;
    will-change: opacity, transform;
    transition: opacity 1s ease-out .8s, transform .8s ease-out .8s;
    transform: translateY(.25em);
  }

  .is-animating .cover__match {
    transition-delay: 1s;
  }

  .story__section--active .cover__match {
    opacity: 1;
    transform: translateY(0);
  }
}

.cover__footer {
  text-align: center;
  margin: 2.5em 1.5em;
}

@media only screen and (min-width: 1024px) {
  .cover__footer {
    margin: 0;
    margin-bottom: calc((9.41818 * var(--base-font-size-vw) / var(--base-font-size)) * 1vw * 10 - 20px - .2em);
    opacity: 0;
    will-change: opacity, transform;
    margin-top: 5em;
    transition: opacity 1s ease-out 1.2s, transform .8s ease-out 1.2s;
    transform: translateY(.5em);
  }

  .is-animating .cover__footer {
    transition-delay: 1.5s;
  }

  .story__section--active .cover__footer {
    opacity: 1;
    transform: translateY(0);
  }
}

.cover__footer br {
  display: none;
}

@media only screen and (min-width: 1024px) {
  .cover__footer br {
    display: block;
  }
}

.cover-float-img {
  z-index: 2;
  width: 8.33333vw;
  position: absolute;
}

@media only screen and (min-width: 1024px) {
  .cover-float-img {
    opacity: 0;
    will-change: opacity, transform;
    transition: opacity 1s ease-out;
    transform: scale(.98);
  }

  .story__section--active .cover-float-img {
    opacity: 1;
    transform: scale(1);
  }

  .story__section--cover .cover-float-img {
    transition-delay: .8s;
  }
}

.cover-float-img video, .cover-float-img img {
  width: 100%;
  height: auto;
}

[data-float-img="2"] {
  transition-delay: 1s;
}

[data-float-img="3"] {
  transition-delay: 1.2s;
}

[data-float-img="4"] {
  transition-delay: 1.4s;
}

[data-float-img="5"] {
  transition-delay: 1.6s;
}

[data-float-img="6"] {
  transition-delay: 1.8s;
}

[data-float-img="7"] {
  transition-delay: 2s;
}

[data-float-img="8"] {
  transition-delay: 2.2s;
}

[data-float-img="9"] {
  transition-delay: 2.4s;
}

[data-float-img="10"] {
  transition-delay: 2.6s;
}

.side-note-num {
  height: 1.3em;
  border: 1px solid #49308c;
  border-radius: 1em;
  margin: 0 .3em;
  padding: .2em .3em 0;
  display: inline-block;
  position: relative;
  top: -.25em;
}

@media only screen and (min-width: 1024px) {
  .story__text-page--white .side-note-num {
    border-color: #bca6d0;
  }
}

.side-note-mobile, .side-note {
  opacity: 0;
  will-change: opacity, transform;
  transition: opacity 1s ease-out, transform .8s ease-out;
  position: relative;
  transform: translateY(6em);
}

.story__section--active .side-note-mobile, .story__section--active .side-note {
  opacity: 1;
  transform: translateY(0);
}

.side-note-mobile__title, .side-note__title {
  text-transform: uppercase;
}

.side-note-mobile__title, .side-note-mobile__title *, .side-note__title, .side-note__title * {
  color: #a3009a;
}

@media only screen and (min-width: 1024px) {
  .story__text-page--white .side-note-mobile__title, .story__text-page--white .side-note-mobile__title *, .story__text-page--white .side-note__title, .story__text-page--white .side-note__title * {
    color: #e4dbec;
  }
}

.side-note-mobile__img, .side-note__img {
  width: 100%;
  height: auto;
  border: 1px solid #a3009a;
}

.illustration-img-container--multiply .side-note-mobile__img, .illustration-img-container--lighten .side-note-mobile__img, .illustration-img-container--multiply .side-note__img, .illustration-img-container--lighten .side-note__img {
  border: 0;
}

.side-note-mobile__img + p, p + .side-note-mobile__img, .side-note__img + p, p + .side-note__img {
  margin-top: 1em;
}

.side-note-mobile__img video, .side-note-mobile__img img, .side-note__img video, .side-note__img img {
  width: 100%;
  height: auto;
  display: block;
}

.side-note-mobile__img--round, .side-note__img--round {
  border-radius: 100%;
  overflow: hidden;
}

.side-note-mobile {
  transform: initial;
  opacity: 1;
  text-align: center;
  max-width: 80%;
  margin-left: auto;
  margin-right: auto;
  padding: 2.4em 0;
  transition: initial;
}

.side-note-mobile__img {
  width: 50%;
  margin-left: auto;
  margin-right: auto;
}

@media only screen and (min-width: 1024px) {
  .side-note-mobile {
    display: none;
  }
}

.side-note-mobile.illustration-img-container--multiply, .side-note-mobile.illustration-img-container--lighten {
  padding: 0 0 2.4em;
}

.side-note-mobile + .side-note-mobile {
  padding-top: 0;
}

.story__bg-image + .side-note-mobile, .story__bg-grid + .side-note-mobile {
  padding-top: 1em;
}

.float-note {
  width: 8.33333vw;
  display: none;
  position: absolute;
}

@media only screen and (min-width: 1024px) {
  .float-note {
    opacity: 0;
    will-change: opacity, transform;
    transition: opacity 1s ease-out, transform .8s ease-out;
    display: block;
    transform: translateY(6em);
  }

  .story__section--active .float-note {
    opacity: 1;
    transform: translateY(0);
  }
}

.float-note, .float-note * {
  color: #fff;
}

.float-note__title {
  text-transform: uppercase;
}

.float-note__title, .float-note__title * {
  color: #e4dbec;
}

.float-note__img {
  width: 100%;
  border: 1px solid #f4e7f2;
}

.float-note__img img {
  width: 100%;
}

.float-note__img + p, p + .float-note__img {
  margin-top: .5em;
}

.float-note__img--round, #story-1-page-3-floatnote-1 .float-note__img {
  border-radius: 100%;
  overflow: hidden;
}

.float-note__line {
  background: linear-gradient(to top right, #0000 0% calc(50% - .8px), #fff 50%, #0000 calc(50% + .8px) 100%);
  position: absolute;
}

.story__section--active .float-note__line {
  animation-name: clipPathChangeLeftToRight;
  animation-duration: .8s;
  animation-iteration-count: 1;
  animation-fill-mode: both;
}

.float-note__line--right {
  background: linear-gradient(to top left, #0000 0% calc(50% - .8px), #fff 50%, #0000 calc(50% + .8px) 100%);
}

.story__section--active .float-note__line--right {
  animation-name: clipPathChangeRightToLeft;
}

.float-note__line--top {
  transform: scaleY(-1);
}

[data-note-order="1"] .float-note__line {
  animation-delay: 1s;
}

[data-note-order="2"] .float-note__line {
  animation-delay: 1.2s;
}

[data-note-order="3"] .float-note__line {
  animation-delay: 1.4s;
}

[data-note-order="4"] .float-note__line {
  animation-delay: 1.6s;
}

[data-note-order="5"] .float-note__line {
  animation-delay: 1.8s;
}

[data-note-order="6"] .float-note__line {
  animation-delay: 2s;
}

[data-note-order="7"] .float-note__line {
  animation-delay: 2.2s;
}

[data-note-order="8"] .float-note__line {
  animation-delay: 2.4s;
}

[data-note-order="9"] .float-note__line {
  animation-delay: 2.6s;
}

[data-note-order="10"] .float-note__line {
  animation-delay: 2.8s;
}

@keyframes clipPathChangeLeftToRight {
  from {
    -webkit-clip-path: inset(0 100% 0 0);
    clip-path: inset(0 100% 0 0);
  }

  to {
    -webkit-clip-path: inset(0 0% 0 0);
    clip-path: inset(0 0% 0 0);
  }
}

@keyframes clipPathChangeRightToLeft {
  from {
    -webkit-clip-path: inset(0 0 0 100%);
    clip-path: inset(0 0 0 100%);
  }

  to {
    -webkit-clip-path: inset(0 0 0 0%);
    clip-path: inset(0 0 0 0%);
  }
}

.illustration-img-container--multiply {
  mix-blend-mode: multiply;
}

.illustration-img-container--multiply .float-note__img, .illustration-img-container--multiply .side-note__img {
  border: 0;
}

.illustration-img-container--lighten {
  mix-blend-mode: lighten;
}

.illustration-img-container--lighten img {
  filter: invert();
}

.illustration-img-container--lighten .illustration-img, .illustration-img-container--lighten .illustration-img * {
  color: #fff;
}

.illustration-img-container--lighten .float-note__img, .illustration-img-container--lighten .side-note__img {
  border: 0;
}

.illustration-img {
  width: 100%;
  height: auto;
}

.illustration-img video, .illustration-img img {
  width: 100%;
  height: auto;
  display: block;
}

.illustration-img span {
  width: 100%;
  text-align: center;
  color: #000;
  text-transform: uppercase;
  font-family: Suisse Intl;
  font-size: calc((13.0061 * var(--base-font-size-vw) / var(--base-font-size)) * 1vw);
  line-height: 1.05;
  position: absolute;
  top: 0;
  left: 0;
}

.story-slideshow {
  cursor: grab;
  background-color: #d7cae3;
  overflow: hidden;
}

@media only screen and (min-width: 1024px) {
  .story-slideshow {
    height: 100vh;
  }
}

.story-slideshow.glide--dragging {
  cursor: grabbing;
}

.story-slideshow ul, .story-slideshow ol {
  list-style: none;
}

.story-slideshow__title {
  width: 100%;
  max-width: 500px;
  text-align: center;
  text-transform: uppercase;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  padding-top: 2em;
  padding-bottom: 4em;
  display: flex;
}

@media only screen and (min-width: 1024px) {
  .story-slideshow__title {
    height: 5rem;
    padding-bottom: 0;
  }
}

.story-slideshow__slides {
  display: flex;
}

.story-slideshow__slide-content {
  width: 100%;
  height: 75vw;
}

@media only screen and (min-width: 1024px) {
  .story-slideshow__slide-content {
    height: calc(100vh - 12rem);
  }
}

.story-slideshow__image {
  width: 65%;
  height: 95%;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}

@media only screen and (min-width: 1024px) {
  .story-slideshow__image {
    width: 100%;
    height: 100%;
  }
}

.story-slideshow__image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
  display: block;
}

@media only screen and (min-width: 1024px) {
  .story-slideshow__captions {
    align-items: flex-end;
    display: flex;
    position: absolute;
    bottom: 3.4em;
    left: 0;
    right: 0;
  }
}

.story-slideshow__caption {
  width: calc(100% - 60px);
  text-align: center;
  margin: .5em 30px;
  display: none;
}

@media only screen and (min-width: 1024px) {
  .story-slideshow__caption {
    width: 100%;
    max-width: 650px;
    margin: 0;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
}

@media only screen and (min-width: 1300px) {
  .story-slideshow__caption {
    max-width: 840px;
  }
}

.story-slideshow__caption.active {
  display: block;
}

.story-slideshow__caption-title {
  color: #49308c;
}

.story-slideshow__thumbnails {
  width: calc(12em + 3px);
  flex-wrap: wrap;
  margin-top: 2em;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 4em;
  display: flex;
}

@media only screen and (min-width: 1024px) {
  .story-slideshow__thumbnails {
    padding-bottom: 0;
    position: absolute;
    bottom: 15px;
    left: 15px;
  }
}

.story-slideshow__thumbnail {
  width: 4em;
  height: 4em;
  background-color: #0000;
  border: 0;
  outline: 1px solid #a3009a;
  margin-bottom: 1px;
  margin-left: 1px;
  padding: 0;
  overflow: hidden;
}

.story-slideshow__thumbnail img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  opacity: .3;
  transition: opacity .2s ease-in;
}

@media only screen and (min-width: 641px) {
  .story-slideshow__thumbnail:hover img {
    cursor: pointer;
    opacity: .5;
  }
}

.story-slideshow__thumbnail.glide__bullet--active img {
  opacity: 1;
}

.story-slideshow__arrow {
  transform-origin: center;
  padding: 0;
  position: absolute;
  top: 14.7em;
  right: .5em;
  transform: rotate(-90deg);
}

@media only screen and (min-width: 641px) {
  .story-slideshow__arrow {
    top: 19.7em;
  }
}

@media only screen and (min-width: 1024px) {
  .story-slideshow__arrow {
    right: 6em;
    top: calc(50% - calc((56.0606 * var(--base-font-size-vw) / var(--base-font-size)) * 1vw) * .88);
  }
}

.story-slideshow__arrow span {
  font-size: calc((35 * var(--base-font-size-vw) / var(--base-font-size)) * 1vw);
  color: #a3009a;
  cursor: pointer;
  padding: 0;
}

@media only screen and (min-width: 1024px) {
  .story-slideshow__arrow span {
    font-size: calc((56.0606 * var(--base-font-size-vw) / var(--base-font-size)) * 1vw);
  }

  .story-slideshow__arrow span:hover {
    color: #49308c;
  }
}

.story-slideshow__arrow--left {
  right: initial;
  left: .5em;
  transform: scaleY(-1)rotate(90deg);
}

@media only screen and (min-width: 1024px) {
  .story-slideshow__arrow--left {
    left: 6em;
  }
}

.bg-grid {
  z-index: 0;
  flex-wrap: wrap;
  display: flex;
}

.bg-grid__img {
  height: 33.3333%;
  flex: 0 0 50%;
}

.bg-grid img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top center;
  outline: 1px solid #f4e7f2;
}

.foreword {
  background-color: #d7cae3;
}

.foreword .story__section {
  text-align: center;
  padding: 60px 45px;
}

@media only screen and (min-width: 1024px) {
  .foreword .story__section {
    text-align: left;
    min-height: 100vh;
    padding: 2.5em 1em 5em;
  }
}

.foreword__title {
  text-align: center;
  margin-bottom: 3.4em;
}

@media only screen and (min-width: 1024px) {
  .foreword__title {
    margin-bottom: 4em;
  }
}

[data-story="ro-kwon"] [data-page-num="0"] .story__bg-image img {
  object-position: center 80%;
}

[data-story="ro-kwon"] [data-page-num="1"] .story__bg-image {
  padding-bottom: 15px;
}

@media only screen and (min-width: 1024px) {
  [data-story="ro-kwon"] [data-page-num="1"] .story__bg-image {
    padding-bottom: 0;
  }
}

[data-story="ro-kwon"] [data-page-num="4"] .story__image-page .story__bg-image {
  margin-top: 0 !important;
}

[data-story="ro-kwon"] .story__section--last-page .story__text-page {
  padding-bottom: 30px;
}

@media only screen and (min-width: 1024px) {
  [data-story="ro-kwon"] .story__section--last-page .story__text-page {
    padding-bottom: 0;
  }
}

#story-1-page-0-floatnote-1 {
  width: 36%;
  bottom: -2vw;
  left: 7vw;
}

#story-1-page-0-floatnote-1 .float-note__img {
  transform: rotate(10deg);
}

#story-1-page-1-sidenote-1 {
  margin-top: 2rem;
}

#story-1-page-1-sidenote-2 {
  margin-top: 10.7rem;
}

#story-1-page-2-sidenote-1-1 {
  width: 11em;
  margin-top: 3em;
  margin-left: 17em;
}

@media only screen and (min-width: 641px) {
  #story-1-page-2-sidenote-1-1 {
    width: 13em;
    margin-top: 4em;
    margin-left: 49vw;
  }
}

@media only screen and (min-width: 1024px) {
  #story-1-page-2-sidenote-1-1 {
    width: 13em;
    margin-top: -2em;
    margin-left: 11em;
  }
}

#story-1-page-2-sidenote-1-1 img {
  transform: rotate(-10deg);
}

#story-1-page-2-sidenote-1-1 span {
  width: 66%;
  top: 1.7em;
  left: 1.7em;
  transform: rotate(-10deg);
}

@media only screen and (min-width: 1024px) {
  #story-1-page-2-sidenote-1-1 span {
    width: 60%;
    top: 1.8em;
    left: 2em;
    transform: rotate(0);
  }
}

#story-1-page-2-sidenote-1-2 {
  width: 58vw;
  margin-top: -38vw;
  margin-bottom: -3em;
  margin-left: 4vw;
}

@media only screen and (min-width: 641px) {
  #story-1-page-2-sidenote-1-2 {
    width: 41vw;
    margin-top: -25.5vw;
    margin-left: 20vw;
  }
}

@media only screen and (min-width: 1024px) {
  #story-1-page-2-sidenote-1-2 {
    width: 22.5em;
    margin-top: -10.5em;
    margin-left: -4.5em;
  }
}

#story-1-page-2-sidenote-1-2 .side-note-mobile__img {
  width: 100%;
  transform: scaleX(-1);
}

#story-1-page-2-sidenote-2 {
  margin-top: 26em;
}

#story-1-page-2-textshape-1 {
  width: 10em;
  height: 6.5em;
  border-radius: 100%;
  margin-top: -2.5em;
  margin-left: -5.5em;
}

#story-1-page-2-floatnote-1 {
  width: 17vw;
  bottom: 3.4em;
  right: 3em;
}

#story-1-page-3-floatnote-1 {
  width: 25em;
  bottom: 3.4em;
  left: 15px;
}

#story-1-page-3-floatnote-1 .float-note__img {
  border-color: #bca6d0;
  margin-bottom: 1em;
}

#story-1-page-3-floatnote-1 p {
  width: 105%;
}

#story-1-page-3-text {
  margin-top: 63vh;
}

#story-1-page-4-floatnote-1-1 {
  width: 8.5vw;
  top: 10vw;
  left: 35vw;
}

#story-1-page-4-floatnote-1-1 img {
  transform: scaleY(-1)rotate(-15deg);
}

#story-1-page-4-floatnote-1-1 span {
  width: 60%;
  top: 2.6em;
  left: 1.8em;
  transform: rotate(10deg);
}

#story-1-page-4-floatnote-1-2 {
  width: 15vw;
  top: -1vw;
  left: 25vw;
}

#story-1-page-4-floatnote-1-2 img {
  transform: scaleX(-1)rotate(-15deg);
}

#story-1-page-4-sidenote-1 {
  margin-top: 19em;
}

#story-1-page-4-sidenote-2 {
  margin-top: 4em;
}

#story-1-page-4-sidenotemobile-final-1 {
  width: 33vw;
  max-width: initial;
  margin-top: 11em;
  margin-left: 46vw;
}

@media only screen and (min-width: 641px) {
  #story-1-page-4-sidenotemobile-final-1 {
    width: 20.5vw;
    margin-top: 15em;
    margin-left: 50vw;
  }
}

#story-1-page-4-sidenotemobile-final-1 .side-note-mobile__img {
  width: 100%;
}

#story-1-page-4-sidenotemobile-final-1 img {
  transform: scaleY(-1)rotate(-7deg);
}

#story-1-page-4-sidenotemobile-final-1 span {
  width: 80%;
  top: 3em;
  left: 1.2em;
  transform: rotate(3deg);
}

#story-1-page-4-sidenotemobile-final-2 {
  width: 65vw;
  max-width: initial;
  margin-top: -25em;
  margin-bottom: -9em;
  margin-left: -2vw;
}

@media only screen and (min-width: 641px) {
  #story-1-page-4-sidenotemobile-final-2 {
    width: 48vw;
    max-width: initial;
    margin-top: -31em;
    margin-left: 12vw;
  }
}

#story-1-page-4-sidenotemobile-final-2 .side-note-mobile__img {
  width: 100%;
}

#story-1-page-4-sidenotemobile-final-2 img {
  transform: rotate(10deg);
}

[data-story="oisin-mckenna"] [data-page-num="2"] .story__image-page + .story__text-page {
  padding-top: 1.2em;
}

@media only screen and (min-width: 1024px) {
  [data-story="oisin-mckenna"] [data-page-num="2"] .story__image-page + .story__text-page {
    padding-top: 75px;
  }
}

[data-story="oisin-mckenna"] [data-page-num="2"] .story__bg-image img {
  object-position: center bottom;
}

[data-story="oisin-mckenna"] [data-page-num="3"] .story__text-page + .story__image-page {
  padding-top: 1.2em;
  padding-bottom: 0;
}

@media only screen and (min-width: 1024px) {
  [data-story="oisin-mckenna"] [data-page-num="3"] .story__text-page + .story__image-page {
    padding-top: 0;
  }
}

[data-story="oisin-mckenna"] [data-page-num="4"] .story__bg img {
  object-position: center bottom;
}

#story-2-page-1-floatnote-1 {
  width: 19em;
  top: 27vw;
  left: 2.5vw;
}

#story-2-page-1-floatnote-1 .float-note__line {
  width: 2.5vw;
  height: 3.5vw;
  top: -3vw;
  left: 15.5vw;
}

#story-2-page-1-floatnote-2 {
  width: 14em;
  top: 27vw;
  left: 29vw;
}

#story-2-page-1-floatnote-2 .float-note__line {
  width: 1vw;
  height: 4.5vw;
  top: -2vw;
  left: -1.5vw;
}

#story-2-page-1-floatnote-3-1 {
  width: 10vw;
  bottom: 4.5em;
  left: 20vw;
}

#story-2-page-1-floatnote-3-1 img {
  transform: scaleY(-1);
}

#story-2-page-1-floatnote-3-1 .illustration-img {
  transform: rotate(-5deg);
}

#story-2-page-1-floatnote-3-1 span {
  width: 82%;
  top: 3em;
  left: 1.2em;
}

#story-2-page-1-floatnote-3-2 {
  width: 17vw;
  bottom: -4.5em;
  left: 8vw;
}

#story-2-page-1-sidenote-1 {
  margin-top: 13.5em;
}

#story-2-page-1-sidenote-2 {
  margin-top: 13em;
}

#story-2-page-1-sidenote-3 {
  margin-top: 2em;
}

#story-2-page-2-floatnote-1-1 {
  width: 14vw;
  bottom: 11.5em;
  left: 32vw;
}

#story-2-page-2-floatnote-1-1 .illustration-img {
  transform: rotate(15deg);
}

#story-2-page-2-floatnote-1-1 span {
  width: 64%;
  top: 2.2em;
  left: 3.9em;
  transform: rotate(7deg);
}

#story-2-page-2-floatnote-1-2 {
  width: 22vw;
  bottom: 2em;
  left: 17vw;
}

#story-2-page-2-floatnote-1-2 img {
  transform: scaleX(-1);
}

#story-2-page-2-sidenote-1 {
  width: 108%;
  letter-spacing: -.02em;
  margin-top: 8em;
}

#story-2-page-2-sidenote-2 {
  width: 108%;
  letter-spacing: -.02em;
  margin-top: 6.5em;
}

#story-2-page-2-sidenote-2 .side-note__img {
  width: 15.5vw;
  height: 15.5vw;
  border-radius: 100%;
  overflow: hidden;
}

#story-2-page-2-sidenotemobile-2 .side-note-mobile__img {
  width: 70%;
  border-radius: 100%;
  overflow: hidden;
}

#story-2-page-2-textshape-1 {
  width: 17vw;
  height: 17vw;
  margin-top: 16.7em;
  margin-left: -9.5em;
}

#story-2-page-3-sidenote-1 {
  margin-top: 5em;
}

#story-2-page-3-sidenote-2 {
  width: 110%;
  letter-spacing: -.01em;
  margin-top: 13em;
}

#story-2-page-3-floatnote-1 {
  width: 80%;
  text-align: center;
  margin-left: 10%;
  bottom: 5em;
}

#story-2-page-4-floatnote-1 {
  width: 28vw;
  z-index: 3;
  top: 75px;
  left: 15px;
}

#story-2-page-4-floatnote-2-1 {
  width: 11vw;
  top: 7em;
  left: 13.5vw;
}

#story-2-page-4-floatnote-2-1 .illustration-img {
  transform: rotate(5deg);
}

#story-2-page-4-floatnote-2-1 span {
  width: 75%;
  top: 2em;
  left: 1.5em;
}

#story-2-page-4-floatnote-2-2 {
  width: 16vw;
  top: 5em;
  left: 27vw;
}

#story-2-page-4-floatnote-2-2 img {
  transform: rotate(-15deg);
}

#story-2-page-5-sidenotemobile-1-1 {
  width: 38vw;
  max-width: initial;
  margin-top: 7em;
  margin-left: 44vw;
}

@media only screen and (min-width: 641px) {
  #story-2-page-5-sidenotemobile-1-1 {
    width: 23.5vw;
    margin-top: 11em;
    margin-left: 45vw;
  }
}

#story-2-page-5-sidenotemobile-1-1 img {
  transform: scaleY(-1)rotate(7deg);
}

#story-2-page-5-sidenotemobile-1-1 span {
  width: 80%;
  top: 3em;
  left: 1.7em;
  transform: rotate(-3deg);
}

#story-2-page-5-sidenotemobile-1-2 {
  width: 65vw;
  max-width: initial;
  margin-top: -24em;
  margin-bottom: -3em;
  margin-left: -8vw;
}

@media only screen and (min-width: 641px) {
  #story-2-page-5-sidenotemobile-1-2 {
    width: 48vw;
    max-width: initial;
    margin-top: -30em;
    margin-left: 7vw;
  }
}

#story-2-page-5-sidenotemobile-1-2 .side-note-mobile__img {
  width: 100%;
}

#story-2-page-5-sidenotemobile-1-2 img {
  transform: rotate(10deg);
}

#story-2-page-5-sidenote-1 {
  margin-top: 2em;
}

#story-2-page-5-sidenote-2 {
  margin-top: 15em;
}

#story-2-page-5-floatnote-1-1 {
  width: 11vw;
  top: 9em;
  left: 14.5vw;
}

#story-2-page-5-floatnote-1-1 .illustration-img {
  transform: rotate(-15deg);
}

#story-2-page-5-floatnote-1-1 img {
  transform: scaleY(-1);
}

#story-2-page-5-floatnote-1-1 span {
  width: 56%;
  top: 3.5em;
  left: 3em;
}

#story-2-page-5-floatnote-1-2 {
  width: 17vw;
  top: -3vw;
  left: 0;
}

#story-2-page-5-floatnote-1-2 img {
  transform: rotate(15deg);
}

#story-2-page-5-sidenotemobile-final {
  width: 65vw;
  max-width: initial;
  margin-top: -4em;
  margin-bottom: -6em;
  margin-left: 16vw;
}

@media only screen and (min-width: 641px) {
  #story-2-page-5-sidenotemobile-final {
    width: 48vw;
    max-width: initial;
    margin-top: -5em;
    margin-bottom: -5em;
    margin-left: 28vw;
  }
}

#story-2-page-5-sidenotemobile-final .side-note-mobile__img {
  width: 100%;
}

[data-story="brontez-purnell"] [data-page-num="1"] .story__bg-image {
  padding-top: 15px;
  padding-bottom: 15px;
}

@media only screen and (min-width: 1024px) {
  [data-story="brontez-purnell"] [data-page-num="1"] .story__bg-image {
    padding-top: 0;
    padding-bottom: 0;
  }
}

[data-story="brontez-purnell"] [data-page-num="2"] .story__bg-image img {
  object-position: center 25%;
}

[data-story="brontez-purnell"] [data-page-num="2"] .story__image-page {
  padding-top: 15px;
}

@media only screen and (min-width: 1024px) {
  [data-story="brontez-purnell"] [data-page-num="2"] .story__image-page {
    padding-top: 0;
  }
}

[data-story="brontez-purnell"] [data-page-num="3"] .story__bg img {
  object-position: center top;
}

[data-story="brontez-purnell"] [data-page-num="4"] .story__image-page {
  padding-top: 15px;
  padding-bottom: 15px;
}

@media only screen and (min-width: 1024px) {
  [data-story="brontez-purnell"] [data-page-num="4"] .story__image-page {
    padding-top: 0;
    padding-bottom: 0;
  }
}

[data-story="brontez-purnell"] [data-page-num="4"] .story__image-page .texture-overlay {
  opacity: .3;
}

[data-story="brontez-purnell"] [data-page-num="5"] .story__text-page {
  padding-top: 15px;
}

@media only screen and (min-width: 1024px) {
  [data-story="brontez-purnell"] [data-page-num="5"] .story__text-page {
    padding-top: 75px;
  }
}

[data-story="brontez-purnell"] [data-page-num="5"] .story__image-page {
  overflow: hidden;
}

#story-3-page-1-sidenote-1 {
  margin-top: 16em;
}

#story-3-page-1-sidenote-2 {
  margin-top: 3em;
}

#story-3-page-2-sidenote-1 {
  margin-top: .5em;
}

#story-3-page-2-sidenote-1 p {
  width: 106%;
}

#story-3-page-2-sidenote-2 {
  margin-top: 3em;
}

#story-3-page-2-sidenote-2 p {
  width: 104%;
  letter-spacing: -.01em;
}

#story-3-page-2-sidenote-3 {
  margin-top: 10em;
}

#story-3-page-2-floatnote-1-1 {
  width: 13.5vw;
  top: 11vw;
  left: 43%;
}

#story-3-page-2-floatnote-1-1 img {
  transform: scale(-1)rotate(-17deg);
}

#story-3-page-2-floatnote-1-1 span {
  width: 57%;
  top: 4.5em;
  left: 2.5em;
  transform: rotate(-5deg);
}

#story-3-page-2-floatnote-1-2 {
  width: 32%;
  top: -6em;
  left: 40%;
}

#story-3-page-2-floatnote-1-2 .float-note__img {
  transform: scaleX(-1)rotate(-60deg);
}

#story-3-page-3-sidenote-1 {
  margin-top: 12em;
}

#story-3-page-3-sidenote-2 {
  margin-top: 4em;
}

#story-3-page-4-sidenotemobile-1-1 {
  width: 37vw;
  max-width: initial;
  margin-top: 3em;
  margin-left: 17vw;
}

@media only screen and (min-width: 641px) {
  #story-3-page-4-sidenotemobile-1-1 {
    width: 22vw;
    margin-top: 4em;
    margin-left: 30vw;
  }
}

#story-3-page-4-sidenotemobile-1-1 .illustration-img {
  transform: rotate(-35deg);
}

#story-3-page-4-sidenotemobile-1-1 span {
  width: 55%;
  top: 1.5em;
  left: 3em;
}

#story-3-page-4-sidenotemobile-1-2 {
  width: 78vw;
  max-width: initial;
  margin-top: -13em;
  margin-bottom: -8em;
  margin-left: 8vw;
}

@media only screen and (min-width: 641px) {
  #story-3-page-4-sidenotemobile-1-2 {
    width: 48vw;
    max-width: initial;
    margin-top: -14em;
    margin-bottom: -10em;
    margin-left: 25vw;
  }
}

#story-3-page-4-sidenotemobile-1-2 .side-note-mobile__img {
  width: 100%;
  transform: scaleX(-1)rotate(-2deg);
}

#story-3-page-4-floatnote-1-1 {
  width: 9.5vw;
  bottom: 13vw;
  right: 12.5%;
}

#story-3-page-4-floatnote-1-1 img {
  transform: rotate(-35deg);
}

#story-3-page-4-floatnote-1-1 span {
  width: 55%;
  top: 1.3em;
  left: 2.5em;
  transform: rotate(-32deg);
}

#story-3-page-4-floatnote-1-2 {
  width: 35%;
  bottom: -5em;
  right: 0;
}

#story-3-page-4-floatnote-1-2 .float-note__img {
  transform: scaleX(-1)rotate(-10deg);
}

#story-3-page-4-sidenote-1 {
  margin-top: .5em;
}

#story-3-page-4-sidenote-1 p {
  width: 104%;
  letter-spacing: -.01em;
}

#story-3-page-4-sidenote-2 {
  margin-top: 4em;
}

#story-3-page-4-sidenote-2 p {
  width: 104%;
  letter-spacing: -.01em;
}

#story-3-page-4-sidenote-3 {
  margin-top: 3em;
}

#story-3-page-5-sidenote-1-1 {
  width: 9vw;
  top: 25vw;
  left: 1vw;
}

#story-3-page-5-sidenote-1-1 img {
  transform: scaleX(-1)rotate(-7deg);
}

#story-3-page-5-sidenote-1-1 span {
  width: 75%;
  top: 1.5em;
  left: 1.1em;
  transform: rotate(2deg);
}

#story-3-page-5-sidenote-1-2 {
  width: 17vw;
  top: 16vw;
  left: 5.5vw;
}

#story-3-page-5-sidenote-1-2 .side-note__img {
  transform: rotate(-10deg);
}

#story-3-page-5-sidenotemobile-final-1 {
  width: 33vw;
  margin-top: 5vw;
  left: -22vw;
}

@media only screen and (min-width: 641px) {
  #story-3-page-5-sidenotemobile-final-1 {
    width: 22vw;
    margin-top: 8vw;
    left: -15vw;
  }
}

#story-3-page-5-sidenotemobile-final-1 img {
  transform: scaleX(-1)rotate(-7deg);
}

#story-3-page-5-sidenotemobile-final-1 span {
  width: 75%;
  top: 1.5em;
  left: 1.1em;
  transform: rotate(2deg);
}

#story-3-page-5-sidenotemobile-final-2 {
  width: 64vw;
  margin-top: -44vw;
  margin-bottom: -10vw;
  left: 13vw;
}

@media only screen and (min-width: 641px) {
  #story-3-page-5-sidenotemobile-final-2 {
    width: 43vw;
    margin-top: -29vw;
    margin-bottom: -7vw;
    left: 9vw;
  }
}

#story-3-page-5-sidenotemobile-final-2 .side-note-mobile__img {
  width: 100%;
}

#story-3-page-5-sidenotemobile-final-2 .side-note-mobile__img img {
  transform: rotate(-10deg);
}

#story-3-page-5-floatnote-2 {
  width: 75%;
  text-align: center;
  bottom: 3.4em;
  left: 12.5%;
}

[data-story="isle-mcelroy"] [data-page-num="1"] .story__image-page, [data-story="isle-mcelroy"] [data-page-num="3"] .story__image-page {
  padding-top: 15px;
  padding-bottom: 0;
}

@media only screen and (min-width: 1024px) {
  [data-story="isle-mcelroy"] [data-page-num="1"] .story__image-page, [data-story="isle-mcelroy"] [data-page-num="3"] .story__image-page {
    padding-top: 0;
  }
}

[data-story="isle-mcelroy"] [data-page-num="2"] > .texture-overlay {
  opacity: .075;
}

[data-story="isle-mcelroy"] [data-page-num="4"] .story__image-page .texture-overlay {
  opacity: .3;
}

[data-story="isle-mcelroy"] [data-page-num="4"] .story__text-page {
  padding-top: 15px;
}

@media only screen and (min-width: 1024px) {
  [data-story="isle-mcelroy"] [data-page-num="4"] .story__text-page {
    padding-top: 75px;
  }
}

[data-story="isle-mcelroy"] [data-page-num="5"] .story__image-page {
  padding-top: 15px;
  padding-bottom: 15px;
}

@media only screen and (min-width: 1024px) {
  [data-story="isle-mcelroy"] [data-page-num="5"] .story__image-page {
    padding-top: 0;
    padding-bottom: 0;
  }
}

[data-story="isle-mcelroy"] .story__section--last-page .story__text-page {
  padding-bottom: 30px;
}

@media only screen and (min-width: 1024px) {
  [data-story="isle-mcelroy"] .story__section--last-page .story__text-page {
    padding-bottom: 0;
  }
}

#story-4-page-0-floatnote-1 {
  width: 15vw;
  top: 0;
  right: -1.8vw;
}

#story-4-page-1-floatnote-1 {
  width: 12vw;
  top: 7.5vw;
  left: 2vw;
}

#story-4-page-1-floatnote-1 .float-note__line {
  width: 5.5vw;
  height: 4.5vw;
  top: 11vw;
  left: 5vw;
}

#story-4-page-1-floatnote-2 {
  width: 11vw;
  top: 10vw;
  right: 4.5vw;
}

#story-4-page-1-floatnote-2 .float-note__line {
  width: 3vw;
  height: 4.5vw;
  top: 3.5vw;
  left: -3.5vw;
}

#story-4-page-1-sidenote-1 {
  margin-top: 1em;
}

#story-4-page-1-sidenote-2 {
  margin-top: 7em;
}

#story-4-page-1-sidenote-2 p {
  width: 106%;
}

#story-4-page-1-sidenote-3 {
  margin-top: 20em;
}

#story-4-page-1-sidenote-3 p {
  width: 109%;
  letter-spacing: -.01em;
}

#story-4-page-2-floatnote-1 {
  width: 22vw;
  bottom: 3.4em;
  left: 15px;
}

#story-4-page-2-floatnote-2-1 {
  width: 10vw;
  top: 3em;
  left: 40.5vw;
}

#story-4-page-2-floatnote-2-1 img {
  transform: rotate(-6deg);
}

#story-4-page-2-floatnote-2-1 span {
  width: 74%;
  top: 1.5em;
  left: 1.5em;
  transform: rotate(-5deg);
}

#story-4-page-2-floatnote-2-2 {
  width: 19vw;
  top: 2em;
  left: 28vw;
}

#story-4-page-2-floatnote-2-2 .float-note__img {
  transform: rotate(-5deg);
}

#story-4-page-3-textshape-1 {
  width: 17vw;
  height: 17vw;
  margin-top: -.5em;
  margin-left: -9.5em;
}

#story-4-page-3-sidenotemobile-1 .side-note-mobile__img {
  width: 70%;
  border-radius: 100%;
  overflow: hidden;
}

#story-4-page-3-sidenote-1 {
  margin-top: 0;
}

#story-4-page-3-sidenote-1 .side-note__img {
  width: 16vw;
  height: 16vw;
  border-radius: 100%;
  overflow: hidden;
}

#story-4-page-3-sidenote-2 {
  margin-top: 8em;
}

#story-4-page-4-floatnote-1 {
  width: 85%;
  text-align: center;
  bottom: 3.4em;
  left: 7.5%;
}

#story-4-page-4-textshape-1 {
  width: 10em;
  height: 5.5em;
  border-radius: 100%;
  margin-top: -.5em;
  margin-left: -2em;
}

#story-4-page-4-sidenote-1-1 {
  width: 16em;
  margin-top: 1.5em;
  margin-left: 16em;
}

#story-4-page-4-sidenote-1-1 img {
  transform: rotate(5deg);
}

#story-4-page-4-sidenote-1-1 span {
  width: 70%;
  top: 1.3em;
  left: 2.1em;
  transform: rotate(2deg);
}

#story-4-page-4-sidenote-1-2 {
  width: 28em;
  margin-top: -13em;
  margin-left: -5.2em;
}

#story-4-page-4-sidenote-1-2 .side-note__img {
  width: 100%;
  transform: scaleX(-1);
}

#story-4-page-4-sidenote-2 {
  margin-top: 16em;
}

#story-4-page-4-sidenote-3 {
  margin-top: 7em;
}

#story-4-page-5-sidenotemobile-1-1 {
  width: 37vw;
  max-width: initial;
  margin-top: 3em;
  margin-left: 48vw;
}

@media only screen and (min-width: 641px) {
  #story-4-page-5-sidenotemobile-1-1 {
    width: 23.5vw;
    margin-top: 3em;
    margin-left: 53vw;
  }
}

#story-4-page-5-sidenotemobile-1-1 img {
  transform: rotate(7deg);
}

#story-4-page-5-sidenotemobile-1-1 span {
  width: 70%;
  top: 1.3em;
  left: 2em;
  transform: rotate(11deg);
}

#story-4-page-5-sidenotemobile-1-2 {
  width: 68vw;
  max-width: initial;
  margin-top: -15em;
  margin-bottom: -1em;
  margin-left: -5vw;
}

@media only screen and (min-width: 641px) {
  #story-4-page-5-sidenotemobile-1-2 {
    width: 47vw;
    max-width: initial;
    margin-top: -19em;
    margin-bottom: -3em;
    margin-left: 15vw;
  }
}

#story-4-page-5-sidenotemobile-1-2 .side-note-mobile__img {
  width: 100%;
}

#story-4-page-5-sidenotemobile-1-2 img {
  transform: scaleX(-1)rotate(4deg);
}

#story-4-page-5-floatnote-1-1 {
  width: 11vw;
  bottom: 14em;
  left: 2vw;
}

#story-4-page-5-floatnote-1-1 img {
  transform: scaleX(-1)rotate(7deg);
}

#story-4-page-5-floatnote-1-1 span {
  width: 80%;
  top: 2em;
  left: .8em;
  transform: rotate(-9deg);
}

#story-4-page-5-floatnote-1-2 {
  width: 17vw;
  bottom: -5em;
  left: 8vw;
}

#story-4-page-5-floatnote-1-2 .float-note__img {
  transform: scaleX(-1)rotate(-5deg);
}

#story-4-page-5-sidenote-1 {
  margin-top: .5em;
}

#story-4-page-5-sidenote-1 p {
  width: 102%;
}

#story-4-page-5-sidenotemobile-final-1 {
  width: 42vw;
  max-width: initial;
  margin-top: 3em;
  margin-left: 3vw;
}

@media only screen and (min-width: 641px) {
  #story-4-page-5-sidenotemobile-final-1 {
    width: 26vw;
    margin-top: 3em;
    margin-left: 13vw;
  }
}

#story-4-page-5-sidenotemobile-final-1 img {
  transform: scaleX(-1)rotate(7deg);
}

#story-4-page-5-sidenotemobile-final-1 span {
  width: 75%;
  top: 2.2em;
  left: 1em;
  transform: rotate(-11deg);
}

#story-4-page-5-sidenotemobile-final-2 {
  width: 68vw;
  max-width: initial;
  margin-top: -15em;
  margin-bottom: -9em;
  margin-left: 23.5vw;
}

@media only screen and (min-width: 641px) {
  #story-4-page-5-sidenotemobile-final-2 {
    width: 47vw;
    max-width: initial;
    margin-top: -19em;
    margin-bottom: -8em;
    margin-left: 27vw;
  }
}

#story-4-page-5-sidenotemobile-final-2 .side-note-mobile__img {
  width: 100%;
}

#story-4-page-5-sidenotemobile-final-2 img {
  transform: scaleX(-1)rotate(-4deg);
}

#story-5-page-8-text-p-increase-width {
  width: 102%;
}

.force-adjust-mobile {
  width: 96%;
}

@media only screen and (min-width: 641px) {
  .force-adjust-mobile {
    width: initial;
  }
}

[data-story="roxane-gay"] [data-page-num="1"] .story__image-page, [data-story="roxane-gay"] [data-page-num="5"] .story__image-page, [data-story="roxane-gay"] [data-page-num="9"] .story__image-page {
  padding-top: 15px;
  padding-bottom: 15px;
}

@media only screen and (min-width: 1024px) {
  [data-story="roxane-gay"] [data-page-num="1"] .story__image-page, [data-story="roxane-gay"] [data-page-num="5"] .story__image-page, [data-story="roxane-gay"] [data-page-num="9"] .story__image-page {
    padding-top: 0;
    padding-bottom: 0;
  }
}

[data-story="roxane-gay"] [data-page-num="2"] .story__image-page {
  padding-bottom: 15px;
}

@media only screen and (min-width: 1024px) {
  [data-story="roxane-gay"] [data-page-num="2"] .story__image-page {
    padding-bottom: 0;
  }
}

[data-story="roxane-gay"] [data-page-num="2"] .story__image-page img {
  object-position: center 40%;
}

[data-story="roxane-gay"] [data-page-num="3"] .story__text-page:last-child {
  padding-top: 15px;
}

@media only screen and (min-width: 1024px) {
  [data-story="roxane-gay"] [data-page-num="3"] .story__text-page:last-child {
    padding-top: 75px;
  }
}

[data-story="roxane-gay"] [data-page-num="5"] .story__text-page, [data-story="roxane-gay"] [data-page-num="6"] .story__text-page {
  padding-top: 15px;
}

@media only screen and (min-width: 1024px) {
  [data-story="roxane-gay"] [data-page-num="5"] .story__text-page, [data-story="roxane-gay"] [data-page-num="6"] .story__text-page {
    padding-top: 75px;
  }
}

[data-story="roxane-gay"] [data-page-num="5"] .story__text-page .story__bg-image {
  margin-top: 15px;
}

[data-story="roxane-gay"] [data-page-num="7"] .story__image-page {
  padding-top: 15px;
}

@media only screen and (min-width: 1024px) {
  [data-story="roxane-gay"] [data-page-num="7"] .story__image-page {
    padding-top: 0;
  }
}

[data-story="roxane-gay"] [data-page-num="7"] .story__bg-image img {
  object-position: 85% center;
}

[data-story="roxane-gay"] .story__section--last-page .story__text-page {
  padding-bottom: 30px;
}

#story-5-page-1-sidenote-1 {
  width: 102%;
  margin-top: 30em;
}

#story-5-page-2-floatnote-1-1 {
  width: 10.5vw;
  top: 1em;
  right: 14.5vw;
}

#story-5-page-2-floatnote-1-1 img {
  transform: scaleX(-1)rotate(7deg);
}

#story-5-page-2-floatnote-1-1 span {
  width: 80%;
  top: 2em;
  left: .8em;
  transform: rotate(-9deg);
}

#story-5-page-2-floatnote-1-2 {
  width: 17vw;
  top: -9em;
  right: 0;
}

#story-5-page-2-floatnote-1-2 .float-note__img {
  transform: rotate(-33deg);
}

#story-5-page-3-sidenotemobile-1 .side-note-mobile__img {
  width: 70%;
  border-radius: 100%;
  overflow: hidden;
}

#story-5-page-2-sidenote-1 {
  margin-top: 2em;
}

#story-5-page-2-sidenote-2 {
  margin-top: 9em;
}

#story-5-page-3-textshape-1 {
  width: 17vw;
  height: 17vw;
  margin-top: 19em;
  margin-left: -9.5em;
}

#story-5-page-3-sidenote-1 {
  margin-top: 38em;
}

#story-5-page-3-sidenote-1 .side-note__img {
  width: 16vw;
  height: 16vw;
  border-radius: 100%;
  overflow: hidden;
}

#story-5-page-3-sidenote-2 {
  margin-top: 10em;
}

#story-5-page-3-sidenote-3 {
  margin-top: 15em;
}

#story-5-page-4-floatnote-1 {
  width: 80%;
  text-align: center;
  bottom: 3.4em;
  left: 10%;
}

#story-5-page-4-sidenote-1 {
  margin-top: 19em;
}

#story-5-page-4-sidenote-1 p {
  width: 106%;
}

#story-5-page-5-floatnote-1 {
  width: 82%;
  text-align: center;
  bottom: 3.4em;
  left: 9%;
}

#story-5-page-5-textshape-1 {
  width: 10vw;
  height: 7vw;
  shape-outside: polygon(49% 56%, 50% 100%, 100% 90%);
  margin-top: 16em;
  margin-left: -5em;
}

#story-5-page-5-floatnote-1-1 {
  width: 8.5vw;
  top: 49.5em;
  left: 5px;
}

#story-5-page-5-floatnote-1-1 img {
  transform: scaleX(-1)rotate(-10deg);
}

#story-5-page-5-floatnote-1-1 span {
  width: 80%;
  top: 1.2em;
  left: .8em;
  transform: rotate(11deg);
}

#story-5-page-5-floatnote-1-2 {
  width: 18vw;
  top: 48.5em;
  left: .5vw;
}

#story-5-page-5-floatnote-1-2 .float-note__img {
  transform: scaleX(-1)rotate(-11deg);
}

#story-5-page-6-sidenote-1, #story-5-page-6-sidenote-2 {
  margin-top: 9em;
}

#story-5-page-6-sidenote-3 {
  margin-top: 27em;
}

#story-5-page-7-floatnote-1 {
  width: 45%;
  text-align: center;
  bottom: 3.4em;
  left: 2.5%;
}

#story-5-page-7-floatnote-2-1 {
  width: 14vw;
  top: 25vw;
  left: 18vw;
}

#story-5-page-7-floatnote-2-1 img {
  transform: scale(-1)rotate(5deg);
}

#story-5-page-7-floatnote-2-1 span {
  width: 80%;
  top: 4em;
  left: .2em;
  transform: rotate(4deg);
}

#story-5-page-7-floatnote-2-2 {
  width: 23vw;
  top: 13vw;
  left: 19vw;
}

#story-5-page-7-floatnote-2-2 .float-note__img {
  transform: scaleX(-1)rotate(11deg);
}

#story-5-page-8-sidenote-1 {
  margin-top: 49em;
}

#story-5-page-8-sidenote-2 {
  width: 103%;
  letter-spacing: -.02em;
  margin-top: 15em;
}

#story-5-page-8-sidenote-3 {
  width: 106%;
  margin-top: 15em;
}

#story-5-page-9-sidenotemobile-1-1 {
  width: 42vw;
  max-width: initial;
  margin-top: 3em;
  margin-left: 6vw;
}

#story-5-page-9-sidenotemobile-1-1 img {
  transform: scaleX(-1)rotate(-15deg);
}

#story-5-page-9-sidenotemobile-1-1 span {
  width: 70%;
  top: 2.2em;
  left: 1.7em;
  transform: rotate(15deg);
}

@media only screen and (min-width: 641px) {
  #story-5-page-9-sidenotemobile-1-1 {
    width: 27vw;
    margin-top: 3em;
    margin-left: 20vw;
  }

  #story-5-page-9-sidenotemobile-1-1 span {
    top: 2.5em;
  }
}

#story-5-page-9-sidenotemobile-1-2 {
  width: 65vw;
  max-width: initial;
  margin-top: -14em;
  margin-bottom: -4em;
  margin-left: 28vw;
}

@media only screen and (min-width: 641px) {
  #story-5-page-9-sidenotemobile-1-2 {
    width: 45vw;
    max-width: initial;
    margin-top: -14em;
    margin-bottom: -6em;
    margin-left: 32vw;
  }
}

#story-5-page-9-sidenotemobile-1-2 .side-note-mobile__img {
  width: 100%;
}

#story-5-page-9-floatnote-1-1 {
  width: 10.5vw;
  top: 10em;
  left: 0;
}

#story-5-page-9-floatnote-1-1 img {
  transform: scaleX(-1)rotate(-14deg);
}

#story-5-page-9-floatnote-1-1 span {
  width: 80%;
  top: 2em;
  left: 1em;
  transform: rotate(13deg);
}

#story-5-page-9-floatnote-1-2 {
  width: 16vw;
  top: 16em;
  left: 2vw;
}

#story-5-page-9-floatnote-1-2 .float-note__img {
  transform: rotate(11deg);
}

#story-5-page-9-sidenotemobile-final {
  width: 68vw;
  margin-top: -7vw;
  margin-bottom: -17vw;
  left: -1vw;
}

@media only screen and (min-width: 641px) {
  #story-5-page-9-sidenotemobile-final {
    width: 50vw;
    margin-top: -7vw;
    margin-bottom: -10vw;
    left: 3vw;
  }
}

#story-5-page-9-sidenotemobile-final .side-note-mobile__img {
  width: 100%;
}

#story-5-page-9-sidenotemobile-final .side-note-mobile__img img {
  transform: rotate(-10deg);
}

[data-story="john-paul-brammer"] [data-page-num="2"] .story__image-page, [data-story="john-paul-brammer"] [data-page-num="3"] .story__image-page {
  padding-top: 15px;
}

@media only screen and (min-width: 1024px) {
  [data-story="john-paul-brammer"] [data-page-num="2"] .story__image-page, [data-story="john-paul-brammer"] [data-page-num="3"] .story__image-page {
    padding-top: 0;
  }
}

[data-story="john-paul-brammer"] [data-page-num="3"] .story__bg-image:before {
  opacity: .7;
}

#story-6-page-1-floatnote-1 {
  width: 16vw;
  top: 9vw;
  left: 6vw;
}

#story-6-page-1-floatnote-1 .float-note__line {
  width: 5.5vw;
  height: 7vw;
  top: 9vw;
  left: 5vw;
}

#story-6-page-1-floatnote-2 {
  width: 16.8vw;
  top: 6vw;
  right: 2vw;
}

#story-6-page-1-floatnote-2 .float-note__line {
  width: 5vw;
  height: 6vw;
  top: 8vw;
  left: -1.5vw;
}

#story-6-page-1-floatnote-3 {
  width: 19vw;
  bottom: 3.4em;
  left: 15px;
}

#story-6-page-1-floatnote-3 p {
  width: 115%;
}

#story-6-page-1-sidenote-1 {
  margin-top: 10em;
}

#story-6-page-1-sidenote-2 {
  margin-top: 5em;
}

#story-6-page-1-sidenote-2 p {
  width: 106%;
}

#story-6-page-1-sidenote-3 {
  margin-top: 3em;
}

#story-6-page-2-sidenote-1 {
  margin-top: 8em;
}

#story-6-page-2-sidenote-2 {
  margin-top: 4em;
}

#story-6-page-2-floatnote-1-1 {
  width: 12vw;
  top: 4em;
  left: 67%;
}

#story-6-page-2-floatnote-1-1 img {
  transform: rotate(7deg);
}

#story-6-page-2-floatnote-1-1 span {
  width: 70%;
  top: 2em;
  left: 2.3em;
  transform: rotate(6deg);
}

#story-6-page-2-floatnote-1-2 {
  width: 29%;
  top: 4em;
  left: 45%;
}

#story-6-page-2-floatnote-2 {
  width: 30%;
  bottom: 3.4em;
  left: 3em;
}

#story-6-page-3-sidenotemobile-1-1 {
  width: 42vw;
  max-width: initial;
  margin-top: 0;
  margin-left: 40vw;
}

@media only screen and (min-width: 641px) {
  #story-6-page-3-sidenotemobile-1-1 {
    width: 27vw;
    margin-top: 3em;
    margin-left: 49vw;
  }
}

#story-6-page-3-sidenotemobile-1-1 .illustration-img {
  transform: rotate(-7deg);
}

#story-6-page-3-sidenotemobile-1-1 span {
  width: 80%;
  top: 1.8em;
  left: 1.7em;
  transform: rotate(2deg);
}

#story-6-page-3-sidenotemobile-1-2 {
  width: 65vw;
  max-width: initial;
  margin-top: -15em;
  margin-bottom: -6em;
  margin-left: -7vw;
}

@media only screen and (min-width: 641px) {
  #story-6-page-3-sidenotemobile-1-2 {
    width: 48vw;
    max-width: initial;
    margin-top: -20em;
    margin-left: 15vw;
  }
}

#story-6-page-3-sidenotemobile-1-2 .side-note-mobile__img {
  width: 100%;
  transform: rotate(-8deg);
}

#story-6-page-3-floatnote-1-1 {
  width: 11.8vw;
  top: 14em;
  right: 0;
}

#story-6-page-3-floatnote-1-1 img {
  transform: rotate(-17deg);
}

#story-6-page-3-floatnote-1-1 span {
  width: 75%;
  top: 1.8em;
  left: 1.5em;
  transform: rotate(-16deg);
}

#story-6-page-3-floatnote-1-2 {
  width: 35%;
  top: 15em;
  right: 9%;
}

#story-6-page-3-floatnote-1-2 .float-note__img {
  transform: rotate(-8deg);
}

#story-6-page-3-floatnote-2 {
  width: 80%;
  text-align: center;
  bottom: 3.4em;
  left: 10%;
}

#story-6-page-3-sidenote-1 {
  margin-top: 18em;
}

#story-6-page-3-sidenotemobile-final-1 {
  width: 42vw;
  max-width: initial;
  margin-top: 0;
  margin-left: 44vw;
}

@media only screen and (min-width: 641px) {
  #story-6-page-3-sidenotemobile-final-1 {
    width: 27vw;
    margin-top: 3em;
    margin-left: 49vw;
  }
}

#story-6-page-3-sidenotemobile-final-1 .illustration-img {
  transform: rotate(-7deg);
}

#story-6-page-3-sidenotemobile-final-1 span {
  width: 80%;
  top: 1.8em;
  left: 1.7em;
  transform: rotate(2deg);
}

#story-6-page-3-sidenotemobile-final-2 {
  width: 65vw;
  max-width: initial;
  margin-top: -15em;
  margin-bottom: -7em;
  margin-left: 2vw;
}

@media only screen and (min-width: 641px) {
  #story-6-page-3-sidenotemobile-final-2 {
    width: 48vw;
    max-width: initial;
    margin-top: -20em;
    margin-left: 15vw;
  }
}

#story-6-page-3-sidenotemobile-final-2 .side-note-mobile__img {
  width: 100%;
  transform: rotate(-8deg);
}

.cookie-policy-modal {
  z-index: 10;
  display: none;
  position: fixed;
  inset: 0;
}

.cookie-policy-modal--active {
  display: block;
}

.cookie-policy-modal [data-open-cookie-policy], .cookie-policy-modal a {
  color: #000;
  text-underline-offset: .2em;
  -webkit-text-decoration: underline #bca6d0;
  text-decoration: underline #bca6d0;
  text-decoration-thickness: 1px;
}

.cookie-policy-modal [data-open-cookie-policy]:hover, .cookie-policy-modal a:hover {
  color: #a3009a;
}

.cookie-policy-modal p + h6 {
  margin-top: 3em;
}

.cookie-policy-modal table tr:not(:last-child) {
  border-bottom: 1px solid #bca6d0;
}

.cookie-policy-modal table td {
  padding-top: .5em;
  padding-bottom: .5em;
}

.cookie-policy-modal table td:nth-child(2) p {
  border-left: 1px solid #bca6d0;
  padding-top: .5em;
  padding-bottom: .5em;
  padding-left: 1em;
}

.cookie-policy-modal__container {
  width: calc(100% - 20px);
  max-width: 1200px;
  height: calc(100% + -2.5em - 30px);
  background-color: #d7cae3;
  border: 1px solid #bca6d0;
  border-radius: 1em;
  position: absolute;
  inset: calc(2.5em + 20px) 10px 10px;
  overflow-x: hidden;
  overflow-y: auto;
}

@media only screen and (min-width: 641px) {
  .cookie-policy-modal__container {
    width: 80%;
    height: calc(100% - 4em);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.cookie-policy-modal__content {
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  padding: 3em 15px;
}

@media only screen and (min-width: 641px) {
  .cookie-policy-modal__content {
    padding: 5em 0;
  }
}

.cookie-policy-modal__title {
  text-align: center;
  margin-bottom: 3.4em;
}

@media only screen and (min-width: 1024px) {
  .cookie-policy-modal__title {
    margin-bottom: 4em;
  }
}

.cookie-policy-modal__close {
  width: 2.6em;
  height: 2.4em;
  z-index: 300;
  margin-left: auto;
  margin-right: 15px;
  position: sticky;
  top: 15px;
}

.cookie-policy-modal__close, .cookie-policy-modal__close * {
  color: #fff;
  font-family: Suisse Intl Mono;
  font-size: calc((12.5576 * var(--base-font-size-vw) / var(--base-font-size)) * 1vw);
  letter-spacing: .03em;
  line-height: 1;
}

.cookie-policy-modal__close:after, .cookie-policy-modal__close:before {
  content: "";
  height: 1px;
  transform-origin: center;
  background-color: #000;
  transition: transform .2s ease-in-out, top .2s ease-in-out;
  position: absolute;
  top: 15px;
  left: 0;
  right: 0;
  transform: rotate(-45deg);
}

.cookie-policy-modal__close:after {
  transform: rotate(45deg);
}

.cookie-policy-modal__close:hover {
  cursor: pointer;
}

.cookie-policy-modal__close:hover:after, .cookie-policy-modal__close:hover:before {
  background-color: #a3009a;
}

@media only screen and (min-width: 1024px) {
  .info .story__section {
    min-height: 100vh;
  }
}

.info__content {
  height: 100%;
  min-height: 100vh;
  text-align: center;
  flex-direction: column;
  padding: calc(5em + 10px) 15px 2.5em;
  display: flex;
}

@media only screen and (min-width: 1024px) {
  .info__content {
    min-height: 100vh;
    padding: 2.5em 2em 2em;
  }
}

.info__hinge-text {
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  display: flex;
}

.info__hinge-text, .info__hinge-text * {
  line-height: 1.3;
}

.info__hinge-text a {
  color: #49308c;
}

.info__hinge-button {
  width: 9em;
  height: 2.5em;
  margin-top: 1.5em;
  text-decoration: none;
  position: relative;
}

.info__hinge-button:after {
  content: "";
  z-index: 1;
  background-color: #fff;
  border-radius: 100%;
  position: absolute;
  inset: 0;
}

.info__hinge-button-text {
  width: 100%;
  height: 100%;
  z-index: 2;
  border: 1px solid #000;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

.info__hinge-button-text span {
  text-transform: uppercase;
  color: #000;
  margin-bottom: -.2em;
}

.info__hinge-button:hover:after {
  background-color: #bca6d0;
}

.info__hinge-button:hover span {
  color: #556148;
  border-color: #556148;
}

.info__hinge-logo {
  width: 4.5em;
  margin-bottom: 1em;
  display: inline-block;
}

.info__hinge-logo img {
  width: 100%;
}

.info__footer-info, .info__footer-info * {
  color: #000;
  font-family: Suisse Intl Mono;
  line-height: 1.15;
  font-size: calc((10.4385 * var(--base-font-size-vw) / var(--base-font-size)) * 1vw);
}

@media only screen and (min-width: 641px) {
  .info__footer-info, .info__footer-info * {
    font-size: calc((10.9879 * var(--base-font-size-vw) / var(--base-font-size)) * 1vw);
  }
}

.info__footer-info a {
  text-decoration-line: none;
}

.info__footer-info a:hover {
  color: #a3009a;
}

.info__footer-info p + p {
  margin-top: 0;
}

.cookie-policy-link {
  width: auto;
  font-family: Suisse Intl Mono;
  line-height: 1.15;
  font-size: calc((10.4385 * var(--base-font-size-vw) / var(--base-font-size)) * 1vw);
  margin-top: 4em;
  margin-left: auto;
  margin-right: auto;
  display: inline-block;
  color: #49308c !important;
}

.info__footer-info .cookie-policy-link * {
  color: inherit;
}

@media only screen and (min-width: 1024px) {
  .cookie-policy-link {
    font-size: calc((11.5485 * var(--base-font-size-vw) / var(--base-font-size)) * 1vw);
    margin-top: 0;
    position: absolute;
    bottom: 1em;
    left: 15px;
  }
}

.cookie-policy-link > span {
  margin-right: 1em;
}

.cookie-policy-link > span:hover {
  cursor: pointer;
  color: #a3009a !important;
}

/*# sourceMappingURL=index.96e71047.css.map */
