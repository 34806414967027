.simplebar-scrollbar:before {
  border-radius: 0;
  background: white;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
}

.simplebar-track.simplebar-horizontal {
  height: 3px;
}
.simplebar-track.simplebar-vertical {
  width: 3px;
}

.simplebar-scrollbar.simplebar-visible:before {
  opacity: 0.3;
}

.simplebar-content-wrapper {
  height: 100% !important;
}
