:root {
  --window-width: 100vw;
  --window-height: 100vh;
}

button {
  background: transparent;
  outline: 0;
  border: 0;
}
