[data-story="brontez-purnell"] {

  [data-page-num="1"] {
    .story__bg-image {
      padding-top: $cover-pad;
      padding-bottom: $cover-pad;

      @media #{$breakpoint-md-up} {
        padding-top: 0;
        padding-bottom: 0;
      }
    }
  }

  [data-page-num="2"] {
    .story__bg-image img {
      object-position: center 25%;
    }
    .story__image-page {
      padding-top: $cover-pad;

      @media #{$breakpoint-md-up} {
        padding-top: 0;
      }
    }
  }

  [data-page-num="3"] {
    .story__bg img {
      object-position: center top;
    }
  }

  [data-page-num="4"] {
    .story__image-page {
      padding-top: $cover-pad;
      padding-bottom: $cover-pad;

      @media #{$breakpoint-md-up} {
        padding-top: 0;
        padding-bottom: 0;
      }

      .texture-overlay {
        opacity: 0.3;
      }
    }
  }

  [data-page-num="5"] {
    .story__text-page {
      padding-top: $cover-pad;

      @media #{$breakpoint-md-up} {
        padding-top: $cover-pad*5;
      }
    }

    .story__image-page {
      overflow: hidden;
    }
  }
}

//PAGE 1
#story-3-page-1-sidenote-1 {
  margin-top: 16em;
}

#story-3-page-1-sidenote-2 {
  margin-top: 3em;
}

//PAGE 2
#story-3-page-2-sidenote-1 {
  margin-top: 0.5em;
  p {
    width: 106%;
  }
}

#story-3-page-2-sidenote-2 {
  margin-top: 3em;

  p {
    width: 104%;
    letter-spacing: -0.01em;
  }
}

#story-3-page-2-sidenote-3 {
  margin-top: 10em;
}

#story-3-page-2-floatnote-1-1 {
  width: 13.5vw;
  top: 11vw;
  left: 43%;

  img {
    transform: scale(-1) rotate(-17deg);
  }

  span {
    width: 57%;
    top: 4.5em;
    left: 2.5em;
    transform: rotate(-5deg);
  }
}

#story-3-page-2-floatnote-1-2 {
  width: 32%;
  top: -6em;
  left: 40%;

  .float-note__img {
    transform: scaleX(-1) rotate(-60deg);
  }
}


//PAGE 3
#story-3-page-3-sidenote-1 {
  margin-top: 12em;
}

#story-3-page-3-sidenote-2 {
  margin-top: 4em;
}

//PAGE 4
#story-3-page-4-sidenotemobile-1-1 {
  width: 37vw;
  max-width: initial;
  margin-top: 3em;
  margin-left: 17vw;

  @media #{$breakpoint-sm-up} {
    width: 22vw;
    margin-top: 4em;
    margin-left: 30vw;
  }

  .illustration-img {
    transform: rotate(-35deg);
  }

  span {
    width: 55%;
    top: 1.5em;
    left: 3em;
  }
}
#story-3-page-4-sidenotemobile-1-2 {
  width: 78vw;
  max-width: initial;
  margin-top: -13em;
  margin-left: 8vw;
  margin-bottom: -8em;

  @media #{$breakpoint-sm-up} {
    width: 48vw;
    max-width: initial;
    margin-top: -14em;
    margin-bottom: -10em;
    margin-left: 25vw;
  }

  .side-note-mobile__img {
    width: 100%;
    transform: scaleX(-1) rotate(-2deg);
  }
}

#story-3-page-4-floatnote-1-1 {
  width: 9.5vw;
  bottom: 13vw;
  right: 12.5%;

  img {
    transform: rotate(-35deg);
  }

  span {
    width: 55%;
    top: 1.3em;
    left: 2.5em;
    transform: rotate(-32deg);
  }
}

#story-3-page-4-floatnote-1-2 {
  width: 35%;
  bottom: -5em;
  right: 0;

  .float-note__img {
    transform: scaleX(-1) rotate(-10deg);
  }
}

#story-3-page-4-sidenote-1 {
  margin-top: .5em;
  p {
    width: 104%;
    letter-spacing: -0.01em;
  }
}

#story-3-page-4-sidenote-2 {
  margin-top: 4em;

  p {
    width: 104%;
    letter-spacing: -0.01em;
  }
}

#story-3-page-4-sidenote-3 {
  margin-top: 3em;
}




//PAGE 5
#story-3-page-5-sidenote-1-1 {
  width: 9vw;
  top: 25vw;
  left: 1vw;

  img {
    transform: scaleX(-1) rotate(-7deg);
  }

  span {
    width: 75%;
    top: 1.5em;
    left: 1.1em;
    transform: rotate(2deg);
  }
}

#story-3-page-5-sidenote-1-2 {
  width: 17vw;
  top: 16vw;
  left: 5.5vw;

  .side-note__img {
    transform: rotate(-10deg);
  }
}

#story-3-page-5-sidenotemobile-final-1 {
  width: 33vw;
  left: -22vw;
  margin-top: 5vw;

  @media #{$breakpoint-sm-up} {
    width: 22vw;
    margin-top: 8vw;
    left: -15vw;
  }

  img {
    transform: scaleX(-1) rotate(-7deg);
  }

  span {
    width: 75%;
    top: 1.5em;
    left: 1.1em;
    transform: rotate(2deg);
  }
}

#story-3-page-5-sidenotemobile-final-2 {
  width: 64vw;
  left: 13vw;
  margin-top: -44vw;
  margin-bottom: -10vw;

  @media #{$breakpoint-sm-up} {
    width: 43vw;
    margin-top: -29vw;
    margin-bottom: -7vw;
    left: 9vw;
  }

  .side-note-mobile__img {
    width: 100%;

    img {
      transform: rotate(-10deg);
    }
  }
}

#story-3-page-5-floatnote-2 {
  bottom: 3.4em;
  width: 75%;
  left: 12.5%;
  text-align: center;
}
