[data-story="oisin-mckenna"] {
  [data-page-num="2"] {
    .story__image-page + .story__text-page {
      padding-top: $container-pad;

      @media #{$breakpoint-md-up} {
        padding-top: $cover-pad*5;
      }
    }
    .story__bg-image img {
      object-position: center bottom;
    }
  }

  [data-page-num="3"] {
    .story__text-page + .story__image-page {
      padding-top: $container-pad;
      padding-bottom: 0;

      @media #{$breakpoint-md-up} {
        padding-top: 0;
      }
    }
  }

  [data-page-num="4"] {
    .story__bg img {
      object-position: center bottom;
    }
  }
}

//PAGE 1
#story-2-page-1-floatnote-1 {
  width: 19em;
  top: 27vw;
  left: 2.5vw;


  .float-note__line {
    width: 2.5vw;
    height: 3.5vw;
    top: -3vw;
    left: 15.5vw;
  }
}

#story-2-page-1-floatnote-2 {
  width: 14em;
  top: 27vw;
  left: 29vw;

  .float-note__line {
    width: 1vw;
    height: 4.5vw;
    top: -2vw;
    left: -1.5vw;
  }
}

#story-2-page-1-floatnote-3-1 {
  width: 10vw;
  bottom: 4.5em;
  left: 20vw;

  img {
    transform: scaleY(-1);
  }

  .illustration-img {
    transform: rotate(-5deg);
  }

  span {
    width: 82%;
    top: 3em;
    left: 1.2em;
  }
}

#story-2-page-1-floatnote-3-2 {
  width: 17vw;
  bottom: -4.5em;
  left: 8vw;
}

#story-2-page-1-sidenote-1 {
  margin-top: 13.5em;
}

#story-2-page-1-sidenote-2 {
  margin-top: 13em;
}

#story-2-page-1-sidenote-3 {
  margin-top: 2em;
}

//PAGE 2
#story-2-page-2-floatnote-1-1 {
  width: 14vw;
  bottom: 11.5em;
  left: 32vw;

  .illustration-img {
    transform: rotate(15deg);
  }

  span {
    width: 64%;
    top: 2.2em;
    left: 3.9em;
    transform: rotate(7deg);
  }
}
#story-2-page-2-floatnote-1-2 {
  width: 22vw;
  bottom: 2em;
  left: 17vw;

  img {
    transform: scaleX(-1);
  }
}

#story-2-page-2-sidenote-1 {
  margin-top: 8em;
  width: 108%;
  letter-spacing: -0.02em;
}

#story-2-page-2-sidenote-2 {
  margin-top: 6.5em;
  width: 108%;
  letter-spacing: -0.02em;

  .side-note__img {
    width: 15.5vw;
    height: 15.5vw;
    border-radius: 100%;
    overflow: hidden;
  }
}

#story-2-page-2-sidenotemobile-2 {
  .side-note-mobile__img {
    width: 70%;
    border-radius: 100%;
    overflow: hidden;
  }
}

#story-2-page-2-textshape-1 {
  width: 17vw;
  height: 17vw;
  margin-left: -9.5em;
  margin-top: 16.7em;
}


//PAGE 3
#story-2-page-3-sidenote-1 {
  margin-top: 5em;
}

#story-2-page-3-sidenote-2 {
  margin-top: 13em;
  width: 110%;
  letter-spacing: -0.01em;
}

#story-2-page-3-floatnote-1 {
  width: 80%;
  text-align: center;
  margin-left: 10%;
  bottom: 5em;
}


//PAGE 4
#story-2-page-4-floatnote-1 {
  left: $cover-pad;
  top: $cover-pad*5;
  width: 28vw;
  z-index: 3;
}

#story-2-page-4-floatnote-2-1 {
  width: 11vw;
  top: 7em;
  left: 13.5vw;

  .illustration-img {
    transform: rotate(5deg);
  }

  span {
    width: 75%;
    top: 2em;
    left: 1.5em;
  }
}

#story-2-page-4-floatnote-2-2 {
  width: 16vw;
  top: 5em;
  left: 27vw;

  img {
    transform: rotate(-15deg);
  }
}


//PAGE 5
#story-2-page-5-sidenotemobile-1-1 {
  width: 38vw;
  max-width: initial;
  margin-top: 7em;
  margin-left: 44vw;

  @media #{$breakpoint-sm-up} {
    width: 23.5vw;
    margin-top: 11em;
    margin-left: 45vw;
  }

  img {
    transform: scaleY(-1) rotate(7deg);
  }

  span {
    width: 80%;
    top: 3em;
    left: 1.7em;
    transform: rotate(-3deg);
  }
}
#story-2-page-5-sidenotemobile-1-2 {
  width: 65vw;
  max-width: initial;
  margin-top: -24em;
  margin-bottom: -3em;
  margin-left: -8vw;

  @media #{$breakpoint-sm-up} {
    width: 48vw;
    max-width: initial;
    margin-top: -30em;
    margin-left: 7vw;
  }

  .side-note-mobile__img {
    width: 100%;
  }

  img {
    transform: rotate(10deg);
  }
}

#story-2-page-5-sidenote-1 {
  margin-top: 2em;
}

#story-2-page-5-sidenote-2 {
  margin-top: 15em;
}

#story-2-page-5-floatnote-1-1 {
  width: 11vw;
  top: 9em;
  left: 14.5vw;

  .illustration-img {
    transform: rotate(-15deg);
  }

  img {
    transform: scaleY(-1);
  }

  span {
    width: 56%;
    top: 3.5em;
    left: 3em;
  }
}

#story-2-page-5-floatnote-1-2 {
  width: 17vw;
  top: -3vw;
  left: 0vw;

  img {
    transform: rotate(15deg);
  }
}

#story-2-page-5-sidenotemobile-final {
  width: 65vw;
  max-width: initial;
  margin-top: -4em;
  margin-bottom: -6em;
  margin-left: 16vw;

  @media #{$breakpoint-sm-up} {
    width: 48vw;
    max-width: initial;
    margin-top: -5em;
    margin-bottom: -5em;
    margin-left: 28vw;
  }

  .side-note-mobile__img {
    width: 100%;
  }
}
