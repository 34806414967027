[data-story="john-paul-brammer"] {
  [data-page-num="2"],
  [data-page-num="3"] {
    .story__image-page {
      padding-top: $cover-pad;

      @media #{$breakpoint-md-up} {
        padding-top: 0;
      }
    }
  }

  [data-page-num="3"] {
    .story__bg-image:before {
      opacity: 0.7;
    }
  }
}

//PAGE 1
#story-6-page-1-floatnote-1 {
  width: 16vw;
  top: 9vw;
  left: 6vw;

  .float-note__line {
    width: 5.5vw;
    height: 7vw;
    top: 9vw;
    left: 5vw;
  }
}
#story-6-page-1-floatnote-2 {
  width: 16.8vw;
  top: 6vw;
  right: 2vw;

  .float-note__line {
    width: 5vw;
    height: 6vw;
    top: 8vw;
    left: -1.5vw;
  }
}
#story-6-page-1-floatnote-3 {
  width: 19vw;
  bottom: 3.4em;
  left: $cover-pad;

  p {
    width: 115%;
  }
}

#story-6-page-1-sidenote-1 {
  margin-top: 10em;
}

#story-6-page-1-sidenote-2 {
  margin-top: 5em;

  p {
    width: 106%;
  }
}

#story-6-page-1-sidenote-3 {
  margin-top: 3em;
}


//PAGE 2
#story-6-page-2-sidenote-1 {
  margin-top: 8em;
}

#story-6-page-2-sidenote-2 {
  margin-top: 4em;
}

#story-6-page-2-floatnote-1-1 {
  width: 12vw;
  top: 4em;
  left: 67%;

  img {
    transform: rotate(7deg);
  }

  span {
    width: 70%;
    top: 2em;
    left: 2.3em;
    transform: rotate(6deg);
  }
}

#story-6-page-2-floatnote-1-2 {
  width: 29%;
  top: 4em;
  left: 45%;
}

#story-6-page-2-floatnote-2 {
  width: 30%;
  left: 3em;
  bottom: 3.4em;
}


//PAGE 3
#story-6-page-3-sidenotemobile-1-1 {
  width: 42vw;
  max-width: initial;
  margin-top: 0em;
  margin-left: 40vw;

  @media #{$breakpoint-sm-up} {
    width: 27vw;
    margin-top: 3em;
    margin-left: 49vw;
  }

  .illustration-img {
    transform: rotate(-7deg);
  }

  span {
    width: 80%;
    top: 1.8em;
    left: 1.7em;
    transform: rotate(2deg);
  }
}
#story-6-page-3-sidenotemobile-1-2 {
  width: 65vw;
  max-width: initial;
  margin-top: -15em;
  margin-bottom: -6em;
  margin-left: -7vw;

  @media #{$breakpoint-sm-up} {
    width: 48vw;
    max-width: initial;
    margin-top: -20em;
    margin-left: 15vw;
  }

  .side-note-mobile__img {
    width: 100%;
    transform: rotate(-8deg);
  }
}
#story-6-page-3-floatnote-1-1 {
  width: 11.8vw;
  top: 14em;
  right: 0;

  img {
    transform: rotate(-17deg);
  }

  span {
    width: 75%;
    top: 1.8em;
    left: 1.5em;
    transform: rotate(-16deg);
  }
}

#story-6-page-3-floatnote-1-2 {
  width: 35%;
  top: 15em;
  right: 9%;

  .float-note__img {
    transform: rotate(-8deg);
  }
}

#story-6-page-3-floatnote-2 {
  bottom: 3.4em;
  width: 80%;
  left: 10%;
  text-align: center;
}

#story-6-page-3-sidenote-1 {
  margin-top: 18em;
}


#story-6-page-3-sidenotemobile-final-1 {
  width: 42vw;
  max-width: initial;
  margin-top: 0em;
  margin-left: 44vw;

  @media #{$breakpoint-sm-up} {
    width: 27vw;
    margin-top: 3em;
    margin-left: 49vw;
  }

  .illustration-img {
    transform: rotate(-7deg);
  }

  span {
    width: 80%;
    top: 1.8em;
    left: 1.7em;
    transform: rotate(2deg);
  }
}
#story-6-page-3-sidenotemobile-final-2 {
  width: 65vw;
  max-width: initial;
  margin-top: -15em;
  margin-bottom: -7em;
  margin-left: 2vw;

  @media #{$breakpoint-sm-up} {
    width: 48vw;
    max-width: initial;
    margin-top: -20em;
    margin-left: 15vw;
  }

  .side-note-mobile__img {
    width: 100%;
    transform: rotate(-8deg);
  }
}
