.cookie-policy-modal {
  position: fixed;
  z-index: 10;
  inset: 0;
  display: none;

  &--active {
    display: block;
  }

  [data-open-cookie-policy],
  a {
    color: black;
    text-decoration: underline;
    text-underline-offset: 0.2em;
    text-decoration-thickness: 1px;
    text-decoration-color: $color-4;

    &:hover {
      color: $color-magenta;
    }
  }

  p + h6 {
    margin-top: 3em;
  }

  table {
    tr:not(:last-child) {
      border-bottom: 1px solid $color-4;
    }

    td {
      padding-top: 0.5em;
      padding-bottom: 0.5em;

      &:nth-child(2) p {
        padding-top: 0.5em;
        padding-bottom: 0.5em;
        padding-left: 1em;
        border-left: 1px solid $color-4;
      }
    }
  }


  &__container {
    position: absolute;
    width: calc(100% - 10px*2);
    max-width: 1200px;
    height: calc(100% - (2.5em + #{$cover-pad}/3*2) - 10px*2);
    background-color: $color-slideshow-bg;
    border: 1px solid $color-4;
    border-radius: 1em;
    overflow-x: hidden;
    overflow-y: auto;
    inset: 10px;
    top: calc((2.5em + #{$cover-pad}/3*2) + 10px);

    @media #{$breakpoint-sm-up} {
      width: 80%;
      height: calc(100% - 2em*2);
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &__content {
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    padding: 3em $cover-pad;

    @media #{$breakpoint-sm-up} {
      padding: 5em 0;
    }
  }

  &__title {
    text-align: center;
    margin-bottom: 3.4em;

    @media #{$breakpoint-md-up} {
      margin-bottom: 4em;
    }
  }

  &__close {
    position: sticky;
    width: 2.6em;
    height: 2.4em;
    top: $cover-pad;
    margin-right: $cover-pad;
    margin-left: auto;
    z-index: 300;

    &, & * {
      color: white;
      font-family: "Suisse Intl Mono";
      font-size: font-size($menu-icon-font-size);
      line-height: 1;
      letter-spacing: 0.03em;
    }

    &:after,
    &:before {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      height: 1px;
      top: $cover-pad;
      transform: rotate(-45deg);
      background-color: black;
      transform-origin: center;
      transition: transform 0.2s ease-in-out, top 0.2s ease-in-out;
    }

    &:after {
      transform: rotate(45deg);
    }

    &:hover {
      cursor: pointer;
      &:after,
      &:before {
        background-color: $color-magenta;
      }
    }
  }
}

.info {

  .story__section {
    @media #{$breakpoint-md-up} {
      min-height: 100vh;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    height: 100%;
    min-height: 100vh;
    text-align: center;
    padding: 2.5em $cover-pad;
    padding-top: calc(2.5em + (2.5em + #{$cover-pad}/3*2));

    @media #{$breakpoint-md-up} {
      min-height: 100vh;
      padding: 2em;
      padding-top: 2.5em;
    }
  }

  &__hinge-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @extend .hinge-text-style;
    flex-grow: 1;


    &, & * {
      line-height: 1.3;
    }

    a {
      color: $color-purple;
    }
  }

  &__hinge-button {
    position: relative;
    margin-top: 1.5em;
    width: 9em;
    height: 2.5em;
    text-decoration: none;

    &:after {
      content: '';
      position: absolute;
      inset: 0;
      z-index: 1;
      background-color: white;
      border-radius: 100%;
    }

    &-text {
      display: flex;
      position: relative;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      position: relative;
      z-index: 2;
      border-radius: 100%;
      border: 1px solid black;

      span {
        text-transform: uppercase;
        color: black;
        margin-bottom: -0.2em;
      }
    }

    &:hover {

      &:after {
        background-color: $color-4;
      }

      span {
        border-color: #556148;
        color: #556148;
      }
    }
  }


  &__hinge-logo {
    display: inline-block;
    margin-bottom: 1em;
    width: 4.5em;

    img {
      width: 100%;;
    }
  }

  &__footer-info {
    &, & * {
      color: black;
      font-family: "Suisse Intl Mono";
      line-height: 1.15;
      font-size: font-size($floatnote-font-size*0.95);

      @media #{$breakpoint-sm-up} {
        font-size: font-size($floatnote-font-size);
      }
    }

    a {
      text-decoration-line: none;

      &:hover {
        color: $color-magenta;
      }
    }

    p + p {
      margin-top: 0;
    }
  }
}

.cookie-policy-link {
  width: auto;
  margin-left: auto;
  margin-right: auto;
  display: inline-block;
  font-family: "Suisse Intl Mono";
  line-height: 1.15;
  font-size: font-size($floatnote-font-size*0.95);
  margin-top: 4em;
  color: $color-purple !important;

  .info__footer-info & * {
    color: inherit;
  } 

  @media #{$breakpoint-md-up} {
    position: absolute;
    bottom: 1em;
    left: $cover-pad;
    font-size: font-size($side-pag-font-size);
    margin-top: 0;
  }

  & > span {
    margin-right: 1em;

    &:hover {
      color: $color-magenta !important;
      cursor: pointer;
    }
  }
}


